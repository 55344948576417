import { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle
} from '../../bootstrap/Card';
import Checks from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Label from '../../bootstrap/forms/Label';
import Button from '../../bootstrap/Button';
import { FreslerTable } from '../../FreslerTable/src/src/components/FreslerTable/FreslerTable';
var xlsx = require("xlsx");
import './TrackerRowImport.css';

import Alert from '../../bootstrap/Alert';
import './TrackerRowImport.css';
var xlsx = require("xlsx");

const cols = [
    {
        displayName: "Fund Name",
        field: "Fund Name",
        type: "string",
        editable: true,
    },
    {
        displayName: "Contact Name",
        field: "Contact Name",
        type: "string",
        editable: true,
    },
    {
        displayName: "Contact Email",
        field: "Contact Email",
        type: "string",
        editable: true,
    },
    {
        displayName: "Fund Size ($M)",
        field: "Fund Size",
        type: "int",
        editable: true,
    },
]

export const TrackerRowsImport = ({ contactsList, updateContactsList, isSimpleViewMode }) => {

    const [isOverWrite, setIsOverWrite] = useState(true)
    const [outputCols, setOutputCols] = useState(cols)
    const shouldHideManualContactButton = contactsList?.length > 0
    const buttonWidth = shouldHideManualContactButton ? 6 : 4

    const startFileAdd = (files) => {

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);

            if (isOverWrite) {
                updateContactsList(json)
            }
            else {
                updateContactsList([...contactsList, ...json])
            }

        };
        reader.readAsArrayBuffer(files[0]);
    }

    const initDataForEdit = () => {
        updateContactsList([
            {
                "Fund Name": "Example Fund",
                "Contact Name": "John Smith",
                "Contact Email": "j.smith@gmail.com",
                "Fund Size": 100
            },
        ])
    }

    const styleOptions = {
        search: true,
        pageSize: 5,
        exportButton: true,
        defaultExpanded: true,
        maxBodyHeight: "300px"
    }

    const actions = [
        {
            icon: 'delete',
            tooltip: 'Delete User',
            onClick: (event, rowData) => { }
        }
    ]

    return (
        <div>
            <div className='row mb-5'>
                <div className='col-12 col-sm-6 col-md-4 mb-2'>
                    <FileUploader
                        style={{ width: "100%" }}
                        label="Drop your contact spreadsheet here."
                        onSelect={(file) => { startFileAdd(file); }}
                        onDrop={(file) => { startFileAdd(file); }}
                        name="file"
                        multiple={true}>
                        <Button
                            style={{ width: "100%" }}
                            color='info'
                            icon='CloudUpload'
                            size="lg"
                            isLight>
                            Drop Spreadsheet here
                        </Button>
                    </FileUploader>
                </div>
                {
                    !shouldHideManualContactButton &&
                    <div className='col-12 col-sm-6 col-md-4 mb-2'>
                        <Button
                            style={{ width: "100%" }}
                            color='warning'
                            icon='Edit'
                            size="lg"
                            isLight
                            onClick={initDataForEdit}
                        >
                            Add Contacts Manually
                        </Button>
                    </div>
                }
                <div className='col-12 col-sm-6 col-md-4 mb-2'>
                    <Button
                        style={{ width: "100%" }}
                        color='success'
                        icon='CloudDownload'
                        isLight
                        tag='a'
                        size="lg"
                        to='/ExampleContactList.csv'
                        target='_blank'
                        download>
                        Download An Example
                    </Button>
                </div>
            </div>
            {
                contactsList?.length >= 0 &&
                <>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <Alert
                                color="warning"
                                isLight
                                borderWidth={0}
                                icon='Info'
                                className='flex-nowrap w-100 mb-0'>
                                Use the column dropdowns to map your columns to the required fields.
                            </Alert>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <FreslerTable
                                initDisplayTableMapping={!isSimpleViewMode}
                                isTableOptionsAccessible={false}
                                isGroupByAccessible={false}
                                setOutputCols={setOutputCols}
                                initCols={cols}
                                initData={contactsList ?? []}
                                updateData={updateContactsList} />
                        </div>
                    </div>
                </>
            }
            {
                !(contactsList?.length >= 0) &&
                <div className='row'>
                    <div className='col-12 text-center'>
                        <div className='display-6'>You can also add contacts after setting up your campaign.</div>
                    </div>
                </div>
            }
            <div className='row' style={{ marginBottom: "5rem" }} />
        </div>
    )
}
