import React, { useEffect, useState } from 'react'
import Input from '../../../components/bootstrap/forms/Input'

export const TaskTitle = ({ focusTask, updateFocusTask }) => {

    const [tempTitle, setTempTitle] = useState("")

    useEffect(() => {
        setTempTitle(focusTask?.title ?? "")
    }, [focusTask])

    const _updateFocusTask = (newTask) => {
        updateFocusTask({ ...focusTask, ...newTask })
    }
    return (
        <Input
            size="lg"
            className='title-column-span slim-input'
            value={tempTitle ?? ""}
            onChange={(e) => setTempTitle(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.target.blur(); // Blur the textarea
                }
            }}
            onBlur={(e) => {
                setTempTitle("")
                _updateFocusTask({ title: e.target.value })
            }}
        />
    )
}
