import React, {
    forwardRef,
    HTMLAttributeAnchorTarget,
    HTMLAttributes,
    ReactElement,
    ReactNode,
} from 'react';
import { Icon } from './Icon';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { TagWrapper } from './TagWrapper';

export const Button = forwardRef(
    (
        {
            children,
            to,
            href,
            isActive,
            color,
            isOutline,
            isLight,
            isLink,
            className,
            icon,
            rounded,
            size,
            isDisable,
            shadow,
            hoverShadow,
            target,
            isVisuallyHidden,
            tag = 'button',
            type = 'button',
            ...props
        },
        ref
    ) => {
        const BTN_CLASS = classNames(
            'btn',
            {
                [`btn-${isOutline || isLink ? `outline-${color}` : color}`]:
                    (color && !isLight) || (color && isLink),
                'border-transparent': isLink,
                [`btn-${size}`]: size,
                [`btn-hover-shadow${hoverShadow !== 'default' ? `-${hoverShadow}` : ''}`]:
                    hoverShadow,
                [`btn-light-${color}`]: isLight,
                [`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
                [`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
                'rounded-0':
                    rounded === 'bottom' ||
                    rounded === 'top' ||
                    rounded === 'end' ||
                    rounded === 'start' ||
                    rounded === 0,
                'btn-only-icon': !children || isVisuallyHidden,
                disabled: isDisable,
                active: isActive,
            },
            className
        );

        const INNER = (
            <>
                {icon && <Icon icon={icon} className='btn-icon' />}
                {isVisuallyHidden ? (
                    <span className='visually-hidden'>Toggle Dropdown</span>
                ) : (
                    children
                )}
            </>
        );

        const ANCHOR_LINK_PATTERN = /^#/i;

        const disableProps = isDisable && {
            tabIndex: -1,
            'aria-disabled': true,
            disabled: true,
        };

        if (tag === 'a') {
            if (typeof to === 'string' && ANCHOR_LINK_PATTERN.test(to)) {
                return (
                    <HashLink
                        ref={ref}
                        className={BTN_CLASS}
                        to={to}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...disableProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}>
                        {INNER}
                    </HashLink>
                );
            }
            if (to) {
                return (
                    <Link
                        ref={ref}
                        className={BTN_CLASS}
                        to={to}
                        rel='noopener'
                        target={target}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...disableProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}>
                        {INNER}
                    </Link>
                );
            }
            return (
                <a
                    ref={ref}
                    className={BTN_CLASS}
                    href={href}
                    role='button'
                    rel='noopener'
                    target={target}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...disableProps}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}>
                    {INNER}
                </a>
            );
        }
        return (
            <TagWrapper
                ref={ref}
                tag={tag}
                type={type}
                className={BTN_CLASS}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...disableProps}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}>
                {INNER}
            </TagWrapper>
        );
    }
);
