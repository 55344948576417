import React, { useEffect, useState } from 'react'
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from '../../../components/bootstrap/Dropdown'
import { getTaskIcons } from './TrackerBoardGetTaskStatusIcons'
import { useDispatch, useSelector } from 'react-redux'
import { setFocusPanelOpen, selectFocusWorkItem, updateTrackerTasks } from '../../../redux/slices/TrackerSlice'
import { getOrDefaultActionItem } from './TrackerBoardUtils'
import Icon from '../../../components/icon/Icon'
import { taskStatusSelectOptions } from '../../../services/Common'

const stateOptions = taskStatusSelectOptions?.filter(p => ["Not Started", "In Progress", "Complete"].includes(p.value))

export const TrackerBoardTaskCell = ({ dataItem, col, updateData, updateItem, isNewItem, tracker, id }) => {

    // const [task, setTask] = useState(null)
    const dispatch = useDispatch()
    const { _id: trackerId, value: trackerTasks } = useSelector(state => state?.tracker?.curTrackerTasks)
    var task = getOrDefaultActionItem(tracker, dataItem, col?.workStream, trackerTasks)


    const updateActionItem = (task, change) => {
        // TODO
        dispatch(updateTrackerTasks({ tasks: [{ ...task, ...change }] }))
    }

    const statusIcons = getTaskIcons(task)

    return (
        <div className='tracker-board-task-cell h-100 w-100 pl-3' key={id}>
            <Dropdown style={{ textAlign: "center" }}>
                <DropdownToggle hasIcon={false}>
                    <div
                        onClick={() => {
                            dispatch(selectFocusWorkItem({ trackerRow: dataItem, workstream: col.workStream }))
                        }}
                        onDoubleClick={() => {
                            dispatch(selectFocusWorkItem({ trackerRow: dataItem, workstream: col.workStream }))
                            dispatch(setFocusPanelOpen(true))
                        }}
                    >
                        {
                            statusIcons.map((icon, i) => {
                                return <div key={i} className='tracker-board-task-cell-icon'>
                                    {icon}
                                </div>
                            })
                        }
                    </div>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd>
                    {stateOptions.map((p, ind) => (
                        <DropdownItem onClick={() => updateActionItem(task, { status: p.value })} >
                            {
                                p.value
                            }
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}
