import React, { useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import { FreslerTable } from '../FreslerTable/src/src/components/FreslerTable/FreslerTable'
import Alert from '../bootstrap/Alert'
import Button from '../bootstrap/Button'

export const TrackerBoardEmailBlastRecipientEdit = ({ recipients, updateRecipients }) => {

    const [isDirty, setIsDirty] = useState(false)

    const _updateRecipients = (data) => {
        updateRecipients(data)
        setIsDirty(true)
    }

    const cols = [
        {
            title: 'Fund Name',
            field: 'Fund Name',
            type: 'string',
            required: true,
        },
        {
            title: 'Fund Size',
            field: 'Fund Size',
            type: 'string',
            required: false,
        },
        {
            title: 'Contact Name',
            field: 'Contact Name',
            type: 'string',
            required: false,
        },
        {
            title: 'Contact Email',
            field: 'Contact Email',
            type: 'email',
            required: true,
        },
    ]

    return (
        <PageWrapper className='justify-content-center text-center'>
            <div className='justify-content-center text-center'>
                <div>
                    <div>
                        <FreslerTable
                            initCols={cols}
                            initData={recipients}
                            enableRowSelection={false}
                            updateData={_updateRecipients}
                        />
                    </div>
                    {
                        isDirty && <Alert icon="Warning" color='warning' isOpen={isDirty} toggle={() => setIsDirty(false)} className="mt-2">
                            Would you like to update your contacts globally based on these changes? <Button color="success" onClick={() => { setIsDirty(false) }}>Update Contacts</Button>
                        </Alert>
                    }
                </div>
            </div>
        </PageWrapper>
    )
}
