/* esbuild-loader: jsx */

// import 'bootstrap/dist/css/bootstrap.min.css';
import classNames from 'classnames';
import memoize from "memoizee";
import React, { useEffect, useState, useRef } from 'react';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Form } from 'react-bootstrap';
import { FreslerDataViewGrouped } from './FreslerDataViewGrouped';
import { FreslerDataViewUngrouped } from './FreslerDataViewUngrouped';
import './FreslerTable.css';
import { defaultActions } from './FreslerTableActions';
import { FreslerTableColumnHeader } from './FreslerTableColumnHeader';
import { FreslerTableMoreOptions } from './FreslerTableMoreOptions';
import { Icon } from './subs/Icon';

import { GripVertical } from 'react-bootstrap-icons';
import {
    checkCellWidth,
    defaultDataAccessor,
    defaultDataRenderer,
    dragHandleCellWidth,
    filterData,
    getColumnOptionsMap,
    getColumnSortFunction,
    getDefaultApplicator,
    getFieldsFromData,
    getRowWidth,
    isNullOrEmpty
} from './FreslerTableUtilities';

const defaultSerializer = ((dataItem, col) => dataItem?.[col.field] ?? '')

const setUpCols = (cols) => {
    return cols?.map((col, i) => {
        return {
            ...col,
            displayName: col.displayName || col.field,
            optionsMap: getColumnOptionsMap(col),
            type: col.type || 'string',
            id: '' + (col?.id ?? col?._id ?? i),
            editable: col.editable || false,
            width: col.width || 250,
            serialize: memoize(col.serialize ?? defaultSerializer, { maxAge: 1000 * 60 * 60 * 24 })
        }
    })
}

// TODO Consolidate table updates into the updateData function
// Provide paramters to demtermine if items are being added, updated, or deleted, or only a subset of items are being changed
export const FreslerTable = ({
    initCols, initData,
    updateData = null,
    customItemUpdater = null,
    initNumRows = null,
    optionalCols = [],
    customDataAccessor = null, customDataRender = null,
    customFilterSetApplicator = null,
    customRowCounter = null, customTableActions = [],
    initDisplayTableMapping = false, initGroupSortOrder = [],
    initGroupBy = null, setOutputCols = null,
    isGroupByAccessible,
    isTableOptionsAccessible = true,
    enableColumnDrag = true,
    enableRowSelection = true,
    enableNewItemAdd = true,
    disableTableActions = [],
    broadcast = () => { }
}) => {

    const headerRef = useRef(null);
    const bodyRef = useRef(null);

    const [data, setData] = useState([])
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [groupBy, setGroupBy] = useState(initGroupBy);
    const [isMapModeOpen, setIsMapModeOpen] = useState(initDisplayTableMapping)

    const [numRows, setNumRows] = useState(0)
    const [checkedItems, setCheckedItems] = useState(new Set());
    const [isFiltersActive, setIsFiltersActive] = useState(false)
    const [searchText, setSearchText] = useState(null)
    const [cols, _setCols] = useState([])
    const [sortedData, setSortedData] = useState([])
    const [columnHeaderClass, setColumnHeaderClass] = useState("fresler-table-row")

    const [filteredData, setFilteredData] = useState([])
    const [allDataFields, setAllDataFields] = useState([])

    useEffect(() => {
        console.log(headerRef?.current, bodyRef?.current)
        if (!headerRef.current || !bodyRef.current) return;

        const headerElement = headerRef.current;
        const bodyElement = bodyRef.current;

        const handleBodyScroll = () => {
            headerElement.scrollLeft = bodyElement.scrollLeft;
        };

        bodyElement.addEventListener('scroll', handleBodyScroll);

        return () => {
            bodyElement.removeEventListener('scroll', handleBodyScroll);
        };
    }, [bodyRef.current]);

    const _updateData = (newData) => {
        if (updateData) {
            updateData(newData)
        }
        else {
            setData(newData)
        }
    }

    useEffect(() => {
        setData(initData)
        setFilteredData(initData)
        setNumRows(initNumRows || initData?.length || 0)

        const fields = getFieldsFromData(initData ?? [])
        setAllDataFields(fields)

    }, [initData])

    useEffect(() => {
        const cols = setUpCols(initCols)
        setCols(cols)
    }, [initCols])


    // Apply column filters
    useEffect(() => {
        const filters = cols.filter((col) => col?.filter?.active).map((col) => ({ filter: { ...col.filter, field: col?.field, col: col }, applicator: col.filterApplicator ?? getDefaultApplicator(col.type) }))

        const tempData = isNullOrEmpty(searchText) ? data : searchAll(cols)
        const filtersActive = filters.length > 0 || !isNullOrEmpty(searchText)

        const filteredData = filterData(tempData, filters, customFilterSetApplicator)
        const newRowCount = customRowCounter ? customRowCounter(filteredData) : filteredData?.length || 0
        setIsFiltersActive(filtersActive)
        setNumRows(newRowCount)
        setFilteredData(filteredData)
    }, [data, cols, searchText])

    const setCols = (c) => {
        if (setOutputCols) {
            setOutputCols(c)
        }
        _setCols(c)
    }

    const toggleTableHeaderClass = () => {
        setColumnHeaderClass(columnHeaderClass === "fresler-table-row" ? "fresler-table-row2" : "fresler-table-row")
    }

    const handleDragColumnEnd = (result) => {
        if (!result.destination) return; // Return if dropped outside the list

        const newColumns = [...cols];
        const [reorderedColumn] = newColumns.splice(result.source.index, 1);
        newColumns.splice(result.destination.index, 0, reorderedColumn);

        setCols(newColumns);

        toggleTableHeaderClass()
    };


    function handleSortClick(column) {
        if (column?.displayName === sortBy?.displayName) {
            if (sortOrder === 'desc') {
                setSortBy(null);
            }
            else {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            }
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
    }

    const defaultItemUpdater = (item, col, value) => {
        let newItems = [...data]
        const index = newItems.findIndex((i) => (i.id ?? i._id) === (item.id ?? item._id))

        newItems[index][col.field] = value

        _updateData(newItems)

        // TODO: Should we await async completion?
        // TODOD: Switch to broadcastChanges and make this work for updateItemSubset and addItem
        const broadCastChange = col?.broadcastChange ?? null
        if (broadCastChange) {
            broadCastChange(item, col, value)
        }
    }

    const updateItemSubset = (items) => {
        var newItems = [...data]
        items.forEach((item) => {
            const index = newItems.findIndex((i) => (i.id ?? i._id) === (item.id ?? item._id))
            newItems[index] = item
        })
        _updateData(newItems)
    }

    const addItem = (item) => {
        var newItems = [...data]
        newItems.push(item)
        _updateData(newItems)
    }

    const updateItem = customItemUpdater || defaultItemUpdater

    const updateCol = (col) => {
        const newCols = [...cols]
        const index = newCols.findIndex((c) => (c.id ?? c._id) === (col.id ?? col._id))
        newCols[index] = col
        setCols(newCols)
    }

    const handleItemCheck = enableRowSelection ? (e, dataItem) => {
        const id = dataItem.id || dataItem._id

        const newSet = new Set(checkedItems)
        if (newSet.has(id)) {
            newSet.delete(id)
        } else {
            newSet.add(id)
        }
        setCheckedItems(newSet)
    } : () => { }

    const accessRowItem = customDataAccessor || defaultDataAccessor
    const renderDataItem = customDataRender || defaultDataRenderer

    // TODO: If data is grouped, do not sort headers. It will be sorted later
    function sortData(column) {
        const sortedData = [...filteredData];
        const sortFunction = getColumnSortFunction(column, sortOrder);
        sortedData.sort(sortFunction);
        return sortedData;
    }

    useEffect(() => {
        const ss = sortBy ? sortData(sortBy) : filteredData;
        setSortedData(ss)
    }, [sortBy, filteredData, sortOrder])

    const getCheckIcon = () => {
        const numChecked = checkedItems.size
        return numChecked > 0 ? numChecked >= numRows ? "CheckSquareFill" : "DashSquare" : "Square"
    }

    const selectAllFilteredRows = () => {
        const idArray = filteredData.map((row) => (row.id ?? row._id))
        const newSet = new Set(idArray)
        setCheckedItems(newSet)
    }

    const deselectAllFilteredRows = () => {
        setCheckedItems(new Set())
    }

    const toggleSelectAll = () => {
        const numChecked = checkedItems.size
        if (numChecked > 0) {
            deselectAllFilteredRows()
        } else {
            selectAllFilteredRows()
        }
    }

    const searchAll = (cols) => {
        const defaultSerializer = ((dataItem, col) => dataItem?.[col.field] ?? '')
        const searchTextLower = searchText?.toLowerCase()
        const filteredData = data.filter((row) => {
            let cat = ""

            for (let i = 0; i < cols.length; i++) {
                const col = cols[i]
                const serializeEnabled = (col?.enableSearch ?? true)
                if (serializeEnabled) {

                    // Using simple field rather then data access function
                    // TODO: Redfine data rendering pipeline -> rowAccessor -> columnAccessor -> serializer, renderer
                    const serializationFunc = col?.serialize ?? defaultSerializer
                    cat += serializationFunc(row, col)
                }
            }
            cat = (cat ?? '').toLowerCase()
            return cat.includes(searchTextLower)
        })
        return filteredData
    }

    const resizeCol = (columnIndex, deltaX) => {
        let newCols = [...cols]
        newCols[columnIndex] = { ...newCols[columnIndex], width: newCols[columnIndex].width + deltaX }
        setCols(newCols)
    }

    const checkIcon = getCheckIcon()
    const isSorted = sortBy != null
    const filteredActions = defaultActions.filter((action) => !disableTableActions.includes(action.displayName))
    const actions = filteredActions.concat(customTableActions)

    const tableWidth = getRowWidth(cols, enableRowSelection)

    return (
        <>
            <DragDropContext onDragEnd={handleDragColumnEnd}>
                <Droppable droppableId="table-columns" direction="horizontal">
                    {(provided) => (
                        <div className='fresler-table-container'>
                            <div className='fresler-table-container-internal'>
                                <div className="fresler-table-container-search-section" >
                                    <div className='fresler-table-top-header row justify-content-between w-100'>
                                        <div className='col-6 col-md-5 col-lg-4'>
                                            <Form.Control
                                                className='search-all-input'
                                                placeholder='Search All'
                                                onChange={(e) => setSearchText(e.target.value)}
                                                value={searchText}
                                            />
                                        </div>
                                        <div className='fresler-table-group-by-display col-5 col-md-5 col-lg-4'>
                                            {
                                                groupBy && <>Grouped By: {groupBy?.displayName} <Icon color='red' icon='XCircleFill' className='cursor-pointer' onClick={() => setGroupBy(null)} /></>
                                            }
                                        </div>
                                        <div className='fresler-table-more-options-section col-1 col-md-2 col-lg-4'>
                                            <span className='justify-content-end w-100 align-items-end align-items-center d-flex float-end text-end d-flex'>
                                                {
                                                    checkedItems.size > 0 && <span className='fresler-table-selected-count'>({checkedItems.size})</span>
                                                }
                                                {
                                                    actions.map((action, index) => {
                                                        const isVisible = action.isVisible ? action.isVisible(data, checkedItems)
                                                            : checkedItems.size > 0

                                                        return <Icon
                                                            size={900}
                                                            key={index}
                                                            onClick={() => {
                                                                action.apply(data, checkedItems, setCheckedItems, _updateData, updateItem)
                                                            }}
                                                            style={{
                                                                visibility: isVisible ? 'visible' : 'hidden',
                                                                marginTop: '.5rem',
                                                            }}
                                                            className={`action-bar-span cursor-pointer ml-1 mr-2`}
                                                            icon={action.icon}
                                                            color={action.color ?? 'primary'}
                                                        />
                                                    })
                                                }
                                                {
                                                    isTableOptionsAccessible &&
                                                    <FreslerTableMoreOptions
                                                        isTableOptionsAccessible={isTableOptionsAccessible}
                                                        allDataFields={allDataFields}
                                                        toggleMapMode={() => setIsMapModeOpen(!isMapModeOpen)}
                                                        cols={cols}
                                                        optionalCols={optionalCols}
                                                        setCols={setCols}
                                                        allData={data}
                                                    />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="fresler-table-container-col-data-section">
                                    <div className='fresler-table-container-col-data-section-internal'

                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <div className={`fresler-table-row-col fresler-table-column-header-row`} ref={headerRef}>
                                            <div className='fresler-table-row' >
                                                <div className="fresler-table-cell fresler-table-grip-column-header" style={{ width: `${dragHandleCellWidth}px` }}>
                                                    <GripVertical
                                                        className={"invisible"}
                                                    />
                                                </div>
                                                {
                                                    enableRowSelection && <div className='fresler-table-cell' style={{ width: `${checkCellWidth}px` }}>
                                                        <div className="row fresler-col-header-check">
                                                            <span
                                                                className={classNames(
                                                                    {
                                                                        'cursor-pointer': true,
                                                                        'text-info': checkedItems.size > 0 && checkedItems.size < numRows,
                                                                        'text-primary': checkedItems.size >= numRows
                                                                    }
                                                                )}
                                                                onClick={toggleSelectAll}
                                                            >
                                                                <Icon icon={checkIcon} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    cols.map((col, index) =>
                                                        <Draggable
                                                            key={col.id ?? col._id ?? index}
                                                            draggableId={col.id ?? col._id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <FreslerTableColumnHeader
                                                                    updateData={_updateData}
                                                                    updateItemSet={updateItemSubset}
                                                                    addItem={addItem}
                                                                    accessRowItem={accessRowItem}
                                                                    updateItem={updateItem}

                                                                    broadcast={broadcast}
                                                                    enableColumnDrag={enableColumnDrag}
                                                                    data={data}
                                                                    col={col}
                                                                    index={index}
                                                                    provided={provided}
                                                                    snapshot={snapshot}
                                                                    sortBy={sortBy}
                                                                    sortOrder={sortOrder}
                                                                    checkedItems={checkedItems}
                                                                    groupBy={groupBy}
                                                                    setGroupBy={setGroupBy}
                                                                    updateCol={updateCol}
                                                                    resizeCol={resizeCol}
                                                                    allDataFields={allDataFields}
                                                                    isMapModeOpen={isMapModeOpen}
                                                                    handleSortClick={handleSortClick}
                                                                />
                                                            )}
                                                        </Draggable>
                                                    )}
                                            </div>
                                        </div>
                                        {
                                            cols && cols?.length > 0 && sortedData ?
                                                groupBy ?
                                                    <FreslerDataViewGrouped
                                                        tableWidth={tableWidth}
                                                        broadcast={broadcast}
                                                        groupBy={groupBy}
                                                        sortBy={sortBy}
                                                        sortOrder={sortOrder}
                                                        numRows={numRows}
                                                        cols={cols}
                                                        sortedData={sortedData}
                                                        updateData={_updateData}
                                                        updateItemSet={updateItemSubset}
                                                        addItem={addItem}
                                                        accessRowItem={accessRowItem}
                                                        updateItem={updateItem}
                                                        enableRowSelection={enableRowSelection}
                                                        renderDataItem={renderDataItem}
                                                        checkedItems={checkedItems}
                                                        handleItemCheck={handleItemCheck}
                                                        isSorted={isSorted}
                                                        isFiltersActive={isFiltersActive}
                                                        enableNewItemAdd={enableNewItemAdd}
                                                    /> :
                                                    <FreslerDataViewUngrouped
                                                        bodyRef={bodyRef}
                                                        tableWidth={tableWidth}
                                                        broadcast={broadcast}
                                                        addItem={addItem}
                                                        sortBy={sortBy}
                                                        sortOrder={sortOrder}
                                                        updateData={_updateData}
                                                        enableRowSelection={enableRowSelection}
                                                        numRows={numRows}
                                                        cols={cols}
                                                        sortedData={sortedData}
                                                        accessRowItem={accessRowItem}
                                                        updateItem={updateItem}
                                                        renderDataItem={renderDataItem}
                                                        checkedItems={checkedItems}
                                                        handleItemCheck={handleItemCheck}
                                                        isSorted={isSorted}
                                                        isFiltersActive={isFiltersActive}
                                                        enableNewItemAdd={enableNewItemAdd}
                                                    />
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </ >
    )
}
