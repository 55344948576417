import { configureStore } from '@reduxjs/toolkit'
import FileJobReducer from './slices/FileJobSlice'
import UserReducer from './slices/UserSlice'
import TrackerReducer from './slices/TrackerSlice'

export const store = configureStore({
    reducer: {
        fileJob: FileJobReducer,
        user: UserReducer,
        tracker: TrackerReducer
    },
})

