import React from 'react';
import { Display } from 'react-bootstrap-icons';

export const deleteAction = {
    displayName: 'Delete',
    icon: 'Trash',
    color: 'danger',
    apply: (data, selected, updateCheckedItems, updateData, updateDataItem) => {
        const newData = data.filter((item) => !selected.has(item.id ?? item._id));
        updateData(newData);
        updateCheckedItems(new Set())
    }
}

export const defaultActions = [deleteAction]