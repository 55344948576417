import showNotification from '../components/extras/showNotification';
import { SeverityLevel } from '@microsoft/applicationinsights-web';


class a4Logger {

    static appInsights = null;
    static user_id = null;

    static logError(error, customProperties = {}, shouldShowNotification = true) {

        a4Logger.appInsights?.trackException({ error: error, severityLevel: SeverityLevel.Error },
            { ...customProperties, user_id: a4Logger.user_id })


        const message = error.message;
        const subMessage = error.stack;

        if (shouldShowNotification) {
            // Should depend in debug mode
            const messageToDisplay = customProperties?.displayMessage ?? "An error occurred. Please try again later.";
            showNotification(messageToDisplay);
        }

        console.error(error.message);
    }

    static logErrorMessage(message, subMessage = '', customProperties = {}, topic = null, shouldShowNotification = true) {
        console.error(message + '\n' + subMessage);

        a4Logger.appInsights?.trackTrace({ message: message, severityLevel: SeverityLevel.Error },
            { ...customProperties, subMessage: subMessage, type: "UserError", user_id: a4Logger.user_id, topic: topic })

        if (shouldShowNotification) {
            showNotification(message, subMessage);
        }
    }

    static notifyUser(message, subMessage = '') {

        showNotification(message, subMessage);

    }


    static logUserEvent(eventName, customProperties = {}, subMessage = '', topic, shouldShowNotification = true) {

        a4Logger.appInsights?.trackTrace({ name: eventName },
            { ...customProperties, type: "UserEvent", user_id: a4Logger.user_id, topic: topic })


        console.log(eventName);
    }

    static logTrace(message, customProperties = {}, subMessage = '', topic, shouldShowNotification = true) {

        a4Logger.appInsights?.trackTrace({ message: message, severityLevel: SeverityLevel.Information },
            { ...customProperties, user_id: a4Logger.user_id, topic: topic })


        console.log(message);
    }
}


export default a4Logger; 