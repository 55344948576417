import * as icons from 'react-bootstrap-icons';
import React from 'react';


export const Icon = ({ icon, ...props }) => {
    const BootstrapIcon = icons[icon];
    const color = props.color;
    const colorClass = `fill-${color}`;
    const className = color ? `${props.className} ${colorClass}` : props.className;
    props = { ...props, className };
    return BootstrapIcon ? <BootstrapIcon {...props} /> : <>{`${icon} * ${className} * ${color}`}</>;
}