import UserService from "./UserService"
import { CustomerException } from "./Common"

const validateResponse = (response) => {
    if (!response.ok) {
        if (response.headers.get('content-type')?.includes('application/json')) {
            const data = response.json()
            throw new CustomerException(data.message, data.userMessage, data.stackTrace);
        }

        throw new CustomerException(response.text(), "Request Failed");
    }
}

export const get = async (path) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/${path}`, {
        method: 'GET',
        headers: {
            ...additonalHeaders
        },
    })

    validateResponse(response)

    return response
}

export const getJson = async (path) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/${path}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...additonalHeaders
        },
    })

    validateResponse(response)

    const data = await response.json()
    return data
}

export const putJson = async (path, body, baseUrl = process.env.REACT_APP_PUBLIC_API_URL, additionalHeaders = {}) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${baseUrl}/${path}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...additonalHeaders
        },
        body: JSON.stringify(body)
    })

    validateResponse(response)

    const data = await response.json()
    return data
}

export const postJson = async (path, body, baseUrl = process.env.REACT_APP_PUBLIC_API_URL, additionalHeaders = {}) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${baseUrl}/${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...additonalHeaders
        },
        body: JSON.stringify(body)
    })

    validateResponse(response)

    const data = await response.json()
    return data
}

export const deleteJson = async (path, body, baseUrl = process.env.REACT_APP_PUBLIC_API_URL, additionalHeaders = {}) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${baseUrl}/${path}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...additonalHeaders
        },
        body: body ? JSON.stringify(body) : undefined
    })

    validateResponse(response)

    const data = await response.json()
    return data
}

export const postRaw = async (path, body, baseUrl = process.env.REACT_APP_PUBLIC_API_URL, additionalHeaders = {}) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${baseUrl}/${path}`, {
        method: 'POST',
        headers: {
            ...additonalHeaders
        },
        body: body
    })

    validateResponse(response)

    const data = await response.json()
    return data
}