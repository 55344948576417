import React, { useEffect, useState } from 'react'

export const TaskDescription = ({ focusTask, updateFocusTask }) => {

    const [textAreaValue, setTextAreaValue] = useState("")

    useEffect(() => {
        setTextAreaValue(focusTask?.description ?? "")
    }, [focusTask])

    const _updateFocusTask = (newTask) => {
        updateFocusTask({ ...focusTask, ...newTask })
    }


    return (
        <textarea rows="5"
            className="taskDesc"
            value={textAreaValue ?? ""}
            onChange={(e) => setTextAreaValue(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.target.blur(); // Blur the textarea
                }
            }}
            onBlur={(e) => {
                setTextAreaValue("")
                _updateFocusTask({ description: e.target.value })
            }}
        />
    )
}
