import FileService from "./FileService";
import { deleteJson, get, getJson, postJson, putJson } from "./ServiceHelperMethods";
import a4Logger from "./a4Logger";
import { dummyFileJobData } from "./data/dummyFileJobData";

const PREFETCH_COUNT = 6

class FileJobService {

    static async getFileJobs() {
        return await getJson(`filejobs`);
    }

    static async downloadAllFiles(fileJob) {

        a4Logger.logTrace(`Downloading all files in filejob`, { fileJob: fileJob?._id })
        const fileJobId = fileJob._id
        const fileJobExtended = await getJson(`filejobs/${fileJobId}`);

        for (const file of fileJobExtended.files) {
            FileService.downloadFile(file)
        }
    }

    static async prefetchFiles(fileJob) {

        const files = [...fileJob.sourceFiles, ...fileJob.files]
        const prefetchCount = Math.min(files.length, PREFETCH_COUNT)

        a4Logger.logTrace(`Prefetching ${prefetchCount} files`, { prefetchCount: prefetchCount, fileJob: fileJob?._id })

        for (let i = 0; i < prefetchCount; i++) {
            if (files[i]?.status == "ready") {
                await FileService.getFileBlob(files[i]?._id)
            }
        }
    }

    static async getFileJob(filejobId) {
        return await getJson(`filejobs/${filejobId}`);
    }

    static async createFileJobs(filejobs) {
        return await postJson(`filejobs`, filejobs);
    }

    static async deleteFileJob(filejobId) {
        return await deleteJson(`filejobs/${filejobId}`);
    }

    static async startFileJobUpgradePaymentIntent(filejob, price) {
        return await postJson(`filejobs/${filejob._id}/initializeUpgrade`, { priceId: price.id });
    }

    static async completeUpgrade(filejob, paymentIntent, subscriptionId) {
        return await postJson(`filejobs/${filejob._id}/completeUpgrade`, { paymentIntent: paymentIntent.id, subscriptionId: subscriptionId });
    }
}



export default FileJobService;