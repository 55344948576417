import React, { useState } from 'react'
import { checkCellWidth, dragHandleCellWidth } from './FreslerTableUtilities'
import { FreslerTableDataAddNewRowButton } from './FreslerTableDataAddNewRowButton'
import { FreslerTableDataAddNewRowField } from './FreslerTableDataAddNewRowField'

export const FreslerTableDataAddNewRow = ({ cols, addItem, renderDataItem, enableRowSelection }) => {

  

    const firstCol = cols[0]
    const remainingCells = cols.slice(1)
    const firstCellWidth = firstCol.width || 200

    return (
        <>
            <div
                className='fresler-table-cell'
                style={{ width: `${dragHandleCellWidth - 1}px` }}>
            </div>
            {
                enableRowSelection && <div
                    className='fresler-table-cell'
                    style={{ width: `${checkCellWidth}px` }}>
                </div>
            }
            <div
                className='fresler-table-cell'
                style={{ width: `${firstCellWidth}px` }}>
                {
                    <FreslerTableDataAddNewRowField
                        renderDataItem={renderDataItem}
                        col={firstCol}
                        addItem={addItem} />
                }
            </div>
            {
                remainingCells.map((col, index) => {
                    return (
                        <div
                            key={index}
                            className='fresler-table-cell'
                            style={{ width: `${col.width ?? 200}px` }}>
                        </div>
                    )
                })
            }
        </>
    )
}
