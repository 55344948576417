import Badge from "../components/bootstrap/Badge";
import Spinner from "../components/bootstrap/Spinner";
import Icon from "../components/icon/Icon";
import { v4 as uuidv4 } from 'uuid';

export class CustomerException extends Error {
    constructor(message, userMessage, stackTrace) {
        super(message);
        this.name = this.constructor.name;

        this.userMessage = userMessage;
        this.stackTrace = stackTrace;
    }
}

export const reduceFileStatuses = (files) => {
    return files?.reduce((acc, file) => {
        if (acc === "failed") {
            return "Failed"
        }

        if (file?.status === "failed") {
            return "Failed"
        } else if (file?.status === "processing" || file?.status === "queued") {
            return "Processing"
        }

        return acc
    }, "Ready")
}

export const isNullOrEmpty = (value) => {
    return value === null || value === undefined || value === ""
}

export const getStatusIcon = (status) => {
    switch (status) {
        case "processing":
        case "Processing":
            return <Spinner size='sm' color="info" />
        case "failed":
        case "Failed":
            return <Icon icon='XCircleFill' color="danger" />
        case "ready":
        case "Ready":
            return <Icon icon='CheckCircleFill' color="success" />
        default:
            return <Icon icon='Question' />
    }
}

export const getPercentage = (value, total) => {

    value = value ?? 0
    total = total ?? 1

    return Math.round((value * 100 / total))
}

export const trackerRowStatusOptions = [
    { value: 'Potential', label: <Badge color="light">Potential</Badge> },
    { value: 'Active', label: <Badge color="info">Active</Badge> },
    { value: 'Blocked', label: <Badge color="danger">Blocked</Badge> },
    { value: 'Abandoned', label: <Badge color="primary">Abandoned</Badge> },
    { value: 'Complete', label: <Badge color="success">Complete</Badge> },
]

export const taskStatusSelectOptions = [
    { value: 'Blocked', label: <Badge color="danger">Blocked</Badge> },
    { value: 'Not Started', label: <Badge color="warning">Not Started</Badge> },
    { value: 'In Progress', label: <Badge color="info">In Progress</Badge> },
    { value: 'Complete', label: <Badge color="success">Complete</Badge> },
    { value: 'Skipped', label: <Badge color="dark">Skipped</Badge> },
]
export const taskPriorirtyLevelSelectOptions = [
    { value: 'Low', label: <Badge color="light">Low</Badge> },
    { value: 'Medium', label: <Badge color="info">Medium</Badge> },
    { value: 'High', label: <Badge color="danger">High</Badge> },
]

export const dummyUserSelectOptions = [
    { value: 'johndoe@gmail.com', label: <div><img className="avatarImg" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlcnxlbnwwfHwwfHx8MA%3D%3D" height="30px" width="30px" />John Doe</div> },
    { value: 'janedoe@gmail.com', label: <div><img className="avatarImg" src="https://images.ctfassets.net/lh3zuq09vnm2/yBDals8aU8RWtb0xLnPkI/19b391bda8f43e16e64d40b55561e5cd/How_tracking_user_behavior_on_your_website_can_improve_customer_experience.png" height="30px" width="30px" />Fred Baldwin</div> },
    { value: 'mikesmith@gmail.com', label: <div><img className="avatarImg" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D" height="30px" width="30px" />Mike Smith</div> },
]

export const isValidEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const getObjectId = () => {
    return uuidv4().slice(0, -8).replace(/-/g, ''); // Removes dashes from the UUID
}