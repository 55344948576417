import classNames from 'classnames';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { GripVertical } from "react-bootstrap-icons";
import { Form } from 'react-bootstrap';
import './FreslerTable.css';
import { FreslerTableDataAddNewRow } from './FreslerTableDataAddNewRow';
import { checkCellWidth, dragHandleCellWidth } from './FreslerTableUtilities';
;

export const FreslerDataViewUngrouped = ({
    numRows, cols,
    sortedData, accessRowItem, updateItem, addItem, updateData, renderDataItem,
    checkedItems, handleItemCheck,
    sortBy, sortOrder,
    isSorted, isFiltersActive,
    broadcast,
    enableRowSelection,
    enableNewItemAdd,
    bodyRef,
    tableWidth
}) => {

    const [newItemInputText, setNewItemInputText] = useState("")

    const handleAddRow = () => {
        const col1 = cols[0];
        const field = col1.field;
        let newRow = { id: Date.now() };
        newRow[field] = newItemInputText;

        addItem(newRow);
        setNewItemInputText("");
    };

    const isMoveDisabled = isSorted || isFiltersActive;

    const handleDrop = (droppedItem) => {

        if (isMoveDisabled) return;

        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedData = [...sortedData];
        // Remove dragged item
        const [reorderedItem] = updatedData.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedData.splice(droppedItem.destination.index, 0, reorderedItem);

        updatedData = updatedData.map((i, index) => {
            var ret = { ...i }
            ret.index = index
            return ret
        })

        // Update State
        updateData(updatedData);
    };

    const getTableBody = () => {
        const rows = [];
        for (let i = 0; i < numRows; i++) {

            const row = sortedData[i];

            const rowItem = accessRowItem(row);

            const cells = [];

            for (let j = 0; j < cols.length; j++) {
                const col = cols[j];
                const dataAccessor = col.customDataAccessor ?? ((row, col) => row);
                const dataItem = dataAccessor(row, col);

                const renderedItem = renderDataItem(dataItem, col, updateData, updateItem, false, broadcast);
                const additionalClasses = col?.generateFreslerTableCellClasses?.(dataItem, col) ?? '';

                cells.push(
                    <div
                        className={`fresler-table-cell fresler-ungrouped-table-cell ${additionalClasses}`}
                        style={{ width: col?.width }} key={j}>
                        {renderedItem}
                    </div>
                );
            }

            rows.push(
                <Draggable key={rowItem?._id ?? 'i' + i}
                    draggableId={rowItem?._id ?? 'i' + i}
                    index={i}>
                    {(provided) => (
                        <div
                            className='fresler-table-row fresler-ungrouped-table-row'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            key={i}
                            onClick={
                                (e) => {
                                    if (e.target.tagName !== 'INPUT') {
                                        handleItemCheck(e, rowItem)
                                    }
                                }
                            }>
                            <div
                                style={{ width: `${dragHandleCellWidth - 1}px` }}
                                className={
                                    classNames({
                                        "d-flex align-items-center": true,
                                        "fresler-grip-cell": true,
                                        "fresler-ungrouped-table-row-drag-cell": true,
                                        "fresler-table-cell": true,
                                        'move-disabled': isSorted || isFiltersActive
                                    })} >
                                {
                                    <span {...(!isMoveDisabled && provided.dragHandleProps)} className="control task-grip">
                                        <GripVertical
                                            className={classNames(
                                                {
                                                    "invisible": isMoveDisabled,
                                                }
                                            )}
                                        />
                                    </span>
                                }
                            </div>
                            {
                                enableRowSelection && <div
                                    style={{ width: `${checkCellWidth}px` }}
                                    className="fresler-action-cell fresler-table-cell d-flex align-items-center"
                                >
                                    <Form.Check
                                        className='fresler-table-row-select-checkbox'
                                        color='success'
                                        onChange={(e) => handleItemCheck(e, row)}
                                        checked={checkedItems.has(row?.id || row?._id)}
                                    />
                                </div>
                            }
                            {cells}
                        </div>
                    )}
                </Draggable>
            );
        }

        return rows;
    }


    const table = getTableBody();

    return (
        <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="fresler-table-row-container">
                {(provided) => (
                    <div
                        className="fresler-table-row-container"
                        {...provided.droppableProps}
                        ref={(element) => {
                            if (element) {
                                provided.innerRef(element);
                                bodyRef.current = element;
                            }
                        }}
                    >
                        {table}
                        {
                            enableNewItemAdd &&
                            <div className='fresler-table-row fresler-table-row-add-new fresler-ungrouped-table-row' >
                                <FreslerTableDataAddNewRow
                                    enableRowSelection={enableRowSelection}
                                    renderDataItem={renderDataItem}
                                    cols={cols}
                                    addItem={addItem}
                                />
                            </div>
                        }
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}