import classNames from 'classnames';
import { ArrowDown, ArrowUp, GripVertical } from "react-bootstrap-icons";
import Draggable from "react-draggable";
import './FreslerTable.css';
import { FreslerTableColumnFieldMapPanel } from './FreslerTableColumnFieldMapPanel';
import FreslerTableHeaderDropdown from './FreslerTableHeaderDropdown';
import FreslerTableHeaderActionDropdown from './FreslerTableHeaderActionDropdown';
import React from 'react';

export const FreslerTableColumnHeader = ({
    col, index, provided, snapshot,
    sortBy, sortOrder, groupBy, setGroupBy,
    updateCol, resizeCol, allDataFields,
    isMapModeOpen, handleSortClick,
    data, checkedItems,
    enableColumnDrag = true,

    updateData = null,
    updateItemSet = null,
    addItem = null,
    accessRowItem = null,
    updateItem = null
}) => {

    const canFitAllActions = (col) => {
        return false;
    }

    return (
        <div
            className='fresler-table-cell fresler-col-header-cell'
            ref={provided.innerRef}
            {...{ ...provided.draggableProps, style: { ...provided.draggableProps.style, width: col?.width || "200px", color: col?.filter?.active ? 'red' : 'black' } }}
            onDoubleClick={() => handleSortClick(col)}
            key={index}>
            <div className="row fresler-col-header">
                <div className="col-auto tr-left">
                    {enableColumnDrag}
                    <span className={
                        classNames({
                            "control task-grip": true,
                            "d-none": !enableColumnDrag,
                        })
                    }
                        {...provided.dragHandleProps}
                        draggable={enableColumnDrag}
                    >
                        <GripVertical />
                    </span>
                    <span className='col-title'>
                        {col.displayName}
                    </span>
                    {' '}{col?.displayName === sortBy?.displayName ?
                        <span>{sortOrder === 'asc' ? <ArrowUp /> : <ArrowDown />}</span> : <></>
                    }
                </div>
                {
                    (col?.enableFilter ?? true) &&
                    <div className={
                        classNames({
                            "control": !(col?.filter?.active),
                            "text-danger": col?.filter?.active,
                            "col-1": true,
                            "tr-right": true
                        })
                    }>
                        <FreslerTableHeaderDropdown

                            col={col}
                            updateCol={updateCol}
                            groupBy={groupBy}
                            setGroupBy={setGroupBy}
                            data={data}
                        />
                    </div>
                }
                {
                    col?.actions?.length > 0 &&
                    <div className={
                        classNames({
                            "control": !(col?.filter?.active),
                            "text-danger": col?.filter?.active,
                            "col-1": true,
                            "tr-right": true
                        })
                    }>
                        <FreslerTableHeaderActionDropdown
                            updateData={updateData}
                            updateItemSet={updateItemSet}
                            addItem={addItem}
                            accessRowItem={accessRowItem}
                            updateItem={updateItem}

                            col={col}
                            updateCol={updateCol}
                            groupBy={groupBy}
                            setGroupBy={setGroupBy}
                            data={data}
                            checkedItems={checkedItems}
                        />
                    </div>
                }
                <div className="col-1 align-self-end fresler-table-col-drag-handle-area">
                    <Draggable
                        axis="x"
                        defaultClassName="DragHandle"
                        defaultClassNameDragging="DragHandleActive"
                        onDrag={(e, { deltaX }) => resizeCol(index, deltaX)}
                        position={{ x: 0 }}
                        zIndex={999}>
                        <div className="DragHandleIcon">⋮</div>
                    </Draggable>
                </div>
            </div>
            <div className='row fresler-col-header-map-section'>
                {
                    isMapModeOpen &&
                    <FreslerTableColumnFieldMapPanel
                        allFields={allDataFields}
                        col={col}
                        updateCol={updateCol}
                    />
                }
            </div>
        </div>
    )
}
