import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from './Avatar';
import useDarkMode from '../hooks/useDarkMode';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import Icon from './icon/Icon';
import { useSelector } from 'react-redux';
import UserService from '../services/UserService';

export const ChatListItem = ({
	src,
	srcSet,
	className,
	isOnline,
	color,
	size,
	name,
	surname,
	latestMessage,
	unreadMessage,
	isActive,
	lastSeenTime,
	...props
}) => {
	const { darkModeStatus } = useDarkMode();
	const user = useSelector(state => state.user.me.value)

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('col-12 cursor-pointer', className)} {...props}>
			<div
				className={classNames(
					'd-flex align-items-center',
					'p-3 rounded-2',
					'transition-base',
					{
						'bg-l25-info-hover': !darkModeStatus,
						'bg-lo50-info-hover': darkModeStatus,
						'bg-l10-info': !darkModeStatus && isActive,
						'bg-lo25-info': darkModeStatus && isActive,
					},
				)}>
				<ChatAvatar
					src={src}
					srcSet={srcSet}
					isOnline={isOnline}
					unreadMessage={unreadMessage}
					color={color}
					size={size}
					className='me-3'
				/>
				<div className='d-grid'>
					<div className='d-flex flex-wrap d-xxl-block'>
						<span className='fw-bold fs-5 me-3'>{`${name} ${surname}`}</span>
						{lastSeenTime && (
							<small
								className={classNames(
									'text-info fw-bold px-3 py-1 rounded-pill align-top text-nowrap',
									{
										'bg-l10-info': !darkModeStatus,
										'bg-lo25-info': darkModeStatus,
									},
								)}>
								{lastSeenTime}
							</small>
						)}
					</div>
					<div className='text-muted text-truncate'>{latestMessage}</div>
				</div>
			</div>
		</div>
	);
};
ChatListItem.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
	className: PropTypes.string,
	isOnline: PropTypes.bool,
	color: PropTypes.string,
	size: PropTypes.number,
	name: PropTypes.string.isRequired,
	surname: PropTypes.string.isRequired,
	latestMessage: PropTypes.string,
	unreadMessage: PropTypes.number,
	isActive: PropTypes.bool,
	lastSeenTime: PropTypes.string,
};
ChatListItem.defaultProps = {
	srcSet: null,
	className: null,
	isOnline: false,
	color: 'primary',
	size: 64,
	latestMessage: null,
	unreadMessage: null,
	isActive: false,
	lastSeenTime: null,
};

export const ChatHeader = ({ to }) => {
	return (
		<>
			<strong className='me-2'>To:</strong>
			{to}
		</>
	);
};
ChatHeader.propTypes = {
	to: PropTypes.string.isRequired,
};

export const ChatMessages = ({ messages, isReply, fileClickHandler, ...props }) => {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className='chat-messages' {...props}>
			{messages.map((i) => {

				return <>
					<div
						key={i.id}
						className={classNames('chat-message', { 'chat-message-reply': isReply })}>
						{i.message ?? i.body}
					</div>
					{
						i._attachments &&
						i._attachments.map((file) => {
							return <div
								className='cursor-pointer hover-underline'
								onClick={() => fileClickHandler(file)}>{file?.fileName ?? file?.title ?? 'null'}</div>
						})
					}
				</>
			})}
		</div>

	);
};


export const EmailMessages = ({ messages, isReply, fileClickHandler, ...props }) => {
	console.log("Messages: ", messages);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className='chat-messages' {...props}>
			{messages.map((i) => {
				i.body = i?.body?.replace("<br/>", ' ');
				i.body = i?.body?.replace(/<[^>]+>/g, '');

				var message = i.message ?? i.body;
				message = message?.replace("<br/>", ' ').replace(/<[^>]+>/g, '');

				const abbreviated = message.length > 100 ? message.substring(0, 100) : message;
				const shouldAbbreviate = message.length > 100;
				const [isCollapsed, setIsCollapsed] = useState(shouldAbbreviate);

				const collapseButton = shouldAbbreviate ? (
					<a className='hover-underline cursor-pointer'
						style={{ color: 'inherit', textDecoration: 'none' }}
						onClick={() => setIsCollapsed(!isCollapsed)}
					>
						{isCollapsed ? "..." : <><br /><br />Read less</>}
					</a>
				) : null;

				const emailStatusIcon = i?.status == "Complete" ? <Icon icon="CheckCircleFill" color="success" /> :
					i?.status == "Error" ? <Icon icon="Error" color="danger" /> : <Icon icon="Sync" color="info" />

				return <>

					<div
						key={i.id}
						className={classNames('chat-message', "bg-warning", "text-dark", { 'chat-message-reply': isReply },)}>
						<Icon icon="Envelope" className={classNames({ "pulse-icon": i?.status == "Processing" })} />{' '}{emailStatusIcon}
						<br />
						{isCollapsed ? abbreviated : message} {collapseButton}
					</div>
					{
						i._attachments &&
						i._attachments.map((file) => {
							return <div
								className={
									classNames({ 'cursor-pointer hover-underline': file?.status == "ready" })
								}
								onClick={
									file?.status == "ready" ?
										() => fileClickHandler(file)
										: null
								}>{
									file?.fileName ?? file?.title ?? 'null'
								}
							</div>
						})
					}
				</>
			})}
		</div>

	);
};

ChatMessages.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			message: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	).isRequired,
	isReply: PropTypes.bool,
};
ChatMessages.defaultProps = {
	isReply: false,
};

export const ChatAvatar = ({
	src,
	srcSet,
	className,
	color,
	unreadMessage,
	isOnline,
	size,
	...props
}) => {
	return (
		<div
			className={classNames('chat-avatar', className)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			<div className='position-relative'>
				{src && <Avatar srcSet={srcSet} src={src} size={size} color={color} />}
				{unreadMessage && (
					<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
						{unreadMessage} <span className='visually-hidden'>unread messages</span>
					</span>
				)}
				{isOnline && (
					<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
						<span className='visually-hidden'>Online user</span>
					</span>
				)}
			</div>
		</div>
	);
};
ChatAvatar.propTypes = {
	src: PropTypes.string,
	srcSet: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string,
	unreadMessage: PropTypes.number,
	isOnline: PropTypes.bool,
	size: PropTypes.number,
};
ChatAvatar.defaultProps = {
	src: null,
	srcSet: null,
	className: null,
	color: null,
	unreadMessage: null,
	isOnline: false,
	size: 45,
};

export const ChatGroup = ({ isReply, messages, isOnline, color, fileClickHandler, ...props }) => {

	const [createdBy, setCreatedBy] = useState({})

	useEffect(async () => {
		const createdById = messages[0].createdBy;
		const createdBy = await UserService.getUserData(createdById);
		setCreatedBy(createdBy);
	}, [])


	const _Avatar = (
		<ChatAvatar
			src={createdBy?.src ?? createdBy?.picture ?? "https://www.tenforums.com/geek/gars/images/2/types/thumb_15951118880user.png"}
			srcSet={createdBy?.srcSet ?? createdBy?.picture ?? "https://www.tenforums.com/geek/gars/images/2/types/thumb_15951118880user.png"}
			username={createdBy?.username ?? createdBy?.email}
			name={createdBy?.name}
			surname={createdBy?.surname}
			isOnline={createdBy?.isOnline}
			color={createdBy?.color}
		/>
	);
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('chat-group', { 'chat-group-reply': isReply })} {...props}>
			{!isReply && _Avatar}
			<ChatMessages messages={messages} isReply={isReply} fileClickHandler={fileClickHandler} />
			{isReply && _Avatar}
		</div>
	);
};

export const EmailChatGroup = ({ isReply, messages, isOnline, color, user, fileClickHandler, ...props }) => {

	const _Avatar = (
		<ChatAvatar
			src={user?.src ?? user?.picture ?? "https://www.tenforums.com/geek/gars/images/2/types/thumb_15951118880user.png"}
			srcSet={user?.srcSet ?? user?.picture ?? "https://www.tenforums.com/geek/gars/images/2/types/thumb_15951118880user.png"}
			username={user?.username ?? user?.email}
			name={user?.name}
			surname={user?.surname}
			isOnline={user?.isOnline}
			color={user?.color}
		/>
	);
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('chat-group', { 'chat-group-reply': isReply })} {...props}>
			{!isReply && _Avatar}
			<EmailMessages messages={messages} isReply={isReply} fileClickHandler={fileClickHandler} />
			{isReply && _Avatar}
		</div>
	);
};

ChatGroup.propTypes = {
	isReply: PropTypes.bool,
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			message: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	).isRequired,
	isOnline: PropTypes.bool,
	color: PropTypes.string,
	user: PropTypes.shape({
		src: PropTypes.string,
		srcSet: PropTypes.string,
		username: PropTypes.string,
		name: PropTypes.string,
		surname: PropTypes.string,
		isOnline: PropTypes.bool,
		color: PropTypes.string,
	}).isRequired,
};
ChatGroup.defaultProps = {
	isReply: false,
	isOnline: false,
	color: null,
};

const Chat = ({ children, className }) => {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={classNames('chat-container', className)}>{children}</div>
	);
};
Chat.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
Chat.defaultProps = {
	className: null,
};

export default Chat;
