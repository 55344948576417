import React, { useRef, useEffect, useState, createContext, useContext, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './FreslerTableDropdown.css';
import classNames from 'classnames';

const DropdownContext = createContext();

const DropdownRoot = ({ children, className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const toggleRef = useRef(null);
    const menuRef = useRef(null);
    const uniqueId = useRef(`dropdown-${Math.random().toString(36).substr(2, 9)}`);

    const toggle = (event) => {
        if (event) {
            event.preventDefault();
            const rect = event.target.getBoundingClientRect();
            setPosition({ top: rect.bottom, left: rect.left });
        }
        setIsOpen(!isOpen);
    };

    const handleClickOutside = useCallback((event) => {
        
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <DropdownContext.Provider value={{ isOpen, toggle, position, toggleRef, menuRef, uniqueId }}>
            <div className={className}>
                {children}
            </div>
        </DropdownContext.Provider>
    );
};

const Toggle = ({ children, hasIcon = true }) => {
    const { toggle, toggleRef } = useContext(DropdownContext);
    return (
        <button ref={toggleRef} onClick={toggle} className="dropdown-toggle">
            {children}
            {hasIcon && <span className="dropdown-caret">▼</span>}
        </button>
    );
};

const Menu = ({ children }) => {
    const { isOpen, menuRef, position } = useContext(DropdownContext);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div
            ref={menuRef}
            className="dropdown-menu show"
            style={{ position: 'fixed', top: position.top, left: position.left }}
        >
            {children}
        </div>,
        document.body
    );
};

const Item = ({ children, icon, onClick }) => {
    const { toggle } = useContext(DropdownContext);
    const handleClick = (event) => {
        console.log('handleOnClick', onClick);
        if (onClick) {
            onClick(event);
            toggle();
        }
    };
    return (
        <div className={classNames({
            'dropdown-item': true,
            'dropdown-item-no-hover': !onClick
        })} onClick={handleClick}>
            {icon && <span className="dropdown-item-icon">{icon}</span>}
            {children}
        </div>
    );
};

export const Dropdown = Object.assign(DropdownRoot, {
    Toggle,
    Menu,
    Item
});

export default Dropdown;
