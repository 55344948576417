import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TrackerBoardEmailBlastEmailPDFAttachment } from '../../components/TrackerBoardEmailBlastModal/TrackerBoardEmailBlastEmailPDFAttachment';
import { LoadingFullPage } from '../../components/loading/LoadingFullPage/LoadingFullPage';
import FileService from '../../services/FileService';
import { BasicPDFViewer } from '../../components/pdf/BasicPDFViewer/BasicPDFViewer';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';


const PDFViewer = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [fileInfo, setFileInfo] = useState(null)
    const { fileId } = useParams();

    useEffect(async () => {
        setIsLoading(true)
        console.log("Getting info for", fileId)

        const info = await FileService.getFileInfo(fileId);
        const blob = await FileService.getFileBlob(fileId)

        // const [info, blob] = await Promise.all([
        //     FileService.getFileInfo(fileId),
        //     FileService.getFileBlob(fileId)
        // ])

        console.log("Got info for", fileId, info, blob)
        const fileUrl = window.URL.createObjectURL(blob);
        info.url = fileUrl;
        console.log("Printing info for term", info)
        setFileInfo(info);
        setIsLoading(false)
    }, [fileId]);

    return (
        isLoading ?
            <LoadingFullPage /> :
            <PageWrapper className='justify-content-center text-center' title='Loading'>
                <div className='justify-content-center text-center' title='Loading'>
                    <Page container='fluid' style={{ zIndex: "1020", flex: "1 1 auto" }}>
                        <div className='row h-100'>
                            <div className='col-12'>
                                <BasicPDFViewer attachment={fileInfo} customText="" />
                            </div>
                        </div>
                    </Page>
                </div>
            </PageWrapper>
    )
}

export default PDFViewer;