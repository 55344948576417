import { Alert as Al2 } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Icon } from './Icon';

export const Alert = ({
    children,
    className,
    color,
    isDismissible,
    isOutline,
    isLight,
    shadow,
    icon,
    rounded,
    borderWidth,
    ...props
}) => {
    const [status, setStatus] = useState(true);
    if (status) {
        return <Al2 variant={color} onClose={() => setStatus(false)} dismissible>
            {
                icon && <div className="alert-icon">
                    <Icon icon={icon} />
                </div>
            }
            <div className='alert-text'>{children}</div>
        </Al2>
    }

    return null;
};
