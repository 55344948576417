import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Alert } from './subs/Alert';
import { Button } from './subs/Button';

import { Card } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import './FreslerTableColumnEditModal.css';
import { FreslerTableColumnEditTableOptionalRow } from './FreslerTableColumnEditTableOptionalRow';
import { FreslerTableColumnEditTableRow } from './FreslerTableColumnEditTableRow';
import { FreslerTableColumnEditTableRowAdd } from './FreslerTableColumnEditTableRowAdd';
import { getNewGuid } from './FreslerTableUtilities';

export const FreslerTableColumnEditModal = ({
    isFreslerTableColumnEditModalOpen, setIsFreslerTableColumnEditModalOpen,
    allData,
    cols, setCols,
    optionalCols = [], allFields = []
}) => {

    const [allDataFields, setAllDataFields] = useState([])
    const [_cols, set_cols] = useState([])

    useEffect(() => {
        const fieldOptions = allFields.map(field => ({ value: field, label: field }))
        setAllDataFields(fieldOptions)

        set_cols(cols ?? [])
    }, [cols, allData])

    const _optionalCols = optionalCols?.filter(col => {
        const curColFields = _cols?.map(col => col.field)
        const isNotDisplayed = !curColFields.includes(col.field)
        return isNotDisplayed
    })

    const handleDrop = (result) => {

        if (!result.destination) return;
        const items = Array.from(_cols)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        set_cols(items)
    }

    const updateColumns = () => {
        setCols(_cols)
        setIsFreslerTableColumnEditModalOpen(false)
    }

    const addNewColumn = (newColumn) => {
        const c = [..._cols]
        c.push({ id: '' + getNewGuid(), ...newColumn })
        set_cols(c)
    }

    function findDuplicateDisplayNames(objects) {
        const displayNameCounts = {};
        const duplicates = [];

        // Count the occurrences of each displayName
        objects.forEach(obj => {
            const displayName = obj.displayName;
            displayNameCounts[displayName] = (displayNameCounts[displayName] || 0) + 1;
        });

        // Collect objects with duplicate displayNames
        objects.forEach(obj => {
            const displayName = obj.displayName;
            if (displayNameCounts[displayName] > 1 && !duplicates.includes(displayName)) {
                duplicates.push(displayName);
            }
        });

        return duplicates;
    }

    const duplicateCols = findDuplicateDisplayNames(_cols)

    return (
        <Modal
            show={isFreslerTableColumnEditModalOpen}
            onHide={() => setIsFreslerTableColumnEditModalOpen(false)}
            title='Edit Columns'
            isCentered
            size='xl'
            isScrollable>
            <Modal.Header closeButton>
                <Modal.Title id='upcomingEdit'>Edit Column List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId={"fresler-column-list"} type={"fresler-column-list"}>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <Card strech className='mb-4'>
                                    <Card.Header>
                                        {/* <CardLabel> */}
                                        <Card.Title>
                                            Displayed Columns
                                        </Card.Title>
                                        <Card.Subtitle>
                                            Add or remove columns from the table
                                        </Card.Subtitle>
                                        {/* </CardLabel> */}
                                    </Card.Header>
                                    <Card.Body style={{ overflowY: "visible" }}>
                                        {
                                            duplicateCols.length > 0 &&
                                            <Alert variant='warning' icon='Error' isOpen={duplicateCols.length > 0}>
                                                There are duplicate columns with the same name. Please rename the following columns: {duplicateCols.join(', ')}
                                            </Alert>
                                        }
                                        <div className='div-table'>
                                            <div className='fresler-table-row'>
                                                <div className='move-col fresler-grouped-table-cell'></div>
                                                <div className='fresler-grouped-table-cell'>Column Name</div>
                                                <div className='fresler-grouped-table-cell'>Data Field</div>
                                                <div className='fresler-grouped-table-cell'>Data Field Type</div>
                                                <div className='delete-col fresler-grouped-table-cell'></div>
                                            </div>
                                            {
                                                _cols?.map((col, index) => {
                                                    return (

                                                        <Draggable
                                                            key={`${index}`}
                                                            draggableId={`${index}`}
                                                            index={index}>
                                                            {(provided) => (

                                                                <FreslerTableColumnEditTableRow
                                                                    dndProvided={provided}

                                                                    key={index}
                                                                    index={index}

                                                                    col={col}
                                                                    _cols={_cols}
                                                                    set_cols={set_cols}
                                                                    allDataFields={allDataFields}
                                                                />


                                                            )}
                                                        </Draggable>
                                                    )
                                                })
                                            }
                                            <FreslerTableColumnEditTableRowAdd
                                                allDataFields={allDataFields}
                                                addNewRow={addNewColumn} />
                                        </div>
                                    </Card.Body>
                                </Card>
                                {
                                    _optionalCols?.length > 0 && <Card strech>
                                    <Card.Header>
                                        {/* <CardLabel> */}
                                        <Card.Title>
                                            Optional Columns
                                        </Card.Title>
                                        <Card.Subtitle>
                                            Drag a suggested column from this list to the Displayed Columns list above.
                                        </Card.Subtitle>
                                        {/* </CardLabel> */}
                                    </Card.Header>
                                    <Card.Body style={{ overflowY: "visible" }}>
                                        <div className='div-table'>
                                            <div className='fresler-table-row'>
                                                <div className='move-col fresler-grouped-table-cell'></div>
                                                <div className='fresler-grouped-table-cell'>Column Name</div>
                                                <div className='fresler-grouped-table-cell'>Data Field</div>
                                                <div className='fresler-grouped-table-cell'>Data Field Type</div>
                                                <div className='delete-col fresler-grouped-table-cell'></div>
                                            </div>
                                            {
                                                _optionalCols?.map((col, index) => {
                                                    return (
                                                        <FreslerTableColumnEditTableOptionalRow
                                                            col={col}
                                                            addColumn={addNewColumn}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                                }


                            </div>

                        )}
                    </Droppable>
                </DragDropContext>
            </Modal.Body>
            <Modal.Footer className='bg-transparent'>
                <Button color="success"
                    onClick={() => updateColumns(_cols)}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
