import React from 'react'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import { Dots } from 'loading-animations-react';

export const LoadingFullPage = ({ loadingMessage = "Loading" }) => {
    return (
        <PageWrapper className='justify-content-center text-center' title='Loading'>
            <div className='justify-content-center text-center' title='Loading'>
                <Dots text={loadingMessage}></Dots>
            </div>
        </PageWrapper>
    )
}
