import React, { useState } from 'react';
import { Button } from './subs/Button';
import { Dropdown } from './FreslerTableDropdown';
import { Icon } from './subs/Icon';

function FreslerTableHeaderActionDropdown({
    col, updateCol, groupBy, setGroupBy, data, checkedItems,
    updateData, updateItemSet, addItem, accessRowItem, updateItem,
}) {

    const [activeAction, setActiveAction] = useState(null)
    const [isActionViewOpen, setIsActionViewOpen] = useState(false)

    const completeAction = () => {
        setActiveAction(false)
        setIsActionViewOpen(false)
    }

    const handleActionClick = (action) => {
        if (action?.runAction) {
            // TODO: Call completeAction based on whether the returned promise resolves or rejects
            action?.runAction(
                data, col, checkedItems,
                updateItem, updateItemSet, updateData, addItem
            )
        }

        else if (action?.renderInit) {
            setActiveAction(action)
            setIsActionViewOpen(true)
        }
    }

    const _setIsActionViewOpen = (isO) => {
        setIsActionViewOpen(isO)
        if (!isO) {
            setActiveAction(null)
        }
    }

    const rendered = (activeAction?.renderInit)
        ? activeAction?.renderInit(completeAction, _setIsActionViewOpen, isActionViewOpen, data, checkedItems)
        : <></>


    return (
        <>
            <Dropdown className='fresler-table-column-header-filter-dropdown' isOpen={true} >
                <Dropdown.Toggle hasIcon={false}>
                    <Icon
                        icon='PlayFill'
                        className='fresler-dropdown-toggle'
                        hoverShadow='default'
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        col?.actions?.map((action, index) => (
                            <Dropdown.Item
                                key={index}
                                onClick={() => handleActionClick(action, col, index)}>
                                <Button
                                    size='sm'
                                    icon={action?.icon}
                                >
                                    {action?.title}
                                </Button>
                            </Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
            {
                isActionViewOpen &&
                rendered
            }
        </>

    );
}

export default FreslerTableHeaderActionDropdown;