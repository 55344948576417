import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { DefaultIntFilterInputRenderer } from './Filters/Renderers/DefaultIntFilterInputRenderer';
import { DefaultSelectFilterInputRenderer } from './Filters/Renderers/DefaultSelectFilterInputRenderer';
import { DefaultStringFilterInputRenderer } from './Filters/Renderers/DefaultStringFilterInputRenderer';

export const getNewGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};
export const isValidEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}
export const dragHandleCellWidth = 38
export const checkCellWidth = 42
export const getDataColsTotalWidth = (cols) => {
    return cols.reduce((acc, col) => acc + (col?.width || 200), 0)
}
export const getRowWidth = (cols, enableRowSelection) => {
    return getDataColsTotalWidth(cols) + dragHandleCellWidth + (enableRowSelection ? checkCellWidth : 0)
}

export const isNullOrEmptyString = (str) => {
    return str === null || str === undefined || str === '' || /^\s*$/.test(str)
}

export const getOptionStringValue = (option) => {
    const strValue = typeof option?.label === 'string' ? option?.label : option?.value ?? ""
    return strValue
}

export const getColumnOptionsMap = (col) => {
    const optionsMap = {}
    col?.options?.forEach((opt) => {
        optionsMap[opt.value] = opt?.stringLabel ?? getOptionStringValue(opt) ?? ""
    })
    return optionsMap
}

export const getColumnSortFunction = (sortBy, sortOrder, nested = true) => {
    const fieldType = sortBy['type']
    const field = sortBy['field']
    const comparatorProvider = sortBy.comparatorProvider

    if (comparatorProvider) {
        return comparatorProvider(sortOrder)
    }

    switch (fieldType) {
        case 'select':
        case 'createselect':
            return (a, b) => {

                const aval = (nested ? a[field] : a) ?? "";
                const bval = (nested ? b[field] : b) ?? "";

                const acomp = sortBy.optionsMap[aval] ?? aval ?? ""
                const bcomp = sortBy.optionsMap[bval] ?? bval ?? ""

                if (sortOrder === 'asc') {
                    return acomp.localeCompare(bcomp);
                } else {
                    return bcomp.localeCompare(acomp);
                }
            };
        case 'number':
        case 'int':
        case 'percentage':
        case 'float':
            return (a, b) => {

                const aval = (nested ? a[field] : a) ?? -Number.MAX_SAFE_INTEGER;
                const bval = (nested ? b[field] : b) ?? -Number.MAX_SAFE_INTEGER;

                if (sortOrder === 'asc') {
                    return aval - bval;
                } else {
                    return bval - aval;
                }
            };
            break;
        case 'string':
        default:
            return (a, b) => {

                const serializer = sortBy.serialize ? sortBy.serialize :
                    nested ? (row, col) => row[field] : (val, col) => val

                const aval = serializer(a, sortBy) ?? '';
                const bval = serializer(b, sortBy) ?? '';

                if (sortOrder === 'asc') {
                    return aval.localeCompare(bval);
                } else {
                    return bval.localeCompare(aval);
                }
            };
            break;
    }
}

export const defaultIntFilterApplicator = (row, filter) => {
    const value = row?.[filter.field] ?? 0
    const min = filter.min
    const max = filter.max
    if (min && max) {
        return value >= min && value <= max
    } else if (min) {
        return value >= min
    } else if (max) {
        return value <= max
    } else {
        return true
    }
}

export const defaultStringFilterApplicator = (row, filter) => {
    const col = filter?.col
    const defaultSerializer = (dataItem, col) => dataItem?.[col.field]
    const serializationFunc = col?.serialize ?? defaultSerializer

    const value = (serializationFunc(row, col) ?? '').toLowerCase()
    const filterValue = (filter.value ?? '').toLowerCase()

    if (filter.mode === 'regex') {
        const regex = new RegExp(filterValue)
        return regex.test(value)
    } else {
        return value.includes(filterValue)
    }
}

const defaultSelectFilterApplicator = (row, filter) => {
    const value = row?.[filter.field]
    const selected = filter.selected
    if (selected && selected.size > 0) {
        return selected.has(value)
    } else {
        return true
    }
}

export const getDefaultApplicator = (type) => {
    switch (type) {
        case 'select':
        case 'createselect':
            return defaultSelectFilterApplicator
        case 'number':
        case 'int':
        case 'percentage':
        case 'float':
            return defaultIntFilterApplicator
            break;
        case 'string':
        default:
            return defaultStringFilterApplicator
    }
}

export const getDefaultFilterRender = (col) => {
    const fieldType = col['type']
    switch (fieldType) {
        case 'select':
        case 'createselect':
            return DefaultSelectFilterInputRenderer
        case 'number':
        case 'int':
        case 'percentage':
        case 'float':
            return DefaultIntFilterInputRenderer
            break;
        case 'string':
        default:
            return DefaultStringFilterInputRenderer
    }
}

export function isNullOrEmpty(value) {
    return value === null || value === undefined || value === '';
}

const defaultFilterSetApplicator = (data, filters) => {
    let newData = [...data]
    filters.forEach((filter) => {
        newData = newData.filter((row) => filter.applicator(row, filter.filter))
    })
    return newData
}

export const filterData = (data, filters, customFilterSetApplicator) => {
    if (filters.length === 0) return data

    const filterSetApplicator = customFilterSetApplicator || defaultFilterSetApplicator
    const newData = filterSetApplicator(data, filters)
    return newData
}


export const defaultDataAccessor = (row) => row

export const DefaultViewComponent = ({ dataItem, col, updateData, updateItem, isNewItem }) => {

    const [val, setVal] = useState(dataItem?.[col.field])

    useEffect(() => {
        setVal(dataItem?.[col.field])
    }, [dataItem, col])

    const isNullOrEmptyString = (str) => {
        return str === null || str === undefined || str === ''
    }

    const shouldValidate = col?.required || col?.type === 'email' || col?.validator

    const isValid = (val) => {
        var isValid = true

        if (col?.required) {
            isValid = isValid && !isNullOrEmptyString(val)
        }

        if (col?.type == 'email') {
            isValid = isValid && (isNullOrEmptyString(val) || isValidEmail(val))
        }

        if (col?.validator) {
            isValid = isValid && col.validator(val)
        }

        return isValid
    }

    return (
        <Form.Control
            isValid={shouldValidate && isValid(val)}
            isInvalid={shouldValidate && !isValid(val)}
            className={
                classNames({
                    "fresler-table-data-cell-input": true,
                    "border border-danger p-3": !isValid(val) && !isNewItem,
                })
            }
            placeholder={isNewItem ? "Add a new item..." : col?.placeholder ?? ""}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    if (isNewItem) {
                        updateItem(dataItem, col, val)
                    } else {
                        event.target.blur();
                    }
                    event.preventDefault()
                }
            }}
            onBlur={(e) => {
                if (!(isNullOrEmptyString(val) && isNewItem)) {
                    updateItem(dataItem, col, val)
                }
            }}
            onChange={
                (e) => {
                    setVal(e.currentTarget.value)
                }
            }

            value={val}
        />
    )
}

export const ProgressBarViewComponent = ({ dataItem, col, updateData, updateItem }) => {

    const [editing, setEditing] = useState(false);
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        const perc = dataItem?.[col.field] ?? 0
        setPercentage(perc)
    }, [dataItem, col])

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleBlur = () => {
        updateItem(dataItem, col, percentage)
        setEditing(false);
    };

    const handleInputChange = (e) => {
        const newPercentage = parseInt(e.target.value, 10);

        if (!isNaN(newPercentage) && newPercentage >= 0 && newPercentage <= 100) {
            setPercentage(newPercentage);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
            e.preventDefault()
        }
    };

    var variant = 'success';

    switch (true) {
        case percentage < 25:
            variant = 'danger';
            break;
        case percentage >= 25 && percentage < 50:
            variant = 'warning';
            break;
        case percentage >= 50 && percentage < 75:
            variant = 'info';
            break;
        case percentage >= 75:
            variant = 'success';
            break;
        default:
            variant = ''; // default percentage if none of the conditions match
            break;
    }

    return (
        <div className="progress-bar-container">
            {editing && updateData ? (
                <input
                    type="number"
                    value={percentage}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
            ) : (
                <div onClick={handleEditClick}>
                    <ProgressBar
                        variant={variant}
                        isAnimated={false}
                        className='flex-grow-1'
                        now={percentage}
                        style={{
                            height: 5,
                        }}
                    />
                    <div className="percentage-label">{percentage}%</div>
                </div>
            )}
        </div>
    );

}

export const SelectViewComponent = ({ dataItem, col, updateData, updateItem, isNewItem }) => {

    const selectedOpt = col?.options.find((opt) => opt.value === dataItem?.[col.field]) ?? (dataItem?.[col.field] ? { value: dataItem?.[col.field], label: dataItem?.[col.field] } : null)

    return (
        col?.type === 'select' ?
            <Select
                className="basic-single"
                classNamePrefix="select"
                value={selectedOpt}
                options={col?.options ?? []}
                placeholder={isNewItem ? "Add a new item..." : "Select..."}
                onChange={e => {
                    updateItem(dataItem, col, e.value)
                }}
            /> :
            <CreatableSelect
                className="basic-single"
                classNamePrefix="select"
                value={selectedOpt}
                options={col?.options ?? []}
                placeholder={isNewItem ? "Add a new item..." : "Select..."}
                onChange={e => {
                    updateItem(dataItem, col, e.value)
                }}
            />
    )
}

export const DateViewComponent = ({ dataItem, col, updateData, updateItem }) => {

    const dueDateData = dataItem?.[col.field]
    const dueDate = dueDateData && typeof dueDateData === "string" ? Date.parse(dueDateData) : dueDateData;

    return (
        <DatePicker
            selected={dueDate}
            onChange={(date) => {
                updateItem(dataItem, col, date)
            }}
        />
    )
}

export const DateViewRender = (dataItem, col, updateData, updateItem) => {

    return <DateViewComponent dataItem={dataItem} col={col} updateData={updateData} updateItem={updateItem} />
}

export const DefaultViewRender = (dataItem, col, updateData, updateItem, isNewItem) => {

    return <DefaultViewComponent dataItem={dataItem} col={col} updateData={updateData} updateItem={updateItem} isNewItem={isNewItem} />
}

export const ProgressBarViewRender = (dataItem, col, updateData, updateItem) => {

    return <ProgressBarViewComponent dataItem={dataItem} col={col} updateData={updateData} updateItem={updateItem} />
}

export const SelectViewRender = (dataItem, col, updateData, updateItem, isNewItem) => {

    return <SelectViewComponent dataItem={dataItem} col={col} updateData={updateData} updateItem={updateItem} isNewItem={isNewItem} />
}

export const getDefaultViewRenderer = (column) => {
    const fieldType = column['type']
    switch (fieldType) {
        case 'createselect':
        case 'select':
            return SelectViewRender
        case 'percentage':
            return ProgressBarViewRender
        case 'date':
            return DateViewRender
        case 'number':
        case 'int':
        case 'float':
            return DefaultViewRender
        case 'string':
        default:
            return DefaultViewRender
    }
}

export const defaultDataRenderer = (dataItem, col, updateData, updateItem, isNewItem = false, broadcast = () => { }) => {
    const fieldRenderer = col["viewRender"] || getDefaultViewRenderer(col)
    return fieldRenderer(dataItem, col, updateData, updateItem, isNewItem, broadcast)
}



export const getFieldsFromData = (data) => {
    const fields = []
    data.forEach((row) => {
        Object.keys(row).forEach((key) => {
            if (!fields.includes(key)) {
                fields.push(key)
            }
        })
    })
    return fields.sort()
}