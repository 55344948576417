import { useEffect, useState } from 'react';
import Button from '../../bootstrap/Button';
import './TrackerColumnsEdit.css';
var xlsx = require("xlsx");
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { sampleTasks } from '../../../pages/trackers/TrackerPage/TrackerBoardExampleTasks';
import './TrackerColumnsEdit.css';
import Icon from '../../icon/Icon';
import Select from '../../bootstrap/forms/Select';
import Input from '../../bootstrap/forms/Input';
var xlsx = require("xlsx");

const cols = [
    {
        displayName: "Fund Name",
        field: "Fund Name",
        type: "string",
        editable: true,
    },
    {
        displayName: "Contact Name",
        field: "Contact Name",
        type: "string",
        editable: true,
    },
    {
        displayName: "Contact Email",
        field: "Contact Email",
        type: "string",
        editable: true,
    },
    {
        displayName: "Fund Size ($M)",
        field: "Fund Size",
        type: "int",
        editable: true,
    },
]

export const TrackerColumnsEdit = ({ trackerWorkstreams, updateWorkstreams, updateWorkstream }) => {

    const [newWS, setNewWS] = useState({
        type: "Task",
        title: "",
    })
    const [exampleTasks, setExampleTasks] = useState(sampleTasks[0])

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...trackerWorkstreams];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        updateWorkstreams(updatedList);
    };

    useEffect(() => {
        const randomExampleTasks = sampleTasks[Math.floor(Math.random() * sampleTasks.length)]
        setExampleTasks(randomExampleTasks)

    }, [trackerWorkstreams])

    return (
        <>
            <div className='row'>
                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                        {(provided) => (
                            <div
                                className="rounded col-xl-12 list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {trackerWorkstreams?.map((item, index) => {
                                    return !item?.deleted &&
                                        <Draggable key={item?._id} draggableId={item?._id} index={index}>
                                            {(provided) => (
                                                <div
                                                    className="rounded item-container mb-2 border-bottom"
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >
                                                    <div className='row align-center'>
                                                        <div className='col-auto d-flex align-items-center' >
                                                            <Icon
                                                                className="drag-handles st-icon hover-hidden ml-3"
                                                                forcefFamily="bootstrap"
                                                                icon="GripVertical"
                                                            />
                                                        </div>
                                                        <div className='col-6 trackerboard-edit-taskpanel-titleinput fs-5'>
                                                            <Input
                                                                className="slim-input"
                                                                value={item?.title}
                                                                onChange={e => updateWorkstream({ ...item, title: e.target.value }, index)}
                                                            // validFeedback='Looks good!'
                                                            />
                                                        </div>
                                                        <div className=' col-3 trackerboard-edit-taskpanel-typeselectfloat-end text-end float-right text-right'>
                                                            <Select
                                                                className="slim-input"
                                                                placeholder="Type"
                                                                value={item?.type}
                                                                onChange={e => updateWorkstream({ ...item, type: e.target.value }, index)}
                                                            >
                                                                <option value="Task">To-Do</option>
                                                                <option value="Email">Email Blast</option>
                                                            </Select>
                                                        </div>
                                                        <div className='col-auto ml-auto' >
                                                            <Button
                                                                color="danger"
                                                                icon="XLg"
                                                                onClick={
                                                                    () => {
                                                                        updateWorkstream({ ...item, deleted: true }, index)
                                                                    }
                                                                }
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className='row mt-4' style={{ paddingBottom: "70px" }}>
                <div className='col-auto d-flex align-items-center' >
                    <Icon
                        className="drag-handles st-icon hover-hidden ml-3"
                        forcefFamily="bootstrap"
                        icon="GripVertical"
                    />
                </div>
                <div className='col-6'>
                    <Input
                        className="slim-input"
                        placeholder="+Add New Task"
                        value={newWS?.title}
                        onChange={e => setNewWS({ ...newWS, title: e.target.value })}
                    // validFeedback='Looks good!'
                    />
                </div>
                <div className='col-3'>
                    <Select placeholder="Type"
                        className="slim-input"
                        value={newWS?.type}
                        onChange={e => setNewWS({ ...newWS, type: e.target.value })}
                    >
                        <option value="Task">To-Do</option>
                        <option value="Email">Email Blast</option>
                    </Select>
                </div>
                <div className='col-auto ml-auto'>
                    <Button
                        icon="Plus"
                        color="success"
                        disabled={!newWS?.title}
                        onClick={() => {
                            updateWorkstream(newWS)
                            setNewWS({
                                type: "Task",
                                title: ""
                            })
                        }}
                    >
                        Add Task
                    </Button>
                </div>
            </div>
        </>
    )
}
