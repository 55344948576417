import { useEffect, useState } from 'react';
import Modal, {
    ModalBody,
    ModalHeader,
    ModalTitle
} from '../bootstrap/Modal';
import FileService from '../../services/FileService';
import { BasicPDFViewer } from '../pdf/BasicPDFViewer/BasicPDFViewer';
import { LoadingFullPage } from '../loading/LoadingFullPage/LoadingFullPage';
import a4Logger from '../../services/a4Logger';

export const PDFViewerModal = ({ isPDFViewerModalOpen, setIsPDFViewerModalOpen, file }) => {

    const fileId = file?._id
    const title = file?.fileName
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [fileUrl, setFileUrl] = useState(false)

    useEffect(async () => {
        setIsLoading(true)
        console.log("Getting info for", fileId)
        if (fileId) {
            try {
                const fileBlob = await FileService.getFileBlob(file?._id)
                console.log("File blob obtained")
                const url = window.URL.createObjectURL(fileBlob)
                setFileUrl(url)
                console.log("File loaded", url)
            }
            catch (error) {
                a4Logger.logError("Error getting file blob", { error: error })

            }
        }
        setIsLoading(false)
    }, [fileId])


    return (
        <Modal
            setIsOpen={setIsPDFViewerModalOpen}
            isOpen={isPDFViewerModalOpen}
            isCentered
            isScrollable
            size='xl'
            fullScreen='xxl'
        >
            <ModalHeader setIsOpen={setIsPDFViewerModalOpen}>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                {
                    isLoading ?
                        <LoadingFullPage />
                        :
                        isError ?
                            <div>Error</div>
                            :
                            <>
                                <BasicPDFViewer attachment={{ ...file, url: fileUrl }} customText="" />
                            </>
                }
            </ModalBody>
        </Modal>
    )
}