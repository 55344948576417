import { GripVertical } from "react-bootstrap-icons";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Button } from './subs/Button';
import { Form } from 'react-bootstrap';
import { Icon } from "./subs/Icon";
import './FreslerTableColumnEditModal.css';
import React from 'react';

export const FreslerTableColumnEditTableRow = ({ index, col, _cols, set_cols, allDataFields, dndProvided }) => {

    const updateCol = (index, field, value) => {
        const newCols = [..._cols]

        newCols[index][field] = value
        set_cols(newCols)
    }

    return (
        <div className='fresler-table-row'

            ref={dndProvided.innerRef}
            {...dndProvided.draggableProps}
        >
            <div className='fresler-grouped-table-cell'>
                <span
                    {...dndProvided.dragHandleProps}
                >
                    <GripVertical />
                </span>
            </div>
            <div className='fresler-grouped-table-cell'>
                <Form.Control
                    value={col.displayName}
                    onChange={e => {
                        updateCol(index, 'displayName', e.target.value)
                    }}
                />
            </div>
            <div className='fresler-grouped-table-cell'>
                <CreatableSelect
                    isSearchable
                    options={allDataFields}
                    value={{ value: col.field, label: col.field }}
                    onChange={e => {
                        updateCol(index, 'field', e.value)
                    }} />
            </div>
            <div className='fresler-grouped-table-cell'>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="color"
                    value={{ value: col.type, label: col.type }}
                    options={[
                        { value: 'string', label: 'string' },
                        { value: 'number', label: 'number' },
                    ]}
                    onChange={e => {
                        updateCol(index, 'type', e.value)
                    }}
                />
            </div>
            <div className='fresler-grouped-table-cell'>
                <Button
                    color='danger'
                    icon='XLg'
                    onClick={() => {
                        const c = [..._cols]
                        c.splice(index, 1)
                        set_cols(c)
                    }}>
                </Button>
            </div>
        </div>
    )
}
