import { deleteJson, getJson, postJson, putJson } from './ServiceHelperMethods';

class ListService {

    static async getLists() {
        return await getJson(`lists`);
    }

    static async getList(id) {
        const lists = await getJson(`lists/id`)

        return lists[0];
    }

    static async upsertLists(lists) {
        return await putJson(`lists`, lists);
    }

    static async deleteList(listId) {
        return await deleteJson(`lists/${listId}`);
    }

    static async upsertListItems(listItems) {
        return await putJson(`listitems`, listItems);
    }
}

export default ListService;