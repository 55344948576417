import React from 'react'
import Card, { CardBody } from '../bootstrap/Card'
import Icon from '../icon/Icon'


export const ErrorItem = ({ message }) => {
    return (
        <div className='row'>
            <div className='col'>
                <span className="text-danger">
                    <Icon
                        icon='Info'
                        size='lg'
                        className='ms-1 mr-1 cursor-help'
                    />{message}</span>
            </div>
        </div>
    )
}


export const WizardGeneratorErrorDisplayPanel = ({ errorMessages }) => {

    return (
        (errorMessages?.length > 0) &&
        <Card className="mt-3 mb-0 no-shadow">

            <CardBody>
                {
                    errorMessages?.map((message, index) => {
                        return <ErrorItem key={index} message={message} />
                    })
                }
            </CardBody>
        </Card>
    )
}


