import Icon from "../../../components/icon/Icon"

export const getCommentsIcon = (task) => {
    const numComments = task?._numComments

    if (numComments > 0) {
        return <Icon size="lg" icon="Comment" color="info" />
    }
    return null
}

export const getEmailIcon = (task) => {
    const numEmails = task?._numEmails

    if (numEmails > 0) {
        return <Icon size="lg" icon="Mail" color="warning" />
    }
    return null
}

export const getSubTasksIcon = (task) => {
    const numSubTasks = task?._numSubTasks

    if (numSubTasks > 0) {
        return <Icon size="lg" icon="Checklist" />
    }
    return null
}

export const getAttachmentsIcon = (task) => {
    const numAttachments = task?.files?.length ?? 0

    if (numAttachments > 0) {
        return <Icon size="lg" icon="Attachment" />
    }
    return null
}

const getStatusIcon = (task) => {
    const status = task?.status

    if (status === "Blocked") {
        return <Icon size="lg" icon="Block" color="danger" />
    }
    else if (status === "In Progress") {
        return <Icon size="lg" icon="Sync" color="info" />
    }
    else if (status === "Complete") {
        return <Icon size="lg" icon="Check" color="success" />
    }
    else if (status === "Skipped") {
        return <Icon size="lg" icon="StepOver" color="muted" />
    }
    return null
}

export const getTaskIcons = (task) => {
    const icons = [
        getStatusIcon(task),
        getCommentsIcon(task),
        getSubTasksIcon(task),
        getAttachmentsIcon(task),
        getEmailIcon(task)
    ]

    return icons.filter(x => x != null)
}