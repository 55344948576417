import React from 'react'
import CreatableSelect from 'react-select/creatable';

export const FreslerTableColumnFieldMapPanel = ({ allFields, col, updateCol }) => {

    const allDataFields = allFields.map(field => {
        return {
            value: field,
            label: field
        }
    })

    return (
        <div className='fresler-table-col-map-area'>
            <CreatableSelect
                isSearchable
                options={allDataFields}
                value={{ value: col.field, label: col.field }}
                onChange={e => {
                    updateCol({ ...col, field: e.value })
                }} />
        </div>
    )
}
