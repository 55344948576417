import React, { useEffect, useState, createRef } from 'react'
import { useParams } from 'react-router-dom';
import { LoadingFullPage } from '../../../components/loading/LoadingFullPage/LoadingFullPage';
import { FileJobDetailsView } from './FileJobDetailsView';
import FileJobService from '../../../services/FileJobService';
import { isNullOrEmpty, reduceFileStatuses, getStatusIcon } from '../../../services/Common';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import Page from '../../../layout/Page/Page';
import a4Logger from '../../../services/a4Logger';

export const FileJobDetailsPage = () => {

    const link = createRef();

    const { fileJobId } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [fileJob, setFileJob] = useState(null)
    const [fileJobStatus, setFileJobStatus] = useState("processing")

    useEffect(async () => {
        setIsLoading(true)
        const fileJob = await FileJobService.getFileJob(fileJobId)
        const status = reduceFileStatuses(fileJob?.files ?? [])
        setFileJobStatus(status)
        FileJobService.prefetchFiles(fileJob)
        setFileJob(fileJob)
        a4Logger.logTrace('Opening FileJob Details', { fileJob: fileJob })

        setIsLoading(false)
    }, [fileJobId])

    const remaingingProcessingTime = ((((fileJob?.fileStatuses?.queued ?? 0) + (fileJob?.fileStatuses?.processing ?? 0)) / 10) * 3) + 3

    return (
        isLoading ?
            <LoadingFullPage loadingMessage='Loading File Job' /> :
            <PageWrapper title={fileJob?.title}>
                <SubHeader>
                    <SubHeaderLeft>
                        <strong className='fs-5'>{getStatusIcon(fileJobStatus)} {isNullOrEmpty(fileJob?.title) ? "FileJob" : fileJob?.title}</strong>
                        <SubheaderSeparator />
                        <span>
                            <Badge color='success' isLight>
                                {fileJob?.files?.length} file(s)
                            </Badge>
                            {' '}
                            {
                                fileJobStatus === "Processing" && <>Estimated Time: {remaingingProcessingTime} minutes</>
                            }
                        </span>
                    </SubHeaderLeft>
                    <SubHeaderRight>
                        <Button
                            color='success'
                            onClick={async (e) => {
                                e.stopPropagation();
                                await FileJobService.downloadAllFiles(fileJob, link)
                            }} >
                            <a role='button' ref={link}  >
                                <Icon icon='Download' />
                            </a>
                        </Button>
                    </SubHeaderRight>
                </SubHeader>
                <Page>
                    <FileJobDetailsView fileJob={fileJob} />
                </Page>
            </PageWrapper>
    )
}
