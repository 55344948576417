import React, { useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Button } from './subs/Button';
import { Form } from 'react-bootstrap';
import { Icon } from './subs/Icon';
import './FreslerTableColumnEditModal.css';

const initCol = {
    displayName: '',
    field: '',
    type: 'string'
}

export const FreslerTableColumnEditTableRowAdd = ({ allDataFields, addNewRow }) => {

    const isNullOrEmpty = (str) => {
        return !str || str === ''
    }


    const [newColumn, setNewColumn] = useState(initCol)

    const isDisabled = !isNullOrEmpty(newColumn.displayName) && !isNullOrEmpty(newColumn.field)

    return (
        <div className='fresler-table-row'>
            <div className='fresler-grouped-table-cell'>
            </div>
            <div className='fresler-grouped-table-cell'>
                <Form.Control
                    value={newColumn.displayName}
                    placeholder="Add a new column"
                    onChange={e => {
                        setNewColumn({ ...newColumn, displayName: e.target.value })
                    }}
                />
            </div>
            <div className='fresler-grouped-table-cell'>
                <CreatableSelect
                    isSearchable
                    options={allDataFields}
                    value={{ value: newColumn.field, label: newColumn.field }}
                    onChange={e => {
                        setNewColumn({ ...newColumn, field: e.value })
                    }} />
            </div>
            <div className='fresler-grouped-table-cell'>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="color"
                    value={{ value: newColumn.type, label: newColumn.type }}
                    options={[
                        { value: 'string', label: 'string' },
                        { value: 'number', label: 'number' },
                    ]}
                    onChange={e => {
                        setNewColumn({ ...newColumn, field: e.value })
                    }}
                />
            </div>
            <div className='fresler-grouped-table-cell'>
                <Button
                    disabled={!isDisabled}
                    icon="PlusLg"
                    color='success'
                    onClick={() => {
                        addNewRow(newColumn)
                        setNewColumn({ ...initCol })
                    }}>
                </Button>
            </div>
        </div>
    )
}
