import { Form } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import React from 'react';

export const DefaultIntFilterInputRenderer = ({ col, filter, setFilter, data = null }) => {

    function isNullOrEmpty(value) {
        return value === null || value === undefined || value === 0;
    }

    const _updateFilter = (filter) => {
        const active = !(isNullOrEmpty(filter.min) && isNullOrEmpty(filter.max))
        setFilter({ ...filter, active: active })
    }

    return (
        <div>
            <div className='row px-3'>
                <div className='col-12 px-1'>
                    <InputGroup>
                        <InputGroup.Text id='inputGroup-sizing-default'>
                            min
                        </InputGroup.Text>
                        <Form.Control
                            type='number'
                            value={filter.min}
                            onChange={(e) => _updateFilter({ ...filter, min: e.target.value })}
                            placeholder=''
                            ariaLabel='Example text with button addon'
                            ariaDescribedby='button-addon1'
                        />
                    </InputGroup>
                </div>
                <div className='col-12 px-1'>
                    <InputGroup>
                        <InputGroup.Text id='inputGroup-sizing-default'>
                            max
                        </InputGroup.Text>
                        <Form.Control
                            type='number'
                            value={filter.max}
                            onChange={(e) => _updateFilter({ ...filter, max: e.target.value })}
                            placeholder=''
                            ariaLabel='Example text with button addon'
                            ariaDescribedby='button-addon1'
                        />
                    </InputGroup>
                </div>
            </div>
        </div>
    )
}
