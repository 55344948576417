export const templates = [
    {
        "title": "Multi-buyer Sale Campaign",
        "workstreams":
            [
                { title: 'Teaser Outreach', type: "Email", priority: 'Medium', due_date_delta: -1 },
                { title: 'Send CIM', type: "Email", priority: 'High', due_date_delta: 3 },
                { title: 'Develop Client Pitch Deck', type: "Task", priority: 'Medium', due_date_delta: 7 },
                { title: 'Send Client Outreach', type: "Email", priority: 'High', due_date_delta: 14 },
                { title: 'Verify Client Financial Information', type: "Task", priority: 'Medium', due_date_delta: 21 },
                { title: 'Initial Client Sync', type: "Meeting", priority: 'Medium', due_date_delta: 22 },
                { title: 'Engage Clients Legal/Compliance Team', type: "Email", priority: 'Medium', due_date_delta: 25 },
                { title: 'Schedule Client-Investor Meeting', type: "Meeting", priority: 'Medium', due_date_delta: 31 },
                { title: 'Sync With Buyer On Client Feedback', type: "Meeting", priority: 'Medium', due_date_delta: 41 },
                { title: 'Gather Buyer Offers/LOI', type: "Email", priority: 'High', due_date_delta: 45 },
                { title: 'Close Deal', type: "Task", priority: 'High', due_date_delta: 49 },
                { title: 'Schedule Buyer/Client Celebration', type: "Meeting", priority: 'Medium', due_date_delta: 52 },
            ]

    }
]
