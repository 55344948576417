import { v4 as uuidv4 } from 'uuid';
import user6 from '../assets/img/user6.jpeg';
import Auth0ClientService from './Auth0ClientService';
import { getJson, postJson, putJson } from './ServiceHelperMethods';
import memoize from 'memoizee';

const DUMMY_USER = {
    email: '',
    user_id: sessionStorage.getItem("USERID") ?? 't' + uuidv4(),
    name: 'Guest',
    picture: user6,
    isFalseUser: true
}

class UserService {

    static errored = false;
    static initializing = false;
    static initialized = false;
    static prom = null;
    static me = null;

    static async getMyUserData(userId) {
        return await getJson(`userdata`);
    }

    static getUserData = memoize(async (userId) => {
        return await getJson(`userdata/a0/${userId}`);
    }, { promise: true, maxAge: 60000, preFetch: true });

    static async setMyUserData(userData) {
        return await putJson(`userdata`, userData);
    }

    static async mergeUsers(tempUserId) {
        return await postJson(`userdata/mergeUsers/${tempUserId}`);
    }

    static async setMyUserDataProp(propName, val) {
        var userData = UserService?.me?.userData;
        userData[propName] = val;
        UserService.me.userData = userData;
        return await UserService.setMyUserData(userData);
    }

    static async initialize() {

        if (!UserService.pme && !UserService.prom) {
            UserService.initializing = true
            const p = async () => {
                try {
                    // Was this here to prevent race condition?
                    // await new Promise(r => setTimeout(r, 20));

                    // MAKE THIS THE ELSE CASE IF AUTH0 CANNOT FIND USER
                    UserService.me = DUMMY_USER;

                    let userId = sessionStorage.getItem("USERID");

                    if (!userId) {
                        sessionStorage.setItem("USERID", DUMMY_USER?.user_id);
                    }

                    const userData = {} //await UserService.getUserData(userId);

                    UserService.me = {
                        ...DUMMY_USER,
                        userData: userData
                    }

                    return true
                }
                catch (err) {
                    a4Logger.logError(err)

                    UserService.initializing = false
                    UserService.initialized = true
                    UserService.errored = true
                    return false
                }
            }

            UserService.prom = p()
        }
        return await UserService.prom
    }

    static getMe() {
        return UserService.me
    }

    // TODO: Need to disallow users from getting each others information
    // This should not need to pass the user ID. We should be able to use the token to get the user ID
    static async getUserHeader() {

        var header = { "x-userid": UserService.me?.user_id }

        if (!(UserService.me?.isFalseUser)) {
            const token = await Auth0ClientService.getAuthToken()
            header["Authorization"] = `Bearer ${token}`
        }

        return header
    }

    static getUser(id) {
        var user = {
            email: '',
            name: 'Deleted User',
            picture: user6,
            user_id: id,
            isFalseUser: true
        }

        return user
    }

    static getOrgUsers() {
        var users = [
            {
                email: 'gas6262@hotmail.com',
                name: 'Deleted User',
                picture: user6,
                user_id: id,
                isFalseUser: true
            }
        ]

        return users
    }

    static async getOrCreateUser(user) {

    }
}

export default UserService;