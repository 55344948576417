import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import FileJobService from '../../services/FileJobService'

export const getFileJobs = createAsyncThunk('filejobs/getFileJobs', async () => {
    const filejobs = await FileJobService.getFileJobs();
    return filejobs
})

export const createFileJobs = createAsyncThunk('filejobs/createFileJobs', async (filejobs) => {
    const hydratedWs = await FileJobService.createFileJobs(filejobs);
    return hydratedWs
})

export const deleteFileJob = createAsyncThunk('filejobs/deleteFileJob', async (filejobId) => {
    const ws = await FileJobService.deleteFileJob(filejobId);
    return ws?._id
})

const initialState = {
    myFileJobs: {
        value: [],
        loading: false,
        error: null
    },
    focusFileJob: {
        value: {},
        loading: false,
        error: null
    },
    myFileJobMap: {
        value: {},
        loading: false,
        error: null
    }
}

export const FileJobSlice = createSlice({
    name: 'filejob',
    initialState,
    reducers: {
        selectFocusFileJob: (state, action) => {
            const filejobId = action.payload
            state.focusFileJob.value = state.myFileJobs.value.find(filejob => filejob.id === filejobId)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFileJobs.pending, (state) => {
                state.myFileJobs.loading = true
                state.myFileJobs.error = null
            })
            .addCase(getFileJobs.fulfilled, (state, action) => {
                state.myFileJobs.loading = false
                state.myFileJobs.value = action.payload
                var m = {}
                action.payload.forEach(ws => {
                    m[ws._id] = ws
                })
                state.myFileJobMap.value = m
            })
            .addCase(getFileJobs.rejected, (state, action) => {
                state.myFileJobs.loading = false
                state.myFileJobs.error = action.error.message
            })


        builder
            .addCase(createFileJobs.pending, (state) => {
                state.myFileJobs.loading = true
                state.myFileJobs.error = null
            })
            .addCase(createFileJobs.fulfilled, (state, action) => {
                state.myFileJobs.loading = false
                state.myFileJobs.value = [...state.myFileJobs.value, ...action.payload]
                var m = {}
                action.payload.forEach(ws => {
                    m[ws._id] = ws
                })
                state.myFileJobMap.value = m
            })
            .addCase(createFileJobs.rejected, (state, action) => {
                state.myFileJobs.loading = false
                state.myFileJobs.error = action.error.message
            })

        builder
            .addCase(deleteFileJob.fulfilled, (state, action) => {
                state.myFileJobs.loading = false
                state.myFileJobs.value = state.myFileJobs.value.filter(filejob => {
                    return filejob._id != action.payload
                })

                delete state.myFileJobMap.value[action.payload]
            })
            .addCase(deleteFileJob.rejected, (state, action) => {
                state.myFileJobs.loading = false
                state.myFileJobs.error = action.error.message
            })
    },
})

// Action creators are generated for each case reducer function
export const { selectFocusFileJob } = FileJobSlice.actions
export const selectFileJobs = (state) => state.filejob.myFileJobs.value
export const selectLoading = (state) => state.filejob.myFileJobs.loading
export const selectError = (state) => state.filejob.myFileJobs.error
export default FileJobSlice.reducer