import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb from '../../../components/bootstrap/Breadcrumb';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import { TrackerBoard } from './TrackerBoard';
import TrackerService from '../../../services/TrackerService';
import { useParams } from 'react-router-dom';

export const TrackerPage = ({ }) => {

    const { trackerId } = useParams();
    const [tracker, setTracker] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(async () => {
        setIsLoading(true)
        const tracker = await TrackerService.getTracker(trackerId)
      
        setTracker(tracker)
        setIsLoading(false)
    }, [trackerId])

    return (
        <PageWrapper>
            <SubHeader>
                <SubHeaderLeft>
                    <Breadcrumb
                        list={[
                            {
                                title: 'My Campaigns',
                                to: '/campaigns',
                            },
                            {
                                title: tracker?.title,
                                to: `/campaigns/${trackerId}`,
                            },
                        ]}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button color="success" icon="Check">
                        Complete Campaign
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid' style={{ zIndex: "1020", flex: "1 1 auto" }}>
                <div className='row h-100'>
                    <div className='col-12'>
                        <TrackerBoard trackerId={trackerId} />
                    </div>
                </div>
            </Page>
        </PageWrapper>

    )
}
