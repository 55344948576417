import memoize from "memoizee";
import sha256 from 'crypto-js/sha256';

export const ObjectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))


// Define the hashStrings function
export function hashStrings(str1, str2) {
    // Concatenate the two strings
    const combinedString = str1 + str2;

    // Create a hash using SHA-256
    const hash = sha256(combinedString).toString();

    // Take the first 12 bytes (24 characters) of the hash
    const truncatedHash = hash.substring(0, 24);

    return truncatedHash;
}

// Memoize the hashStrings function
const memoizedHashStrings = memoize(hashStrings, { length: false });

export const getOrDefaultActionItem = (tracker, trackerRow, workstream, trackerTaskMap) => {

    const workstreamTitle = workstream?.title ?? ""
    const trackerRowTitle = trackerRow?._listItem?.data?.["Fund Name"] ?? ""

    var trId = trackerRow?._id
    var wsId = workstream?._id

    var aiId = trId && wsId ? memoizedHashStrings(trId, wsId) : ObjectId();

    let ai = trackerTaskMap?.[aiId]

    if (!ai || ai?.deleted) {
        ai = {
            deleted: false,
            _id: aiId,
            tracker: tracker?._id,
            trackerRow: trId,
            workstream: wsId,
            title: `${workstreamTitle} (${trackerRowTitle})`,
            type: workstream.type,
            owners: [],
            project: tracker?.project,
            files: [],
            status: "Not Started"
        }
    }


    return ai
}
