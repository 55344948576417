import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getOptionStringValue } from '../../FreslerTableUtilities';

export const DefaultSelectFilterInputRenderer = ({ col, filter, setFilter, data = null }) => {

    const [searchText, setSearchText] = useState("")
    const [uniqueOptions, setUniqueOptions] = useState([])

    function isNullOrEmpty(value) {
        return value === null || value === undefined || value === 0;
    }

    // Collect all of the options from the data
    const getUniqueOptionList = () => {
        const dataValues = new Set()
        const optionValues = new Set()

        data?.forEach((row) => {
            dataValues.add(row[col.field])
        })
        col?.options?.forEach((o) => {
            optionValues.add(o.value)
        })

        const uniqueOps = col?.options?.filter(o => {
            return dataValues.has(o.value)
        })

        const unallowedSet = new Set()

        data?.filter((row) => {
            return !optionValues.has(row[col.field])
        }).forEach((row) => {
            unallowedSet.add(row[col.field])
        })

        const unAllowedValues = [...unallowedSet].map((v) => {
            return { label: v, value: v }
        })

        return [...uniqueOps, ...unAllowedValues]
    }

    useEffect(() => {
    }, [data])

    const handleItemCheck = (e, option) => {
        const newFilter = { ...filter }

        if (!newFilter.selected) {
            newFilter.selected = new Set()
        }

        if (e.target.checked) {
            newFilter.selected.add(option.value)
        }
        else {
            newFilter.selected.delete(option.value)
        }

        const isFiltersActive = newFilter.selected.size > 0

        setFilter({ ...newFilter, active: isFiltersActive })
    }

    const uniqueOptionsList = getUniqueOptionList()

    const isSearchMatch = (option, searchText) => {
        const strValue = getOptionStringValue(option)
        return strValue?.toLowerCase()?.includes(searchText?.toLowerCase())
    }

    return (
        <div>
            <div className='row px-3'>
                <Form.Control
                    placeholder='Search'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <div className='fresler-table-select-filter-option-list row px-3'>
                {
                    uniqueOptionsList?.filter(o => {
                        return isNullOrEmpty(searchText) || isSearchMatch(o, searchText)
                    })?.
                        map((option) => {
                            return <div className='col-12 px-1'>
                                < div className='row' >
                                    <div className='col-2'>
                                        <Form.Check
                                            className='fresler-table-row-select-checkbox'
                                            color='success'
                                            onChange={(e) => handleItemCheck(e, option)}
                                            checked={filter?.selected?.has(option?.value) ?? false}
                                        />
                                    </div>
                                    <div className='col-10'>
                                        {option?.label}
                                    </div>
                                </div>
                            </div>
                        })
                }
            </div>
        </div>
    )
}