import React, { useState, useEffect } from 'react'
import { FileUploader } from "react-drag-drop-files";
import './FileDropPad.css'

export const FileDropPad = ({ startFileAdd, maxSize, label }) => {

    return (
        <FileUploader
            label={label ?? "Upload or drop a file right here"}
            maxSize={maxSize ?? 20}
            onSelect={(file) => { startFileAdd(file); }}
            onDrop={(file) => { startFileAdd(file); }}
            name="file"
            multiple={true} />
    );
}
