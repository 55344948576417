import React, { useState } from 'react'
import { Icon } from './subs/Icon'
import { Dropdown } from './FreslerTableDropdown'
import { Button } from './subs/Button';
import { FreslerTableColumnEditModal } from './FreslerTableColumnEditModal';


export const FreslerTableMoreOptions = ({
    cols, optionalCols, setCols,
    allData, toggleMapMode, allDataFields
}) => {

    const [isFreslerTableColumnEditModalOpen, setIsFreslerTableColumnEditModalOpen] = useState(false)

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle
                    hasIcon={false}
                    variant='toggle'
                >
                    <Icon icon="ThreeDotsVertical"
                        className='cursor-pointer'
                        style={{
                            fontSize: "large"
                        }}
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item  onClick={() => setIsFreslerTableColumnEditModalOpen(true)}>
                        <Button icon='LayoutThreeColumns'>
                            {" "}Edit Columns
                        </Button>
                    </Dropdown.Item>
                    <Dropdown.Item  onClick={() => toggleMapMode()}>
                        <Button icon='Bezier2'>
                            {" "}Map Data Columns
                        </Button>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <FreslerTableColumnEditModal
                allFields={allDataFields}
                allData={allData}
                cols={cols}
                optionalCols={optionalCols}
                setCols={setCols}

                isFreslerTableColumnEditModalOpen={isFreslerTableColumnEditModalOpen}
                setIsFreslerTableColumnEditModalOpen={setIsFreslerTableColumnEditModalOpen}
            />
        </>
    )
}
