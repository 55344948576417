import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { getOrDefaultActionItem } from '../../pages/trackers/TrackerPage/TrackerBoardUtils';
import { updateTrackerTasks } from '../../redux/slices/TrackerSlice';
import { isValidEmail } from '../../services/Common';
import EmailService from '../../services/EmailService';
import FileJobService from '../../services/FileJobService';
import FileService from '../../services/FileService';
import a4Logger from '../../services/a4Logger';
import { isNullOrEmptyString } from '../FreslerTable/src/src/components/FreslerTable/FreslerTableUtilities';
import { WizardGenerator } from '../WizardGenerator/WizardGenerator';
import Modal, {
    ModalBody
} from '../bootstrap/Modal';
import { TrackerBoardEmailBlastEmailEdit } from './TrackerBoardEmailBlastEmailEdit';
import { TrackerBoardEmailBlastEmailReview } from './TrackerBoardEmailBlastEmailReview';
import { TrackerBoardEmailBlastRecipientEdit } from './TrackerBoardEmailBlastRecipientEdit';

export const TrackerBoardEmailBlastModal = ({
    isTrackerBoardEmailBlastModalOpen,
    setIsTrackerBoardEmailBlastModalOpen,
    callback, data, selectedItems,
    tracker,
    workstream
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);

    const usableData = data?.map(d => ({ ...d?._listItem?.data, _id: d?._id, trackerRowId: d?._id, listItemId: d?._listItem?._id }))
    const [initRecipients, setInitRecipients] = useState([])
    const me = useSelector((state) => state?.user?.me)
    const curTrackerTasks = useSelector(state => state?.tracker?.curTrackerTasks)
    const [wizConfig, setWizConfig] = useState({})
    const workstreams = tracker?._workstreams ?? []
    const dispatch = useDispatch()


    useEffect(() => {
        setInitRecipients((selectedItems.size > 0 ? usableData?.filter(x => selectedItems.has(x?._id)) : usableData) ?? [])
    }, [])

    const validators = [
        (formik) => {
            var messages = []
            const rec = formik?.values?.recipients

            if (!(rec?.length > 0)) {
                messages.push("At least 1 recipient is required")
            }

            for (var i = 0; i < rec?.length; i++) {
                const r = rec[i]

                if (!r?.["Contact Email"]) {
                    messages.push(`Recipient ${i + 1} is missing an email`)
                }

                if (!isValidEmail(r?.["Contact Email"])) {
                    messages.push(`Recipient ${i + 1} has an invalid email`)
                }
            }

            return messages
        }
    ]

    useEffect(() => {

        const wc = {
            onSubmit: async formData => {

                try {
                    setIsLoading(true)

                    const recipients = formData?.recipients
                    const email = { ...formData?.email, body: formData?.emailBody, attachments: formData?.email.attachments ?? [] }
                    const attachments = email?.attachments
                    const attchCount = attachments?.length
                    const myOrg = { display_name: "Fresler LLC" }

                    setLoadingMessage("Uploading source attachment files")
                    a4Logger.logUserEvent("Beginning watermarking jobs", { sourceFiles: attachments.length })
                    // Upload source documents
                    const hydratedSourceDocuments = await FileService.uploadFiles(attachments.map(a => a?.file))

                    const createWaterMarkedFiles = async (waterMarkOptions) => {

                        const fileJobsToCreate =
                            waterMarkOptions.map((opt, i) => {
                                if (!opt) {
                                    return null
                                }

                                const { src, loc, text } = opt

                                const variation = {
                                    watermark: {
                                        location: loc,
                                        source: "static",
                                        text: text
                                    },
                                    fileSet: {
                                        id: text,
                                        title: text
                                    }
                                }

                                const fileJob = {
                                    job: {
                                        type: "watermark",
                                        sourceFiles: [src?._id],
                                    },
                                    variations: [variation]
                                }

                                return fileJob
                            })

                        const response = await FileJobService.createFileJobs(fileJobsToCreate.filter(j => j != null))

                        const createdJobs = fileJobsToCreate?.map((fj, i) => {
                            if (!fj) {
                                return null
                            }

                            const r = response.shift()
                            return (r.files)[0][0]
                        })

                        return createdJobs
                    }



                    setLoadingMessage("Generating documents watermarked for your org")
                    a4Logger.logUserEvent("Generating static myOrg documents")
                    // Generate documents which have static watermarks only once
                    // TODO - Bulk upload these attachements instead of 1 by 1
                    const staticItems = attachments.map((att, i) => {
                        const sourceDocument = hydratedSourceDocuments[i]

                        if (att?.watermarkSrc != 'myOrg' || !(att.watermarkSrc && att?.watermarloc)) { // If not static, should never be referenced
                            return null
                        }

                        const watermarkText = `Fresler LLC` // TODO: Get from user org
                        const item = { src: sourceDocument, loc: att?.watermarloc, text: watermarkText }
                        return item
                    })

                    // TODO: Check if the response is as expected
                    const staticWatermarkedAttachments = await createWaterMarkedFiles(staticItems)

                    // Start from here
                    var docWaterMarkItems = []


                    // Add all of the documents to be watermarked
                    setLoadingMessage("Generating documents watermarked for recipient orgs")
                    recipients.forEach((r, i) => {
                        const company = EmailService.getCompany(r)
                        attachments?.forEach((att, j) => {
                            const sourceDocument = hydratedSourceDocuments[j]
                            var item = null

                            if (att?.watermarloc && att?.watermarkSrc == 'theirOrg') {
                                item = { src: sourceDocument, loc: att?.watermarloc, text: company }
                            }
                            else {
                                item = null
                            }

                            docWaterMarkItems.push(item)
                        })
                    })

                    const waterMarkedFiles = await createWaterMarkedFiles(docWaterMarkItems)

                    setLoadingMessage("Updating campaign tasks")
                    var tasksToSave = recipients.map((r, i) => {

                        console.log("Getting campaign info", tracker, r?._id, workstream?._id)
                        const task = getOrDefaultActionItem(tracker, r, workstream, curTrackerTasks)

                        const emailAttachments = attachments.map((att, j) => {
                            const attachmentIndex = (i * attchCount) + j

                            // Use the doc customized for their org, the one customized for my org, or the original
                            const watermarkedAttachment = waterMarkedFiles[attachmentIndex] ?? staticWatermarkedAttachments[j] ?? sourceDocuments[j]
                            return watermarkedAttachment?._id
                        })

                        task.files = [...task.files, ...emailAttachments]
                        task.status = "Complete"
                        task._numEmails = (task._numEmails ?? 0) + 1
                        return task
                    })

                    dispatch(updateTrackerTasks({ tasks: tasksToSave }))

                    setLoadingMessage("Generating emails", attachments)
                    const emails = recipients.map((r, i) => {
                        const emailAttachments = attachments.map((att, j) => {
                            const attachmentIndex = (i * attchCount) + j

                            // Use the doc customized for their org, the one customized for my org, or the original
                            const watermarkedAttachment = waterMarkedFiles[attachmentIndex] ?? staticWatermarkedAttachments[j] ?? sourceDocuments[j]
                            return watermarkedAttachment?._id
                        })

                        const task = tasksToSave[i]

                        var filledEmail = EmailService.fillTemplate(email, r, me, myOrg)
                        filledEmail = {
                            ...filledEmail,
                            parent: task?._id,
                            to: [
                                {
                                    Name: r?.["Contact Name"],
                                    Email: r?.["Contact Email"]
                                }
                            ],
                            cc: isNullOrEmptyString(email.cc) ? [] : email.cc.split(';').map(e => ({ Email: e })),
                            attachments: emailAttachments
                        }

                        return filledEmail
                    })

                    const hydratedEmails = await EmailService.saveEmails(emails)

                    a4Logger.notifyUser("Emails Staged For Delivery", `${hydratedEmails.length} emails have been staged for delivery.`)
                    a4Logger.logUserEvent(`Created ${hydratedEmails.length} emails`, { emails: hydratedEmails, attachments: attchCount })
                    setIsTrackerBoardEmailBlastModalOpen(false)

                }
                catch (e) {
                    a4Logger.logError(e)
                    a4Logger.notifyUser("Error Sending Email Blast", "There was an error sending the email blast. Please try again.")
                }
                finally {

                    setIsLoading(false)
                }
            },
            title: `${workstream?.title}`,
            steps: [
                {
                    cards: [
                        {
                            title: 'Validate Recipient Information',
                            icon: 'Groups',
                            iconColor: 'warning',
                            fields: [
                                {
                                    id: "recipients",
                                    title: "Groups",
                                    init: initRecipients,
                                    render: (formik) =>
                                        <>
                                            <TrackerBoardEmailBlastRecipientEdit
                                                recipients={formik?.values?.recipients}
                                                updateRecipients={(rec) => {
                                                    formik?.setFieldValue("recipients", rec)
                                                }} />
                                        </>
                                    ,
                                }
                            ]
                        }
                    ],
                    validator: validators[0]
                },
                {
                    cards: [
                        {
                            title: 'Compose Email To Recipients',
                            icon: 'Email',
                            iconColor: 'info',
                            fields: [
                                {
                                    id: "email",
                                    title: "Edit Email",
                                    init: {},
                                    render: (formik) =>
                                        <>
                                            <TrackerBoardEmailBlastEmailEdit
                                                email={formik?.values?.email}
                                                emailBody={formik?.values?.emailBody || ""}
                                                updateEmailBody={(body) => {
                                                    formik?.setFieldValue("emailBody", body)
                                                }}
                                                updateEmail={(em) => {
                                                    formik?.setFieldValue("email", em)
                                                }} />
                                        </>
                                    ,
                                }
                            ]
                        }
                    ]
                },
                {
                    cards: [
                        {
                            title: 'Review Emails',
                            icon: 'ManageSearch',
                            iconColor: 'danger',
                            fields: [
                                {
                                    id: "email",
                                    title: "Edit Email",
                                    init: {},
                                    render: (formik) =>
                                        <>
                                            <TrackerBoardEmailBlastEmailReview
                                                emailBody={formik?.values?.emailBody || ""}
                                                email={formik?.values?.email}
                                                recipients={formik?.values?.recipients} />
                                        </>
                                    ,
                                }
                            ]
                        }
                    ]
                }
            ]
        }

        setWizConfig(wc)
    }, [initRecipients, tracker?._id, curTrackerTasks])



    return (
        <Modal
            setIsOpen={setIsTrackerBoardEmailBlastModalOpen}
            isOpen={isTrackerBoardEmailBlastModalOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='xl'>
            <ModalBody>
                {
                    isLoading ?
                        <PageWrapper className='justify-content-center text-center' title='Tasks'>
                            <div className='justify-content-center text-center' title='Tasks'>
                                <Dots text={loadingMessage}></Dots>
                            </div>
                        </PageWrapper>
                        : <>
                            <WizardGenerator wizardObject={wizConfig} />
                        </>
                }
            </ModalBody>
        </Modal>
    )
}