import { useEffect, useState } from 'react';
import { TrackerRowsImport } from '../../../components/trackers/TrackerRowsImport/TrackerRowsImport';
import Modal, {
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter
} from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import { getObjectId } from '../../../services/Common';
import ListService from '../../../services/ListService';
import TrackerService from '../../../services/TrackerService';

export const TrackerBoardContactEditPanel = ({ isTrackerBoardContactEditPanelOpen, setIsTrackerBoardContactEditPanelOpen, trackerRows, onSave, tracker }) => {

    const title = 'Edit Contacts'
    const [isLoading, setIsLoading] = useState(false)
    const [_listItems, _setListItems] = useState([])
    const [isDirty, setIsDirty] = useState(false)
    const [listId, setListId] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        setIsDirty(false)
        const lid = trackerRows[0]?._listItem?.list ?? getObjectId()
        setListId(lid)
        const listItems = trackerRows.map((r, i) => ({ ...(r._listItem.data ?? {}), _id: r._listItem._id }))
        _setListItems(listItems)
    }, [isTrackerBoardContactEditPanelOpen, trackerRows])

    const saveItems = async () => {
        var trackerRowsToSave = []

        // Save
        const liToSave = _listItems?.map((l, i) => {
            if (l._id) {
                const ll = trackerRows.find(r => r._listItem._id === l._id)
                const { _id, ...data } = l
                return { ...ll._listItem, data: data }
            }
            else {
                const trackerRow = {
                    trackerIndex: trackerRows.length + i,
                    tracker: tracker._id,
                    _listItem: {
                        data: l,
                        type: 'contact',
                        title: "",
                        list: listId
                    },
                }

                trackerRowsToSave.push(trackerRow)

                return null
            }
        })

        const nonNullLiToSave = liToSave.filter(l => l !== null)

        await Promise.all([
            ListService.upsertListItems(nonNullLiToSave),
            TrackerService.upsertTrackerRows(trackerRowsToSave)
        ])
    }

    return (
        <Modal
            setIsOpen={setIsTrackerBoardContactEditPanelOpen}
            isOpen={isTrackerBoardContactEditPanelOpen}
            isCentered
            isScrollable
            size='xl'
            fullScreen='xxl'
        >
            <ModalHeader setIsOpen={setIsTrackerBoardContactEditPanelOpen}>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                {
                    isLoading ?
                        <LoadingFullPage />
                        :
                        <TrackerRowsImport
                            isSimpleViewMode={true}
                            contactsList={_listItems}
                            updateContactsList={(l) => {
                                setIsDirty(true)
                                _setListItems(l)
                            }} />
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    size='lg'
                    color='success'
                    onClick={async () => {
                        if (isDirty) {
                            // Save
                            // TODO: Try catch w/ loading screen
                            await saveItems()
                            onSave()
                        }
                        setIsTrackerBoardContactEditPanelOpen(false)
                    }}
                    variant='secondary'
                >
                    {
                        isDirty ? 'Save' : 'Close'
                    }
                </Button>
            </ModalFooter>
        </Modal>
    )
}