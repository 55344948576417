import React, { useEffect, useState } from 'react'
import {
    CardTitle
} from '../../../components/bootstrap/Card';
import "./TrackerBoard.css";
import Icon from '../../../components/icon/Icon';
import Input from '../../../components/bootstrap/forms/Input';

export const TrackerBoardTrackerTitle = ({ title, updateTitle }) => {

    const [_title, _setTitle] = useState("")

    useEffect(() => {
        _setTitle(title)
    }, [title])

    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false)

    return (
        <CardTitle>
            {
                isEditModeEnabled ?
                    <Input
                        value={_title}
                        onChange={(e) => _setTitle(e.target.value)}
                        onBlur={() => {
                            updateTitle(_title)
                            setIsEditModeEnabled(false)
                        }}
                        autoFocus
                    />
                    :
                    <div
                        className='tracker-board-tracker-title'
                        onDoubleClick={() => setIsEditModeEnabled(true)}
                    >
                        {title}
                    </div>

            }
        </CardTitle>
    )
}
