import createAuth0Client from '@auth0/auth0-spa-js';
import a4Logger from './a4Logger';

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

class Auth0ClientService {
    static auth0ClientProm = null;

    static async resetClient() {
        a4Logger.logTrace("Resetting the auth0 client",
            { auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN, auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID },
            null,
            "Authentication"
        )

        const client = await createAuth0Client({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
            audience: `https://${process.env.REACT_APP_AUTH0_CORE_DOMAIN}/api`,
            authorizationParams: {
                redirect_uri: `${window.location.origin}`,
            }
        })


        // Handle the callback if returning from Auth0
        if (window.location.search.includes('code=')) {
            await client.handleRedirectCallback();
            window.history.replaceState({}, document.title, window.location.pathname); // Clean the URL
        }

        Auth0ClientService.auth0ClientProm = (async () => client)()
    }

    static  {
        a4Logger.logTrace("Initializing auth0 client for Auth0ClientService",
            { auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN, auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID },
            null,
            "Authentication"
        )

        Auth0ClientService.resetClient()
    }

    static async getUser(userId = null) {
        var user = null
        var retries = 7
        
        while (!Auth0ClientService.auth0ClientProm) {
            await delay(10);
        }

        var auth0client = await (Auth0ClientService.auth0ClientProm)

        while (!user && retries-- > 0) {

            a4Logger.logTrace("Auth0ClientService.getUser()",
                { userId: userId, retriesRemaining: retries, auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN, auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID },
                null,
                "Authentication"
            )

            user = await auth0client.getUser()
            if (user) {
                user = {
                    ...user,
                    user_id: user?.sub
                }
            }
            if (!user) {
                await delay(70);
            }
        }

        a4Logger.logTrace("Auth0ClientService.getUser() returning user",
            { user: user, retriesRemaining: retries, auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN, auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID },
            null,
            "Authentication"
        )
        return user
    }

    static async login(redirect_uri = '') {
        var auth0client = await (Auth0ClientService.auth0ClientProm)
        const fullUrl = `${window.location.origin}${redirect_uri}`
        await auth0client.loginWithRedirect({
            redirect_uri: fullUrl
        });
    }

    static async logout() {
        var auth0client = await (Auth0ClientService.auth0ClientProm)
        await auth0client.logout({
            returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
          });
    }

    static async getAuthToken(audience = "api", scopes = null) {

        a4Logger.logTrace("Auth0ClientService.getAuthToken()",
            { audience: audience, scopes: scopes, auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN, auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID },
            null,
            "Authentication"
        )

        var accessToken = null
        var auth0client = await (createAuth0Client({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
            redirect_uri: `${window.location.origin}`,
            audience: `https://${process.env.REACT_APP_AUTH0_CORE_DOMAIN}/${audience}`,
        }))

        try {
            accessToken = await auth0client.getTokenSilently({
                audience: `https://${process.env.REACT_APP_AUTH0_CORE_DOMAIN}/${audience}`,
                redirect_uri: `${window.location.origin}`,
            });
        } catch (error) {
            if (error.error !== 'login_required') {
                accessToken = await auth0client.getTokenWithPopup({
                    audience: `https://${process.env.REACT_APP_AUTH0_CORE_DOMAIN}/${audience}`,
                    redirect_uri: `${window.location.origin}`,
                });
            }
        }

        a4Logger.logTrace("Auth0ClientService.getAuthToken() returning access token",
            { accessTokenIsNull: accessToken == null },
            null,
            "Authentication"
        )
        return accessToken
    }
}

export default Auth0ClientService;