// import FormGroup from './bootstrap/forms/FormGroup';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle
} from '../../../components/bootstrap/Card';
// import PhaseService from '../../../services/PhaseService';
import Modal, {
    ModalBody, ModalHeader
} from '../../../components/bootstrap/Modal';
import { TrackerColumnsEdit } from '../../../components/trackers/TrackerColumnsEdit/TrackerColumnsEdit';

export const TrackerColumnModal = ({ isColumnModalOpen, setIsColumnModalOpen, ...props }) => {

    return (
        <>
            <Modal
                setIsOpen={setIsColumnModalOpen}
                isOpen={isColumnModalOpen}
                titleId='projectPhases'
                isCentered
                isScrollable
                size='lg'>
                <ModalHeader setIsColumnModalOpen={setIsColumnModalOpen}>
                    Edit Tasks (Columns)
                </ModalHeader>
                <ModalBody>

                    <Card >
                        <CardHeader>
                            <CardLabel>
                                <CardTitle>Edit Tasks (Columns)</CardTitle>
                                <br />
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <TrackerColumnsEdit {...props} />
                        </CardBody >
                    </Card>
                </ModalBody>
            </Modal>
        </>
    )
}
