import { isNullOrEmpty } from "../components/FreslerTable/src/src/components/FreslerTable/FreslerTableUtilities";
import { putJson } from "./ServiceHelperMethods"

class EmailService {

    static getCustomerAndCompany(item) {
        const name = EmailService.getName(item)
        const company = EmailService.getCompany(item)
        const nameCompany = name && company ? name + " (" + company + ")" : (name ? name : company)

        return nameCompany
    }

    static getCompany(item) {
        return item["Firm Name"] ?? item["Fund Name"] ?? item["Company"] ?? (item["user_metadata"] && item["user_metadata"]["company_name"] ? item["user_metadata"]["company_name"] : null) ?? ""
    }

    static getName(item) {
        const firstName = item["given_name"] ?? item["Contact Name"] ?? ""
        const lastName = item["family_name"] ?? ""
        const name = item["Contact Name"] ?? item["given_name"] ?? (firstName && lastName ? firstName + " " + lastName : (firstName ? firstName : ""))
        return name
    }

    static replaceTokens = (text, item, user, org) => {
        if (text && item) {
            text = text?.replaceAll("{{me.first_name}}", user?.first_name ?? user?.name?.split(' ').find(x => x) ?? "")
            text = text?.replaceAll("{{me.company}}", org?.display_name ?? "")
            text = text?.replaceAll("{{me.name}}", user?.name ?? (user?.first_name ? user?.first_name + " " + user?.last_name : null) ?? "")
            text = text?.replaceAll("{{me.email}}", user?.email ?? "")


            text = text?.replaceAll("{{buyer.contact}}", item["Contact Name"] ?? item["given_name"] ?? "")
            text = text?.replaceAll("{{buyer.first_name}}", item["given_name"] ?? item["Contact Name"] ?? "")
            text = text?.replaceAll("{{buyer.last_name}}", item["family_name"] ?? "")

            const company = EmailService.getCompany(item)
            text = text?.replaceAll("{{buyer.company}}", company)
        }
        return text ?? ""
    }

    static fillTemplate = (emailTemplate, item, user, org) => {

        const email = {
            ...emailTemplate,
            body: EmailService.replaceTokens(emailTemplate?.body, item, user, org),
            subject: EmailService.replaceTokens(emailTemplate?.subject, item, user, org)
        }

        return email

    }

    static async saveEmails(emails) {

        const hydratedEmails = await putJson(`emails`, emails);
        return hydratedEmails
    }
}

export default EmailService;