import { deleteJson, getJson, postJson, putJson } from "./ServiceHelperMethods";

class TaskService {

    static taskSaveQueue = [];
    static workstreamSaveQueue = [];

    static {
        const interval = setInterval(function () {
            // method to be executed;
            TaskService.saveTaskQueue()
            TaskService.saveWorkstreamQueue()
        }, 2000);
    }

    // TODO: This should probably be async
    static saveTaskQueue() {

        if (TaskService.taskSaveQueue.length > 0) {
            var saveQ = TaskService.taskSaveQueue.map(({ history, ...keepAttrs }) => keepAttrs)
            putJson(`tasks`, saveQ);
            TaskService.taskSaveQueue = []
        }
    }

    static saveWorkstreamQueue() {

        if (TaskService.workstreamSaveQueue.length > 0) {
            putJson(`workstreams`, TaskService.workstreamSaveQueue);
            TaskService.workstreamSaveQueue = []
        }
    }

    static async getTasks() {
        return await getJson(`tasks`);
    }

    static async getTrackerTasks(trackerId) {
        return await getJson(`trackers/${trackerId}/tasks`);
    }

    static async getTasksById(taskIds) {
        return await getJson(`tasks/${taskIds.join(',')}`);
    }

    static async deleteTasks(ids) {
        return await deleteJson(`tasks/${ids.join(',')}`);
    }

    static async upsertTask(task) {
        TaskService.upsertTasks([task])
    }

    static async upsertTasks(tasks) {
        TaskService.taskSaveQueue.push(...tasks)
    }
    static async upsertWorkstream(workstream) {
        TaskService.upsertWorkstreams([workstream])
    }

    static async upsertWorkstreams(workstreams) {
        TaskService.workstreamSaveQueue.push(...workstreams)
    }

    static async getSubTasks(taskId) {
        return await getJson(`tasks/${taskId}/subtasks`);
    }

    static async getTaskActivity(taskId) {
        return await getJson(`events/task/${taskId}`);
    }

    static async addComment(comment) {
        return await putJson(`events`, [comment]);
    }
}

export default TaskService;