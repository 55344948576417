import memoize from "memoizee";
import { get, getJson, postRaw, putJson } from "./ServiceHelperMethods";
import a4Logger from "./a4Logger";

class FileService {

    static getFileBlobMem = memoize(FileService._getFileBlob, { maxAge: 1000 * 60 * 60 * 24 })

    static toString(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    static async getFileInfo(fileIds) {
        const fileIdString = fileIds.join(',');
        return await getJson(`files/${fileIdString}`);
    }

    static async getFileJobs() {
        return await getJson(`filejobs`);
    }

    static async _getFileBlob(fileId) {
        a4Logger.logTrace(`Getting file blob`, { fileId: fileId })
        const resp = await get(`files/${fileId}/fileContent`);
        return await resp.blob()
    }

    static async getFileBlob(fileId) {
        return await FileService.getFileBlobMem(fileId)
    }

    static async downloadFile(file) {

        a4Logger.logTrace(`Downloading file`, { fileId: file?._id, fileName: file?.title })

        const fileId = file._id
        const blob = await FileService.getFileBlob(fileId)

        // Create a temporary URL for the Blob object
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element to simulate the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.name // Specify the desired filename

        // Simulate click on the link to trigger the download
        link.click();

        // Clean up the temporary URL
        URL.revokeObjectURL(blobUrl);
    }

    static async upsertWatermarks(watermarks) {
        return await putJson(`watermarks`, watermarks);
    }

    static async uploadFiles(filesToUpload) {

        const filesCreated = await Promise.all(
            filesToUpload.map(async (file, ind) => {

                const formData = new FormData();

                formData.append("file", file);
                formData.append("filename", file.name);

                return await postRaw(`files`, formData)
            })
        )

        return filesCreated
    };
}



export default FileService;