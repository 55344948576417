import { Button } from './subs/Button';
import { Icon } from './subs/Icon';
import { Dropdown } from './FreslerTableDropdown';
import { getDefaultFilterRender } from './FreslerTableUtilities';
import React from 'react';

function FreslerTableHeaderDropdown({ 
    col, updateCol, groupBy, setGroupBy, data,
    key
}) {

    const setFilterValue = (filter) => {
        updateCol({ ...col, filter: filter, type: col?.type })
    }

    const rendererProvider = getDefaultFilterRender(col)
    const filterInputRenderer = rendererProvider({ col, filter: col?.filter ?? {}, setFilter: setFilterValue, data: data })

    const variant = col?.filter?.active ? 'danger' : "link"

    return (
        <Dropdown
            key={key}
            autoClose="outside"
            className='fresler-table-column-header-filter-dropdown'
            isOpen={true} >
            <Dropdown.Toggle
                variant={variant}
                size='sm'
                color="green"
                hasIcon={false}>
                <Icon
                    color={col?.filter?.active ? 'danger' : null}
                    icon='Funnel'
                    className='fresler-dropdown.-toggle'
                    hoverShadow='default'
                />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    (col?.enableGrouping ?? true) && <Dropdown.Item
                        onClick={() => {
                            setGroupBy(groupBy?.field == col?.field ? null : col)
                        }}
                        header>
                        {
                            groupBy?.field == col?.field ? "Ungroup" : "Group By"
                        }
                    </Dropdown.Item>
                }
                <Dropdown.Item
                    key={`${col?.field}-filter`}
                >
                    <>
                        {
                            filterInputRenderer
                        }
                        <div className='filterRow d-flex justify-content-end mt-1'>
                            {
                                col?.filter?.active && <Button color='danger' onClick={() => { setFilterValue({}) }} icon='X' className='clearFilterButton pull-right'></Button>
                            }
                        </div>
                    </>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default FreslerTableHeaderDropdown;