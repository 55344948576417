import React, { useState } from 'react'
import { getNewGuid } from './FreslerTableUtilities'

export const FreslerTableDataAddNewRowField = ({ col, addItem, renderDataItem, groupBy, groupVal }) => {

    const getNewItem = () => {
        const newItem = {
            [col.field]: '',
            id: getNewGuid()
        }
        newItem[col.field] = ''
        return newItem
    }

    const [newItem, setNewItem] = useState(getNewItem())

    const updateNewItem = (nn, cc, newVal) => {
      
        addItem({ ...nn, [cc.field]: newVal })
        setNewItem(getNewItem())
    }

    const rendered = renderDataItem(newItem, col, () => { }, updateNewItem, true)

    return (
        <div>
            {rendered}
        </div>
    )
}
