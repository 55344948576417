import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Modal, {
    ModalBody, ModalHeader
} from '../../bootstrap/Modal';
import {
    OffCanvasTitle
} from '../../bootstrap/OffCanvas';
import YoutubeEmbed from './YoutubeEmbed';

export const YouTubeEmbedModal = ({ isYouTubeEmbedModalOpen, setIsYouTubeEmbedModalOpen }) => {
    return (
        <>
            <Modal
                setIsOpen={setIsYouTubeEmbedModalOpen}
                isOpen={isYouTubeEmbedModalOpen}
                titleId='upcomingEdit'
                isScrollable={false}
                size='xl'
            // fullScreen='xxl'
            >
                <ModalHeader setIsOpen={setIsYouTubeEmbedModalOpen}>
                </ModalHeader>
                <ModalBody>
                    <YoutubeEmbed embedId="7NziynqCBw0" />
                </ModalBody>
            </Modal>
        </>
    )
}