
import React, { createRef, useEffect, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Select from '../../../components/bootstrap/forms/Select';
import Icon from '../../../components/icon/Icon';
import { LoadingFullPage } from '../../../components/loading/LoadingFullPage/LoadingFullPage';
import { BasicPDFViewer } from '../../../components/pdf/BasicPDFViewer/BasicPDFViewer';
import FileJobService from '../../../services/FileJobService';
import FileService from '../../../services/FileService';
import { getPercentage, getStatusIcon, reduceFileStatuses } from '../../../services/Common';
import Progress from '../../../components/bootstrap/Progress';
import a4Logger from '../../../services/a4Logger';

export const FileJobDetailsView = ({ fileJob }) => {

    const downloadFileLink = createRef()
    const downloadAllLink = createRef()

    const [isLoading, setIsLoading] = useState(false)
    const [isOneDownloading, setIsOneDownloading] = useState(false)
    const [isAllDownloading, setIsAllDownloading] = useState(false)

    const [currentFileSetIndex, setCurrentFileSetIndex] = useState(0)
    const [currentFileSet, setCurrentFileSet] = useState(null)
    const sourceFileCount = fileJob?.sourceFiles?.length
    const fileOptions = [...fileJob?.sourceFiles, ...fileJob?.files]
    const fileJobFiles = reduceFileStatuses(fileJob?.files ?? [])

    const generateFileSetOptions = (fileJob) => {

        var fs = [{ title: "Original Source Files", files: fileJob?.sourceFiles }, ...fileJob?.filesets]

        fs = fs?.map(fileSet => {
            const fsStatus = reduceFileStatuses(fileSet?.files)

            return {
                title: fileSet?.title,
                files: fileSet?.files,
                status: fsStatus,
                icon: getStatusIcon(fsStatus)
            }
        })

        return fs

    }

    const fileSetOptions = generateFileSetOptions(fileJob)

    const downloadFileSet = async (e) => {
        try {
            a4Logger.logUserEvent("Downloading File Set", { fileSet: currentFileSet?._id, fileJob: fileJob?._id })
            setIsOneDownloading(true)
            await Promise.all(currentFileSet?.files.map(async (file) => {
                await FileService.downloadFile(file)
            }))
            setIsOneDownloading(false)
        } catch (error) {
            setIsAllDownloading(false)
            a4Logger.logErrorMessage("Error downloading file set", 'The file set could not be downloaded', { fileSet: currentFileSet?._id, fileJob: fileJob?._id })
            a4Logger.logError(error)
        }
    }

    const downloadAllFiles = async (e) => {
        try {
            a4Logger.logUserEvent("Downloading File Job All File Sets", { fileJob: fileJob?._id, fileSets: fileSetOptions })
            setIsAllDownloading(true)
            e.stopPropagation();
            await FileJobService.downloadAllFiles(fileJob)
            setIsAllDownloading(false)
        } catch (error) {
            setIsAllDownloading(false)
            a4Logger.logError(error)
        }
    }

    // Load the current file set
    useEffect(async () => {

        setIsLoading(true)
        const fileSet = fileSetOptions[currentFileSetIndex]

        // Get all of the files in the file set
        const expandedFiles = await Promise.all(fileSet?.files?.map(async file => {

            const fileBlob = await FileService.getFileBlob(file?._id)

            const fileUrl = window.URL.createObjectURL(fileBlob)
            const newFile = { url: fileUrl, ...file }
            return newFile

        }))

        const newFileSet = { ...fileSet, files: expandedFiles }

        setCurrentFileSet(newFileSet)
        setIsLoading(false)

        const fileJobStatus = reduceFileStatuses(fileJob?.files ?? [])
        const percSuccess = getPercentage(fileJob?.fileStatuses?.ready, fileJob?.files?.length)
        a4Logger.logUserEvent("Expanding File Job in View", { fileJob: fileJob?._id, fileJobStatus, fileJobStatus, percSuccess: percSuccess.toFixed(2) })

    }, [currentFileSetIndex])

    const fileJobStatus = reduceFileStatuses(fileJob?.files ?? [])
    const percSuccess = getPercentage(fileJob?.fileStatuses?.ready, fileJob?.files?.length)

    return (
        <div>
            <div className='row'>
                <div className='col-12 col-md-9 order-last order-md-first'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                            {
                                (["Processing", "Queued"]).includes(fileJobStatus) && <Progress color="success" isAnimated value={percSuccess} />
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                            <div style={{ paddingTop: "5px" }}>
                                <div className='col-md-12'>
                                    <FormGroup id="fileSet" label="File" isFloating>
                                        <Select
                                            value={currentFileSetIndex}
                                            onChange={e => setCurrentFileSetIndex(e.target.value)}>
                                            {
                                                fileSetOptions?.map((fileSet, i) => {
                                                    return <option value={i} disabled={fileSet.status != "Ready"}>({fileSet?.status}) {fileSet?.title}</option>
                                                }) ?? []
                                            }
                                        </Select>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mb-4'>
                            {
                                isLoading ? <LoadingFullPage /> : <>
                                    {
                                        currentFileSet?.files?.map((file, i) => {
                                            return <BasicPDFViewer attachment={file} customText="" />
                                        })
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-3 order-first order-md-last'>
                    <div className='row'>
                        <div className='col-4 col-md-12 mb-3'>
                            <Button
                                className='btn btn-success'
                                icon='Download'
                                onClick={async (e) => {
                                    await downloadFileSet(e)
                                }}
                            >
                                {
                                    isOneDownloading ?
                                        <Spinner color='primary' size={10} /> :
                                        (currentFileSetIndex == 0 ? "Download Original File(s)" : "Download This File Set")

                                }
                                <a role='button display-none' ref={downloadFileLink}  >
                                </a>
                            </Button>
                        </div>
                        <div className='col-4 col-md-12 mb-3'>
                            <Button
                                className='btn btn-success'
                                icon='Download'
                                disabled={fileJobStatus != "Ready"}
                                onClick={async (e) => {
                                    await downloadAllFiles(e)
                                }}
                            >
                                {
                                    isAllDownloading ?
                                        <Spinner color='primary' size={10} /> :
                                        "Download All"
                                }
                                <a role='button display-none' ref={downloadAllLink}  >
                                </a>
                            </Button>
                        </div>
                        <div className='col-4 col-md-12 mb-3'>
                            <Button
                                className='btn btn-success disabled'
                                onClick={() => { }}
                            >
                                Download Zip
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
