
import { useEffect, useRef, useState } from 'react';
import Button from '../bootstrap/Button';
import Card, {
    CardBody, CardHeader, CardLabel, CardTitle
} from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Select from '../bootstrap/forms/Select';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { CardActions } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import { isNullOrEmpty } from '../../services/Common';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import EmailService from '../../services/EmailService';


export const TrackerBoardEmailBlastEmailPDFAttachment = ({ attachment, setAttachment, deleteAttachment, contact, isEditEnabled = true, customText = null }) => {
    
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [isAttachmentExpanded, setIsAttachmentExpanded] = useState(true)

    const waterMarkLocations = [
        {
            title: "Top",
            value: "start"
        },
        {
            title: "Center",
            value: "center"
        },
        {
            title: "Bottom",
            value: "end"
        }
    ]

    const getViewer = (attachment) => {

        const markText = !isNullOrEmpty(customText) ? customText :
            (attachment.watermarkSrc == 'myOrg') ? "My Org" :
                (contact ? EmailService.getCompany(contact) : "{{Their Org}} ")


        return <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
            <div style={{ height: '750px' }}>
                <Viewer
                    renderPage={(props) => (
                        <>
                            {props.canvasLayer.children}
                            {
                                attachment.watermarkSrc && attachment?.watermarloc && attachment?.watermarkSrc != 'none' &&
                                <div
                                    style={{
                                        alignItems: attachment?.watermarloc,
                                        display: 'flex',
                                        height: '100%',
                                        justifyContent: 'center',
                                        left: 0,
                                        position: 'absolute',
                                        top: 0,
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            color: 'rgba(0, 0, 0, 0.2)',
                                            fontSize: `${4 * props.scale}rem`,
                                            fontWeight: 'bold',
                                            textTransform: 'uppercase',
                                            transform: attachment?.watermarloc == 'center' ? 'rotate(-45deg)' : null,
                                            userSelect: 'none',
                                        }}
                                    >
                                        {markText}
                                    </div>
                                </div>
                            }
                            {props.annotationLayer.children}
                            {props.textLayer.children}
                        </>
                    )}
                    fileUrl={attachment?.url}
                    plugins={[
                        defaultLayoutPluginInstance
                        // Register plugins
                    ]}
                />
            </div>
        </Worker>
    }

    return (
        <Card>
            <CardHeader>
                <CardLabel>
                    <CardTitle>
                        <span>
                            <Icon className="cursor-pointer" onClick={() => setIsAttachmentExpanded(!isAttachmentExpanded)} icon={isAttachmentExpanded ? 'ChevronDown' : "ChevronRight"} />
                            {'  ' + attachment?.title}
                        </span>
                    </CardTitle>
                </CardLabel>
                {
                    isEditEnabled &&
                    <CardActions>
                        <Button icon="XLg"
                            onClick={() => deleteAttachment()}
                        />
                    </CardActions>
                }
            </CardHeader>
            <CardBody style={{ display: isAttachmentExpanded ? "block" : "none" }} >
                <div >
                    {
                        attachment &&
                        <div className='row g-4' >
                            <div className={isEditEnabled ? 'col-md-9' : 'col-md-12'}>
                                <div className=" justify-content-center text-center">
                                    <div className="MyComponent">
                                        {/* <div className="webviewer" ref={viewer} style={{ height: "100vh" }}></div> */
                                            getViewer(attachment)
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                isEditEnabled &&
                                <div className='col-md-3'>
                                    <div className='row g-4' >
                                        <div className='col-md-12'>
                                            <FormGroup
                                                id='watermarksrc'
                                                label='Watermark'
                                                isFloating>
                                                <Select
                                                    onChange={e => setAttachment({ ...attachment, watermarkSrc: e.target.value })}
                                                    value={attachment?.watermarkSrc}
                                                >
                                                    <option value="none">None</option>
                                                    <option value="myOrg">My Org WaterMark</option>
                                                    <option value="theirOrg">Reciepient Org Watermark</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-12'>
                                            <FormGroup
                                                id='watermarkloc'
                                                label='Watermark Location'
                                                isFloating>
                                                <Select
                                                    onChange={e => setAttachment({ ...attachment, watermarloc: e.target.value })}
                                                    value={attachment?.watermarloc}
                                                // invalidFeedback={formik?.errors.firstName}
                                                >
                                                    {
                                                        waterMarkLocations.map((type, i) => (
                                                            <option value={type.value}>{type.title}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </CardBody>
        </Card>
    )
}
