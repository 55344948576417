import React from 'react';
import Page from '../../layout/Page/Page';
// import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Alert from '../../components/bootstrap/Alert';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle
} from '../../components/bootstrap/Card';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { componentsMenu } from '../../menu';

export const Support = () => {
    return (
        <PageWrapper title={componentsMenu.charts.subMenu.chartsArea.text}>
            <Page container='fluid'>

                <Alert
                    isLight
                    className='border-0'
                    shadow='md'
                    icon='Public'
                    color='warning'>
                    Please contact us directly for all billing inquiries.
                </Alert>
                <div className='row h-8'>
                    <div className='col-12 col-sm-4'>
                        <Card>
                            <CardHeader>
                                <CardLabel icon='Mail' iconColor='success'>
                                    <CardTitle>Email</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                Email us at <a href='mailto:support@sigma9.app'>support@sigma9.app</a>
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-12 col-sm-4'>
                        <Card>
                            <CardHeader>
                                <CardLabel icon='Phone' iconColor='secondary'>
                                    <CardTitle>Call</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <a href='mailto:david@sigma9.app?subject=Call%20Request&body=Hello%2C%0A%0AI%20would%20like%20to%20request%20a%20call%3A%0A%0AName%3A%0ACompany%3A%0ASubject%3A%0ADescription%3A'>Request</a> Request a call with us
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-12 col-sm-4'>
                        <Card>
                            <CardHeader>
                                <CardLabel icon='Chat' iconColor='primary'>
                                    <CardTitle>Chat</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                Chat with us using the messenger at the bottom right of your screen
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    )
}
