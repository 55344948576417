import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Page from '../../../layout/Page/Page'
import './TrackerListDashboard.css'
import TrackerExplanation from './TrackerExplanation.json'
import Card, { CardBody, } from '../../../components/bootstrap/Card'
import Button from '../../../components/bootstrap/Button'
import { useSelector } from 'react-redux'
import { Dots } from 'loading-animations-react';
import { NewTrackerModal } from '../../../components/trackers/NewTrackerModal/NewTrackerModal'
import { TrackerListDashboardTrackerItem } from './TrackerListDashboardTrackerItem'
import YouTubeEmbed from '../../../components/external/YoutubeEmbed'

export const TrackerListDashboard = () => {


    const [hideTutorial, setHideTutorial] = useState(true)
    const [isNewTrackerModalOpen, setIsNewTrackerModalOpen] = useState(false)

    const trackersState = useSelector(state => state?.tracker?.state)

    useEffect(() => {

    }, [trackersState])


    return (
        <PageWrapper title='Campaign List Dashboard'>
            <Page>
                <div className='row'>
                    <div className='centered-text col-12'>
                        <div className='display-3 fw-bold py-3'>My Campaigns</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='centered-text col-12'>
                        <div
                            onClick={() => setHideTutorial(false)}
                            className='display-5 py-3 nav-link cursor-pointer hover-underline'>What is a Campaign?</div>
                    </div>
                </div>
                <div className='row mb-5'  style={{ display: hideTutorial ? "none" : "block" }} >
                    <div className='centered-text col-12'>
                        <YouTubeEmbed videoId={TrackerExplanation.videoId} />
                    </div>
                </div>
                <div className='row mb-3' style={{ display: hideTutorial ? "none" : "block" }} >
                    <div className='centered-text col-12'>
                        <div
                            className='display-6'>{TrackerExplanation.explanation}
                        </div>
                    </div>
                </div>
                <div className='row centered-text'>
                    <div className='centered-text col-12 mt-3'>
                        {
                            !trackersState?.loading && trackersState?.value.length == 0 &&
                            <div className='display-7'>You have no campaigns. Click the button below to create a new one</div>
                        }
                    </div>
                </div>
                <div className='row centered-text'>
                    {
                        trackersState?.loading ?
                            <div className='col-md-4'>
                                <Dots></Dots>
                            </div> :
                            trackersState?.value?.map(tracker => {
                                return (
                                    <TrackerListDashboardTrackerItem tracker={tracker} />
                                )
                            })
                    }
                    <div className='col-md-4'>
                        <Card stretch>
                            <CardBody className='h-100 d-flex align-items-center justify-content-center'>
                                <Button
                                    onClick={() => setIsNewTrackerModalOpen(true)}
                                    color='info'
                                    size='lg'
                                    isLight
                                    className='w-100 h-100'
                                    icon='AddCircle'>
                                    Add New Campaign
                                </Button>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>

            <NewTrackerModal isNewTrackerModalOpen={isNewTrackerModalOpen} setIsNewTrackerModalOpen={setIsNewTrackerModalOpen} />
        </PageWrapper>
    )
}
