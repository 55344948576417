import React, { useEffect, useState } from 'react'
import TaskService from '../../../services/TaskService'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Icon from '../../../components/icon/Icon';
import { useDispatch } from 'react-redux';
import { updateTrackerTasks } from '../../../redux/slices/TrackerSlice';
import Input from '../../../components/bootstrap/forms/Input';
import classNames from 'classnames';
import { getObjectId } from '../../../services/Common';

export const SubTaskPanel = ({ focusTask }) => {

    const focusTaskId = focusTask?._id
    const [isSubTasksLoading, setIsSubTasksLoading] = useState(false)
    const [subTasks, setSubTasks] = useState([])
    const dispatch = useDispatch()
    const [newTaskTitle, setNewTaskTitle] = useState("")

    useEffect(async () => {
        if (focusTaskId) {
            setIsSubTasksLoading(true)
            const ss = await TaskService.getSubTasks(focusTaskId)
            const sortedSubTasks = ss?.sort((a, b) => (a.index > b.index) ? 1 : -1)
            setSubTasks(sortedSubTasks)
            setIsSubTasksLoading(false)
        }
    }, [focusTaskId])


    const handleSubTaskDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;

        var updatedList = [...subTasks];

        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);

        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

        const newSubTasks = updatedList?.map((st, i) => {
            const newSubTask = { ...st, index: i }
            TaskService.upsertTask(newSubTask)
            return newSubTask
        })

        setSubTasks(newSubTasks)
    };

    const upsertSubTask = async (newST, index) => {
        const subTask = {
            _id: newST?._id ?? getObjectId(),
            index: newST?.index ?? subTasks.length,
            ...newST,
            parent: focusTaskId
        }
        TaskService.upsertTask(subTask)
        var newSubTasks = []

        if (index === null || typeof index === 'undefined') {
            newSubTasks = [...subTasks, subTask]
            setSubTasks(newSubTasks)
        }
        else {
            newSubTasks = [...subTasks]
            newSubTasks[index] = subTask
            setSubTasks(newSubTasks)
        }

        const numSubTasks = newSubTasks.filter(x => !x.deleted).length
        var tasksToSave = [{ ...focusTask, _numSubTasks: numSubTasks }]
        dispatch(updateTrackerTasks({ tasks: tasksToSave }))
    }

    return (
        <div className="sub-task-section">
            <div className="border-bottom h2 mt-4">
                SubTasks
            </div>
            <div>

                <DragDropContext onDragEnd={handleSubTaskDrop}>
                    <Droppable droppableId="listr">
                        {(provided) => (

                            <div className="row listr"
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                <div>
                                    {
                                        subTasks
                                            ?.map((st, sti) => (
                                                <Draggable key={st?._id ?? sti} draggableId={st?._id ?? sti} index={sti}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                        >
                                                            <div
                                                                className={classNames({
                                                                    "col-md-12 border-bottom": true,
                                                                    "d-none": st?.deleted,
                                                                })}
                                                            >
                                                                <div className={"row st " + (st?.status == "Complete" ? "st-complete" : "")}>
                                                                    <div className='col-sm-2'>
                                                                        <span
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <Icon
                                                                                className="drag-handles st-icon hover-hidden"
                                                                                onClick={e => upsertSubTask({ ...st, status: st?.status == "Complete" ? "New" : "Complete" }, sti)}
                                                                                icon="DragHandle" />
                                                                        </span>
                                                                        <Icon className="st-icon hover-hidden"
                                                                            onClick={e => upsertSubTask({ ...st, deleted: true }, sti)}
                                                                            icon="Delete"
                                                                            color="danger"
                                                                        />
                                                                        <Icon className='st-completion-button'
                                                                            onClick={e => upsertSubTask({ ...st, status: st?.status == "Complete" ? "New" : "Complete" }, sti)}
                                                                            color={st?.status == "Complete" ? "success" : null}
                                                                            icon="TaskAlt" />
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                        <span contentEditable
                                                                            onBlur={e => upsertSubTask({ ...st, title: e.currentTarget.textContent }, sti)}
                                                                        >
                                                                            {st?.title}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                    }
                                </div>
                                <div className='col-sm-2'></div>
                                <div className="col-6">
                                    <div>
                                        <Input
                                            style={{ width: "100%" }}
                                            className='addNewTaskBox slim-input'
                                            onChange={e => setNewTaskTitle(e.target.value)}
                                            placeholder='+Add Sub Task'
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    setNewTaskTitle("")
                                                    upsertSubTask({ title: e.target.value })
                                                }
                                            }}
                                            value={newTaskTitle}
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

            </div>
        </div>
    )
}
