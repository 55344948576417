import React, { createRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingFullPage } from '../../loading/LoadingFullPage/LoadingFullPage';
import { BasicPDFViewer } from '../../pdf/BasicPDFViewer/BasicPDFViewer';
import FileService from '../../../services/FileService';
import CreatableSelect from 'react-select/creatable';
import Input from '../../bootstrap/forms/Input';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Checks from '../../bootstrap/forms/Checks';
import Button from '../../bootstrap/Button';
import { FileDropPad } from '../../Files/FileDropPad';
import Select from '../../bootstrap/forms/Select';
import { AttachMoney } from '../../icon/material-icons';
import FileJobService from '../../../services/FileJobService';
import a4Logger from '../../../services/a4Logger';
import { useDispatch } from 'react-redux';
import { getFileJobs } from '../../../redux/slices/FileJobSlice';

export const NewFileJobWizard = ({ files, callback }) => {

    const link = createRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isMultipleWatermarks, setIsMultipleWatermarks] = useState(false)
    const { fileJobId } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [fileJob, setFileJob] = useState(null)
    const [internalFiles, setInternalFiles] = useState([])
    const [watermarkLoc, setWatermarkLoc] = useState("top")
    const [loadingMessage, setLoadingMessage] = useState("Loading")

    const [watermarkTexts, setWatermarkTexts] = useState([])
    const [watermarkText, setWatermarkText] = useState("")

    const [attachments, setAttachments] = useState([])

    const deleteAttachment = (index) => {
        const newAttachments = [...attachments]
        newAttachments.splice(index, 1)
        setAttachments(newAttachments)
    }

    const startWaterMarkJob = async () => {
        setIsLoading(true)

        try {
            // Upload files on the server
            setLoadingMessage("Uploading files")
            a4Logger.logUserEvent("Beginning watermarking jobs", { sourceFiles: attachments.length })
            const uploadedFiles = await FileService.uploadFiles(attachments?.map(f => f?.file))
            const sourceFileIds = uploadedFiles?.map(f => f?._id)

            // Create the variations
            setLoadingMessage("Staging file variations")
            const waterMarksToCreate = isMultipleWatermarks ? watermarkTexts?.map(x => x.value) : [watermarkText]

            a4Logger.logTrace("Setting watermarks to create", { watermarks: waterMarksToCreate.length })
            const variations = waterMarksToCreate?.map(watermarkText => (
                {
                    watermark: {
                        text: watermarkText,
                        location: watermarkLoc,
                        source: "static"
                    },
                    fileSet: {
                        id: watermarkText,
                        title: watermarkText
                    }
                }
            ))

            setLoadingMessage("Initializing the job")
            // Create the filejob
            const fileJob = {
                job: {
                    type: "watermark",
                    sourceFiles: sourceFileIds,
                },
                variations: variations
            }

            a4Logger.logTrace("Creating filejob")
            const resp = await FileJobService.createFileJobs([fileJob])
            const { job, files } = resp[0]

            a4Logger.logTrace("Navigating to new file job", { id: job._id })
            navigate(`/filejob/${job._id}`)

            dispatch(getFileJobs())
            setLoadingMessage("Loading")
            setIsLoading(false)
            if (callback) {
                a4Logger.logTrace("Calling callback")
                callback()
            }
        }
        catch (e) {
            a4Logger.logError(e)
            a4Logger.logErrorMessage("File Job submission failed", e.message, {})
            setIsLoading(false)
        }

        setLoadingMessage("Loading")
        setIsLoading(false)
    }

    const waterMarkLocations = [
        {
            title: "Top",
            value: "start"
        },
        {
            title: "Center",
            value: "center"
        },
        {
            title: "Bottom",
            value: "end"
        }
    ]

    const startFileAdd = async (files) => {
        setIsLoading(true)

        const fileProms = Object.keys(files)?.map(async (key, index) => {

            const file = files[key]
            var fileExtRe = /(?:\.([^.]+))?$/;
            var matches = fileExtRe.exec(file.name);
            if (matches.length < 1) {
                throw Error('File Name is invalid and cannot determine extension')
            }
            const extension = matches[1]
            var type = "Other"
            switch (extension) {
                case 'pdf':
                    type = "PDF"
                    break;
                case 'ppt':
                case 'pptx':
                    type = "PowerPoint"
                    break;
                default:
                    type = "Other"
                    break;
            }

            const url = await (FileService.toString(file))

            const newF = {
                'title': file.name,
                'description': '',
                'file': file,
                'type': type,
                'url': url
            }


            return newF
        });

        const newFiles = await Promise.all(fileProms)
        const prevAttachments = attachments ?? [];
        setAttachments([...prevAttachments, ...newFiles])

        setIsLoading(false)
        return false
    }

    useEffect(async () => {
        setAttachments([])
        await startFileAdd(files)
    }, [files])

    const isNullOrEmpty = (str) => {
        return str === null || str === undefined || str === ""
    }

    const displayWatermarkText = isMultipleWatermarks ? (watermarkTexts?.[0]?.value ?? "") : watermarkText
    const numWatermarks = isMultipleWatermarks ? watermarkTexts?.length ?? 0 : (isNullOrEmpty(watermarkText) ? 0 : 1)

    const validateParameters = () => {
        if (numWatermarks < 1) {
            return false
        }
        if (!attachments || attachments?.length < 1) {
            return false
        }

        return true
    }

    return (
        isLoading ?
            <LoadingFullPage loadingMessage={loadingMessage} /> :
            <>

                <div className='watermark-options'>
                    <div className='mb-3' >
                        <div className=''>
                            <label for="watermarkText" className="form-label">Multiple Watermarks</label>
                        </div>
                        <FormGroup
                            id='watermarksrc'
                            isFloating>
                            <Checks
                                type='switch'
                                id='multiplewatermarks'
                                name='multiplewatermarks'
                                onChange={e => setIsMultipleWatermarks(e.target.checked)}
                                checked={isMultipleWatermarks}
                            />
                        </FormGroup>
                    </div >
                    <div className='row'>
                        {
                            isMultipleWatermarks ?
                                <div className='col-12 col-md-9 col-lg-6 mb-4' style={{ zIndex: 2 }}>
                                    <FormGroup id="watermarkText" label="Watermark Text">
                                        <CreatableSelect
                                            isMulti
                                            formatCreateLabel={(inputValue) => `Press enter to add watermark '${inputValue}'`}
                                            onChange={(newValue) => {
                                                const nn = [...watermarkTexts, newValue]
                                                setWatermarkTexts(newValue)
                                            }}
                                            form="false"
                                            value={watermarkTexts} />
                                    </FormGroup>
                                </div> :
                                <div className='col-12 col-md-9 col-lg-6 mb-4'>
                                    <FormGroup id="watermarkText" label="Watermark Text">
                                        <Input
                                            onChange={e => {
                                                setWatermarkText(e.target.value)
                                            }}
                                            value={watermarkText}
                                        />
                                    </FormGroup>
                                </div>
                        }
                    </div>
                    <div className="row">
                        <div className='col-12 col-md-9 col-lg-6 mb-4'>
                            <FormGroup
                                id='watermarkloc'
                                label='Watermark Location'
                                isFloating>
                                <Select
                                    onChange={e => {
                                        const newAttachemnts = attachments?.map(a => { return { ...a, watermarloc: e.target.value } })

                                        setAttachments(newAttachemnts)
                                        setWatermarkLoc(e.target.value)
                                    }}
                                    value={watermarkLoc}
                                // invalidFeedback={formik?.errors.firstName}
                                >
                                    {
                                        waterMarkLocations.map((type, i) => (
                                            <option value={type.value}>{type.title}</option>
                                        ))
                                    }
                                </Select>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='mb-2'>
                        <Button
                            isDisable={!validateParameters()}
                            size="xl"
                            color="success"
                            onClick={startWaterMarkJob}>
                            Start Watermarking
                        </Button>
                    </div>
                    <div className='mb-2'>
                        {
                            numWatermarks > 0 &&
                            <>
                                <b>{attachments?.length} </b> files, <b>{numWatermarks} </b> watermarks will generate <b>{attachments?.length * numWatermarks}</b> watermarked files
                            </>
                        }
                    </div>
                </div>
                {
                    attachments?.map((attachment, i) => {
                        switch (attachment?.type) {
                            case "PDF":
                            default:
                                return <BasicPDFViewer
                                    customText={displayWatermarkText}
                                    enabled={false}
                                    attachment={attachment}
                                    setAttachment={(a) => {
                                        const newAttachments = [...attachments]
                                        newAttachments[i] = a
                                        setAttachments(newAttachments)
                                    }}
                                    deleteAttachment={() => deleteAttachment(i)}
                                />
                                break;
                        }
                    })
                }
                <div className='pdf-watermark-tail mt-5'>
                    <div className='row'>
                        <div className='col-12 col-md-9'>
                            <FileDropPad
                                label="Click here or drop to add a file"
                                maxSize={20}
                                startFileAdd={(files) => { startFileAdd(files) }} />
                            <h5>20MB, 10 files limit</h5>
                        </div>
                        <div className='col-12 col-md-3'>
                            <Button
                                isDisable={!validateParameters()}
                                size="xl"
                                color="success"
                                onClick={startWaterMarkJob}>
                                Start Watermarking
                            </Button>
                        </div>
                    </div>
                </div>
            </>
    )
}
