export const getNumEmails = (tracker) => {
    if (!tracker)
    {
        return 0
    }
    return tracker?._emails?.total ?? 0
}

export const getNumFiles = (tracker) => {
    if (!tracker)
    {
        return 0
    }
    return tracker?._files?.total ?? 0
}

export const getRemainingTrackerRows = (tracker) => {
    if (!tracker)
    {
        return 0
    }
    const incompleteStatuses = ["Active", "Blocked"]
    const incompleteRows = tracker?._trackerrows?.statusCounts.filter(x => incompleteStatuses.includes(x.status)).map(x => x.statusCount).reduce((acc, val) => acc + val, 0)
    return incompleteRows
}

export const getBlockedTrackerRows = (tracker) => {
    if (!tracker)
    {
        return 0
    }
    const blockedItems = tracker?._trackerrows?.statusCounts.find(x => x.status === "Blocked")?.statusCount ?? 0
    return blockedItems
}

// TODO: Add more granularity using task status
export const getPercentageComplete = (tracker) => {
    if (!tracker)
    {
        return 0
    }
    const incompleteRows = getRemainingTrackerRows(tracker)
    const complete = tracker?._trackerrows?.statusCounts.find(x => x.status === "Complete")?.statusCount ?? 0
    const total = incompleteRows + complete
    return Math.round((complete * 100 / total))
}

export const getDueDateString = (tracker) => {
    if (!tracker)
    {
        return 0
    }
    if (tracker?.dueDate) {
        return moment(tracker?.dueDate).format("MM/DD/YYYY")
    }
    return null
}