import { Quote } from 'react-bootstrap-icons'
import { Button } from '../../subs/Button';
import { Form } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import React from 'react';

export const DefaultStringFilterInputRenderer = ({ col, filter, setFilter, data = null }) => {

    function isNullOrEmpty(value) {
        return value === null || value === undefined || value === '';
    }

    const dropDownOptions = [
        { text: 'Text', value: 'normal' },
        { text: 'Regex', value: 'regex' },
    ]

    const mode = filter.mode === 'regex' ? 1 : 0
    const nextMode = filter.mode === 'regex' ? 0 : 1
    const icon = dropDownOptions.find(o => o.value === filter.mode)?.icon ?? <Quote />

    const _updateFilter = (filter) => {
        const active = !isNullOrEmpty(filter.value)
        setFilter({ ...filter, active: active })
    }

    return (
        <div>
            <div className='row filterRow'>
                <InputGroup>
                    <Button isOutline color='primary' id='button-addon1' onClick={() => _updateFilter({ ...filter, mode: dropDownOptions[nextMode].value })}>
                        {dropDownOptions[mode].text}
                    </Button>
                    <Form.Control
                        type='text'
                        value={filter.value ?? ''}
                        onChange={(e) => _updateFilter({ ...filter, value: e.target.value })}
                        placeholder=''
                        ariaLabel='Example text with button addon'
                        ariaDescribedby='button-addon1'
                    />
                </InputGroup>
            </div>
        </div>
    )
}
