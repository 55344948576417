import React, { forwardRef, ReactNode, ElementType, HTMLAttributes } from 'react';
import PropTypes from 'prop-types';

export const TagWrapper = forwardRef(({ tag: Tag, children, ...props }, ref) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Tag ref={ref} {...props}>
            {children}
        </Tag>
    );
});