import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Button from '../../../components/bootstrap/Button';
import Card, {
    CardActions,
    CardBody,
    CardFooter,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import { setFocusPanelOpen, updateTrackerTasks } from '../../../redux/slices/TrackerSlice';
import { dummyUserSelectOptions, getObjectId, taskPriorirtyLevelSelectOptions, taskStatusSelectOptions } from '../../../services/Common';
import TaskService from '../../../services/TaskService';
import { SubTaskPanel } from './SubTaskPanel';
import { TaskDescription } from './TaskDescription';
import "./TaskPanel.css";
import { TaskTitle } from './TaskTtitle';
import Chat, { ChatGroup, EmailChatGroup } from '../../../components/Chat';
import { LoadingFullPage } from '../../../components/loading/LoadingFullPage/LoadingFullPage';
import a4Logger from '../../../services/a4Logger';
import { PDFViewerModal } from '../../../components/pdfviewer/PDFViewerModal';
import { TaskPanelFileInput } from './TaskPanelFileInput';
import FileService from '../../../services/FileService';
import { TrackerBoardPanelFilesSection } from './TrackerBoardPanelFilesSection';

export const TaskPanel = ({ focusTask, updateFocusTask }) => {

    const PriorityLevels = ['High', 'Medium', 'Low']

    const dueDate = focusTask.dueDate && typeof focusTask.dueDate === "string" ? Date.parse(focusTask.dueDate) : focusTask.dueDate;
    const dispatch = useDispatch()

    const _updateFocusTask = (change) => {
        updateFocusTask(focusTask, change)
    }

    const updateTaskTags = (
        newValue,
        actionMeta
    ) => {
        _updateFocusTask({ tags: newValue.map(x => x.value) })
    };

    const focusTaskId = focusTask?._id
    const [isActivityLoading, setIsActivityLoading] = useState(false)
    const [taskEvents, setTaskEvents] = useState([])
    const [textAreaContent, setTextAreaContent] = useState('');
    const [TaskActivityPanel, setTaskActivityPanel] = useState([])
    const [isPDFViewerModalOpen, setIsPDFViewerModalOpen] = useState(false)
    const [pdfViewerFile, setPdfViewerFile] = useState(null)
    const user = useSelector(state => state.user.me.value)

    // TODO: Rather than pulling activity, comments, and subtasks in separate API calls, create 1 API call that pulls all of this data

    const fileClickHandler = (file) => {
        const fileId = file?.fileId ?? file?._id

        if (file?.type == "PDF") {
            setPdfViewerFile(file)
            setIsPDFViewerModalOpen(true)
        }
        else {
            a4Logger.logUserEvent("Downloading File", { fileId: fileId })
            FileService.downloadFile(fileId)
        }
    }

    useEffect(async () => {
        if (focusTaskId) {
            setIsActivityLoading(true)

            try {
                const taskActivity = await TaskService.getTaskActivity(focusTaskId)
                setTaskEvents(taskActivity)
                setIsActivityLoading(false)
            }
            catch (err) {
                a4Logger.logError(err, { displayMessage: "Error loading task activity" })
            }
        }
    }, [focusTaskId])

    const addComment = async (comment, filesToUpload) => {

        var fileIds = []
        var filesCreated = []
        if (filesToUpload?.length > 0) {
            filesCreated = await FileService.uploadFiles(filesToUpload)
            fileIds = filesCreated.map(x => x._id)
        }

        const event = {
            type: "Comment",
            body: comment,
            parent: focusTaskId,
            attachments: fileIds,
            _attachments: filesCreated,
            createdBy: user.sub,
            _id: getObjectId()
        }
        TaskService.addComment(event)

        const numComments = taskEvents?.filter(x => !x.deleted && x.type == "comment").length + 1
        var tasksToSave = [{ ...focusTask, _numComments: numComments, files: [...focusTask.files, ...fileIds] }]
        dispatch(updateTrackerTasks({ tasks: tasksToSave }))
        setTaskEvents([...taskEvents, event])
    }

    return (
        <>
            <Card stretch className="taskpanel-card">
                <CardHeader className="card-header-border-1">
                    <CardLabel className='fresler-table-task-panel-label' >
                        <CardTitle>
                            <TaskTitle focusTask={focusTask} updateFocusTask={_updateFocusTask} />
                        </CardTitle>
                    </CardLabel>
                    <CardActions>
                        <Button icon="Delete" color="danger" onClick={() => {
                            const message = `Are you sure you want to delete this task? This cannot be undone.`
                            if (window.confirm(message)) {
                                _updateFocusTask({ deleted: !focusTask?.deleted })
                                dispatch(setFocusPanelOpen(false))
                            }
                        }} />
                        <Button className='white'
                            color='link'
                            icon='Close'
                            onClick={() => {
                                dispatch(setFocusPanelOpen(false))
                            }} />
                    </CardActions>
                </CardHeader>
                <CardBody isScrollable>
                    <div className='d-flex flex-column align-items-left mb-5'>
                        <div>
                            <div className="trackerboard-taskpanel-table">
                                <div className='trackerboard-taskpanel-table-row'>
                                    <div className='trackerboard-taskpanel-table-cell' scope="row" style={{ bottom: 0, verticalAlign: "bottom" }}>Status</div>
                                    <div className='trackerboard-taskpanel-table-cell zindex-dropdown' >
                                        <div style={{ minWidth: "200px" }}>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                options={taskStatusSelectOptions}
                                                value={taskStatusSelectOptions?.find(x => x.value == focusTask?.status)}
                                                onChange={(newValue, actionMetaData) => _updateFocusTask({ status: newValue.value })
                                                }
                                                form="false"
                                                isClearable={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className='trackerboard-taskpanel-table-row'>
                                    <div className="trackerboard-taskpanel-table-cell" scope="row" style={{ bottom: 0, verticalAlign: "bottom" }}>Assigned To</div>
                                    <div className="trackerboard-taskpanel-table-cell" >
                                        <div style={{ minWidth: "200px" }}>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                options={dummyUserSelectOptions}
                                                value={dummyUserSelectOptions?.find(x => x.value == focusTask?.owner) ?? null}
                                                onChange={(newValue, actionMetaData) => _updateFocusTask({ owner: newValue.value })}
                                                form="false"
                                                isClearable={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className='trackerboard-taskpanel-table-row'>
                                    <div className='trackerboard-taskpanel-table-cell' style={{ bottom: 0, verticalAlign: "bottom" }} scope="row">Priority</div>
                                    <div className='trackerboard-taskpanel-table-cell'>
                                        <div style={{ minWidth: "200px" }}>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                options={taskPriorirtyLevelSelectOptions}
                                                value={taskPriorirtyLevelSelectOptions?.find(x => x.value == focusTask?.priority)}
                                                onChange={(newValue, actionMetaData) => _updateFocusTask({ priority: newValue.value })}
                                                form="false"
                                                isClearable={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className='trackerboard-taskpanel-table-row'>
                                    <div className="trackerboard-taskpanel-table-cell" style={{ bottom: 0, verticalAlign: "bottom" }} scope="row">Due Date</div>
                                    <div className="trackerboard-taskpanel-table-cell">
                                        <div>
                                            <DatePicker
                                                selected={dueDate}
                                                // style={textStyle}
                                                onChange={(date) => { _updateFocusTask({ dueDate: date }) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='trackerboard-taskpanel-table-row'>
                                    <div className='trackerboard-taskpanel-table-cell' style={{ bottom: 0, verticalAlign: "bottom" }} scope="row">Tags</div>
                                    <div className='trackerboard-taskpanel-table-cell'>
                                        <div>
                                            <CreatableSelect style={{ color: "black" }} className="tagSelect"
                                                // styles={creatableStyle}
                                                noOptionsMessage={() => 'Type to create a new tag'}
                                                formatCreateLabel={inputValue => `Press enter to add tag '${inputValue}'`}
                                                options={[]}
                                                isMulti
                                                minMenuHeight={10}
                                                value={focusTask.tags?.map(t => ({ label: t, value: t })) ?? []}
                                                onChange={(newValue, actionMetaData) => updateTaskTags(newValue, actionMetaData)}
                                                form="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>
                            Description
                        </p>
                    </div>
                    <div>
                        <TaskDescription focusTask={focusTask} updateFocusTask={_updateFocusTask} />
                    </div>
                    <SubTaskPanel focusTask={focusTask} />
                    <TrackerBoardPanelFilesSection
                        focusTask={focusTask}
                        updateFocusTask={_updateFocusTask}
                        fileClickHandler={fileClickHandler}
                    />
                    <div className="border-bottom h2 mt-4 mb-5">
                        Activity
                    </div>
                    <div className="activitySection" >
                        <div  >
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="comment-widgets m-b-20">
                                        {
                                            isActivityLoading ? <LoadingFullPage />
                                                :
                                                <Chat>
                                                    {
                                                        taskEvents ?
                                                            taskEvents.map((event, ind) => {
                                                                // TODO: Need to have other history besides comments

                                                                var author = {};

                                                                switch (event?.type) {
                                                                    case "Comment":
                                                                        return (
                                                                            <ChatGroup
                                                                                fileClickHandler={fileClickHandler}
                                                                                key={ind}
                                                                                messages={[event]}
                                                                                isReply={event.author == user.sub}
                                                                            />
                                                                        )
                                                                        break;
                                                                    case "Email":
                                                                        return (
                                                                            <EmailChatGroup
                                                                                fileClickHandler={fileClickHandler}
                                                                                key={ind}
                                                                                messages={[event]}
                                                                                isReply={event.author == user.sub}
                                                                            />
                                                                        )
                                                                        break;
                                                                    default:
                                                                        break;
                                                                }
                                                            })
                                                            : <>No recent activity</>
                                                    }
                                                </Chat>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </CardBody>
                <CardFooter className="card-footer-border-1 fresler-table-task-panel-footer">
                    <TaskPanelFileInput addComment={addComment} />
                </CardFooter>
            </Card>
            <PDFViewerModal isPDFViewerModalOpen={isPDFViewerModalOpen} setIsPDFViewerModalOpen={setIsPDFViewerModalOpen} file={pdfViewerFile} />
        </>
    )
}
