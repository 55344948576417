import React, {
    createRef,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import "./SplitPane.css";
import SplitPaneContext from "./SplitPaneContext";

export const SplitPane = ({ children, ...props }) => {
    const [clientHeight, setClientHeight] = useState(null);
    const [clientWidth, setClientWidth] = useState(null);
    const [wrapperWidth, setWrapperWidth] = useState(0)
    const yDividerPos = useRef(null);
    const xDividerPos = useRef(null);
    const wrapperRef = useRef(null);

    useEffect(() => {
        console.log('wrapperRef', wrapperWidth)
    }, [wrapperWidth]);

    useEffect(() => {
        // Update the width when the component mounts and whenever the window is resized
        function handleResize() {
            if (wrapperRef.current) {
                setWrapperWidth(wrapperRef.current.offsetWidth);
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial width calculation
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onMouseHoldDown = (e) => {
        yDividerPos.current = e.clientY;
        xDividerPos.current = e.clientX;
    };

    const onMouseHoldUp = () => {
        yDividerPos.current = null;
        xDividerPos.current = null;
    };

    const onMouseHoldMove = (e) => {
        if (!yDividerPos.current && !xDividerPos.current) {
            return;
        }

        setClientHeight(clientHeight + e.clientY - yDividerPos.current);
        setClientWidth(clientWidth + e.clientX - xDividerPos.current);

        yDividerPos.current = e.clientY;
        xDividerPos.current = e.clientX;
    };

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);

        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        };
    });

    return (
        <div className="split-panel-contextd split-pane-row" {...props} ref={wrapperRef}>
            <SplitPaneContext.Provider
                value={{
                    clientHeight,
                    setClientHeight,
                    clientWidth,
                    wrapperWidth,
                    setClientWidth,
                    onMouseHoldDown,
                }}
            >
                {children}
            </SplitPaneContext.Provider>
        </div>
    );
};

export const Divider = (props) => {
    const { onMouseHoldDown } = useContext(SplitPaneContext);

    return <div {...props} onMouseDown={onMouseHoldDown} className="separator-col-wrapper">
        <div className="separator-col" />
    </div>
};

export const SplitPaneTop = (props) => {
    const topRef = createRef();
    const { clientHeight, setClientHeight } = useContext(SplitPaneContext);

    useEffect(() => {
        if (!clientHeight) {
            setClientHeight(topRef.current.clientHeight);
            return;
        }

        topRef.current.style.minHeight = clientHeight + "px";
        topRef.current.style.maxHeight = clientHeight + "px";
    }, [clientHeight]);

    return (
        <div {...props} className="split-pane-top" ref={topRef}>
            Test
        </div>
    );
};

export const SplitPaneBottom = (props) => {

    return (
        <div {...props} className="split-pane-bottom">
            Test Bottom
        </div>
    );
};

export const SplitPaneLeft = (props) => {
    const topRef = createRef();
    const { clientWidth, setClientWidth, wrapperWidth } = useContext(SplitPaneContext);

    useEffect(() => {
        if (!clientWidth) {
            const baseWidth = wrapperWidth;
            const numPanels = props?.numPanels || 1;
            const newClientWidth = baseWidth - ((.25 * baseWidth) * (numPanels - 1));
            console.log('newClientWidth', newClientWidth)
            setClientWidth(baseWidth - ((.25 * baseWidth) * (numPanels - 1)));
            return;
        }

        topRef.current.style.minWidth = clientWidth + "px";
        topRef.current.style.maxWidth = clientWidth + "px";
    }, [clientWidth]);

    useEffect(() => {
        const baseWidth = wrapperWidth;
        const numPanels = props?.numPanels || 1;
        const newClientWidth = baseWidth - ((.25 * baseWidth) * (numPanels - 1));
        setClientWidth(newClientWidth);
        console.log('newClientWidth', newClientWidth)
    }, [props?.numPanels]);

    return <div {...props} className="split-pane-left" ref={topRef} />;
};

export const SplitPaneRight = ({ children, ...props }) => {

    return (
        <div {...props} className="split-pane-right">
            {children}
        </div>
    );
};

export default SplitPane;
