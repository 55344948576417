

export const dummyFileJobData = [
    {
        _id: "64934a574ca5910806c36d16",
        type: 'watermark',
        sourceFile: "64934a494ca5910806c36d10",
        title: '',
        updatedBy: 't22',
        updatedAt: new Date("2023-06-21T19:07:03.780Z"),
        createdAt: new Date("2023-06-21T19:07:03.783Z"),
        fileStatuses: {
            "ready": 5,
            "processing": 2,
            "queued": 2
        },
        __v: 0
    },
    {
        _id: "64934a574ca5910806c36d167",
        type: 'watermark',
        sourceFile: "64934a494ca5910806c36d11",
        title: '',
        updatedBy: 't22',
        updatedAt: new Date("2023-06-21T19:07:03.780Z"),
        createdAt: new Date("2023-06-21T19:07:03.783Z"),
        fileStatuses: {
            "ready": 5,
            "failed": 1,
            "processing": 2,
            "queued": 2
        },
        __v: 0
    }
]