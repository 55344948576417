import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Icon from '../../icon/Icon';
import UserService from '../../../services/UserService';
import a4Logger from '../../../services/a4Logger';
import Avatar from '../../Avatar';
import { AvatarMemberInput } from './AvatarMemberInput';

export const AvatarGroupMemberList = ({ members, updateMembers }) => {

    const isEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const [orgUsers, setOrgUsers] = useState([]);

    const updateOrgUsers = async () => {
        var orgUsers = UserService.getOrgUsers()
        await Promise.all(
            orgUsers?.map(async (user) => {
                return {
                    ...user,
                    value: user.user_id,
                    label: <div style={{ color: "black" }}><img className="avatarImg" src={user.picture} height="30px" width="30px" />{user.name} </div>
                }
            })).then((orgUserOptions) => {
                setOrgUsers(orgUserOptions)
            })
    }

    useEffect(async () => {
        await updateOrgUsers()

    }, []);


    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th style={{ width: "10%" }} scope="col"></th>
                        <th style={{ width: "7%" }} scope="col"></th>
                        <th style={{ width: "35%" }} scope="col">Name</th>
                        <th style={{ width: "35%" }} scope="col">Email</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (members ?? []).map((member, i) => {
                            var resolvedMember = UserService.getUser(member)
                            return (
                                <tr>
                                    <td >
                                        {
                                            updateMembers && <div>
                                                <Icon icon="x-circle" onClick={() => { members.splice(i, 1); updateMembers(members) }} />
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        <Avatar
                                            size="30px"
                                            src={resolvedMember?.picture}
                                            srcSet={resolvedMember?.picture}
                                            userName={resolvedMember?.name}
                                        />
                                    </td>
                                    <td>{resolvedMember?.name}</td>
                                    <td>
                                        {resolvedMember?.email}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                    </tr>
                </tbody>
            </table>
            {
                updateMembers && <AvatarMemberInput options={orgUsers} updateMembers={updateMembers} />
            }
        </>
    )
}
