export const getDefaultSliceExtraReducers = (getThunk, upsertThunk, deleteThunk) => {
    return (builder) => {
        builder
            .addCase(getThunk.pending, (state) => {
                state.state.loading = true
                state.state.error = null
            })
            .addCase(getThunk.fulfilled, (state, action) => {
                state.state.loading = false
                state.state.value = action.payload


            })
            .addCase(getThunk.rejected, (state, action) => {
                state.state.loading = false
                state.state.error = action.error.message
            })
        builder
            .addCase(upsertThunk.pending, (state) => {
                state.state.loading = true
                state.state.error = null
            })
            .addCase(upsertThunk.fulfilled, (state, action) => {
                state.state.loading = false;

                // Create a map of existing items using _id as keys
                var existingItemsMap = new Map(state.state.value.map(item => [item._id, item]));

                // Update existing items and append new ones
                state.state.value = action.payload.reduce((acc, newItem) => {
                    if (existingItemsMap.has(newItem._id)) {
                        // If the item already exists, update it
                        acc.push({ ...existingItemsMap.get(newItem._id), ...newItem });
                        existingItemsMap.delete(newItem._id); // Remove from map after updating
                    } else {
                        // If the item is new, append it
                        acc.push(newItem);
                    }
                    return acc;
                }, []);
            })
            .addCase(upsertThunk.rejected, (state, action) => {
                state.state.loading = false
                state.state.error = action.error.message
            })

        builder
            .addCase(deleteThunk.fulfilled, (state, action) => {
                state.state.loading = false
                state.state.value = state.state.value.filter(tracker => {
                    return tracker._id != action.payload
                })
            })
            .addCase(deleteThunk.rejected, (state, action) => {
                state.state.loading = false
                state.state.error = action.error.message
            })
    }
}