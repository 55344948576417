import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FileDropPad } from '../../components/Files/FileDropPad';
import Badge from '../../components/bootstrap/Badge';
import { NewFileJobModal } from '../../components/filejobs/NewFileJobWizard/NewFileJobModal';
import useDarkMode from '../../hooks/useDarkMode';
import useTourStep from '../../hooks/useTourStep';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
    SubHeaderLeft,
    SubheaderSeparator
} from '../../layout/SubHeader/SubHeader';
import { demoPages } from '../../menu';
import { FileJobDashboardFileJobItemList } from './FileJobDashboardFileJobList';


export const FileJobDashboard = () => {

    const [isOverWrite, setIsOverWrite] = useState(true)
    const [files, setFiles] = useState([])

    useTourStep(12);
    const dispatch = useDispatch();

    const [isNewFileJobModalOpen, setIsNewFileJobModalOpen] = useState(false)

    const { darkModeStatus } = useDarkMode();
    const navigate = useNavigate();
    const handleOnClickToEmployeeListPage = useCallback(
        () => navigate(`../${demoPages.appointment.subMenu.employeeList.path}`),
        [navigate],
    );

    const myFileJobs = useSelector(state => state?.fileJob?.myFileJobs)
    const me = useSelector(state => state.user.me)

    return (
        <PageWrapper title={demoPages.projectManagement.subMenu.list.text}>
            <SubHeader>
                <SubHeaderLeft>
                    <strong className='fs-5'>Hi {me?.value?.name}</strong>
                    <SubheaderSeparator />
                    <span>
                        You have created
                        <Badge color='success' isLight>
                            {myFileJobs?.value?.length} job(s)
                        </Badge>
                        .
                    </span>
                </SubHeaderLeft>
            </SubHeader>
            <Page>
                <div className='mb-3 mt-5'>
                    <h5>You can use the portal below to watermark documents. Simply drag your documents into the widget below to begin the watermarking process. You can watermark documents in batches and receive an email notification once the documents have completed watermarking.</h5>
                </div>
                <div className='mb-3 mt-5'>
                    <FileDropPad
                        label="Click here or drop a file to begin watermarking"
                        maxSize={20}
                        startFileAdd={(files) => { setFiles(files) }} />
                    <h5>20MB, 10 files limit</h5>
                </div>
                <FileJobDashboardFileJobItemList filejobs={myFileJobs?.value} />
            </Page>
            <NewFileJobModal
                isNewFileJobModalOpen={isNewFileJobModalOpen}
                setIsNewFileJobModalOpen={setIsNewFileJobModalOpen}
                files={files}
            />
        </PageWrapper>
    )
}
