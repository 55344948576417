import classNames from 'classnames';
import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logos/logosquare.png';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody, CardTitle } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

import { YouTubeEmbedModal } from '../../../components/content/YoutubeEmbed/YouTubeEmbedModal';
import useDarkMode from '../../../hooks/useDarkMode';
import useTourStep from '../../../hooks/useTourStep';
import { demoPages } from '../../../menu';
import './KnowledgeGrid.css';
import data from './helper/dummyKnowledgeData';

// eslint-disable-next-line react/prop-types
const Item = ({ _id, image, title, description, tags, color, link }) => {
	useTourStep(15);
	const { darkModeStatus } = useDarkMode();

	const navigate = useNavigate();
	const handleOnClick = useCallback(
		() => navigate(link),
		[navigate, _id],
	);
	return (
		<Card
			className='cursor-pointer shadow-3d-primary shadow-3d-hover'
			onClick={handleOnClick}
			data-tour={title}>
			<CardBody>
				<CardTitle>{title}</CardTitle>
				<div
					className={classNames(
						'ratio ratio-1x1',
						'rounded-2',
						`bg-l${darkModeStatus ? 'o25' : '10'}-${color}`,
						'mb-3',
					)}>
					<img
						width="100%"
						height="100%"
						src={image}
						style={{ objectFit: 'cover' }}
						className='img-responsive search-engine-image full-width'
					>
					</img>
					{/* <img
						src={image}
						alt=''
						width='100%'
						height='auto'
						className='img-fluid'
					/> */}
				</div>
				<p className='text-muted'>{description}</p>
				<div className='row g-2'>
					{!!tags &&
						// eslint-disable-next-line react/prop-types
						tags.map((tag) => (
							<div key={tag.text} className='col-auto'>
								<Badge isLight color={tag.color} className='px-3 py-2'>
									{tag.text}
								</Badge>
							</div>
						))}
				</div>
			</CardBody>
		</Card>
	);
};

const KnowledgeGridPage = () => {
	const { darkModeStatus } = useDarkMode();
	const [isNewModelModalOpen, setIsNewModelModalOpen] = useState(false)
	const [isYouTubeEmbedModalOpen, setIsYouTubeEmbedModalOpen] = useState(false)

	const [filterableData, setFilterableData] = useState(data);

	const searchAndFilterData = (searchValue, category) => {
		let tempData = data;

		if (category)
			tempData = data.filter((item) =>
				item.categories.find((categ) => categ.value === category),
			);

		return tempData.filter((item) => {
			return (
				item.title.toLowerCase().includes(searchValue) ||
				item.description.toLowerCase().includes(searchValue) ||
				item.content.toLowerCase().includes(searchValue) ||
				item.categories.find((categ) => categ.text.toLowerCase().includes(searchValue)) ||
				item.tags.find((tag) => tag.text.toLowerCase().includes(searchValue))
			);
		});
	};

	const debounce = (func, wait) => {
		let timeout;

		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	const onFormSubmit = (values) => {
		const searchValue = values.search.toString().toLowerCase();
		const newData = searchAndFilterData(searchValue, values.category);

		if (!values.search && !values.category) {
			setFilterableData(data);
		} else {
			setFilterableData(newData);
		}
	};

	const formik = useFormik({
		initialValues: {
			search: '',
			category: '',
		},
		onSubmit: onFormSubmit,
		onReset: () => setFilterableData(data),
	});


	const navigate = useNavigate();

	return (
		<PageWrapper title="Welcome">
			<Page>
				<div className='row'>
					<div className='col-12 text-center my-5'>
						<span className='sigma9-font display-5 fw-bold animate__animated animate__fadeIn'>Welcome to<img src={logo} height={60} />DealWinds</span>
						<br />
						<Button className="mt-3 pulse-button" color="info" size="lg" onClick={() => navigate('/campaigns')}>Launch A New Campaign</Button>{' '}
						<Button onClick={() => setIsYouTubeEmbedModalOpen(true)} className="mt-3" color="success" size="lg">Show Me How</Button>
					</div>
					{/* <div
						className='col-xxl-6 mx-auto text-center my-5'
						data-tour='knowledge-filter'>
						<form
							className={classNames('row', 'pb-4 px-3 mx-0 g-4', 'rounded-3', [
								`bg-l${darkModeStatus ? 'o25' : '10'}-primary`,
							])}
							onSubmit={formik.handleSubmit}>
							<div className='col-md-10'>
								<Input
									id='search'
									size='lg'
									placeholder='Type your question...'
									className={classNames('rounded-1', {
										'bg-white': !darkModeStatus,
									})}
									onChange={(e) => {
										formik.handleChange(e);

										if (e.target.value.length > 2)
											debounce(
												() =>
													onFormSubmit({
														...formik.values,
														search: e.target.value,
													}),
												1000,
											)();

										if (e.target.value.length === 0) formik.resetForm();
									}}
									value={formik.values.search}
								/>
							</div>
							<div className='col-md-2'>
								<Button
									size='lg'
									icon='Close'
									color='primary'
									className='w-100'
									rounded={1}
									onClick={formik.resetForm}
									type='reset'
									isDisable={!(formik.values.search || formik.values.category)}
								/>
							</div>
						</form>
					</div> */}
				</div>
				<div className='row mb-5 d-flex justify-content-center'>
					{
						filterableData.map((item) => (
							<div key={item._id} className='col-xl-3 col-lg-4 col-md-6 animate__animated animate__fadeIn'>
								{/* eslint-disable-next-line react/jsx-props-no-spreading */}
								<Item {...item} />
							</div>
						))
					}
				</div>
			</Page>

			<YouTubeEmbedModal
				isYouTubeEmbedModalOpen={isYouTubeEmbedModalOpen}
				setIsYouTubeEmbedModalOpen={setIsYouTubeEmbedModalOpen}
			/>

		</PageWrapper>
	);
};

export default KnowledgeGridPage;
