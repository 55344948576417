import React, { useCallback, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../../components/icon/Icon';
import { useDropzone } from 'react-dropzone'

export const TaskPanelFileInput = ({ addComment }) => {

    const [textAreaContent, setTextAreaContent] = useState('');
    const user = useSelector(state => state.user.me.value)
    const [filesToUpload, setFilesToUpload] = useState([]);

    const submitComment = () => {
        addComment(textAreaContent, filesToUpload)
        setTextAreaContent('')
        setFilesToUpload([])
    }

    const onDrop = useCallback(acceptedFiles => {
        var newFilesToUpload = [...filesToUpload]
        acceptedFiles.forEach((file) => {
            var index = newFilesToUpload.findIndex(x => x.name == file.name);
            index === -1 ? newFilesToUpload.push(file) : console.log(`${file.name} item already in list to be uploaded`);
        })
        setFilesToUpload(newFilesToUpload)
    }, [])

    const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
        onDrop: onDrop,
        noClick: true
    })

    return (
        <div className="add-commentSection task-panel-bottom-area" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p style={{ height: "70px" }}>+ Drop files here</p> :
                    <div className='row w-100'>
                        <div className='left-comment-area'>
                            <img className='avatar-image img-fluid' src={user.picture ?? "https://www.tenforums.com/geek/gars/images/2/types/thumb_15951118880user.png"} alt="user" width="50" />
                        </div>
                        <div className='right-comment-area'>
                            <InputGroup>
                                <Textarea
                                    placeholder="Drop attachments here"
                                    className="h-50"
                                    contentEditable
                                    value={textAreaContent}
                                    onKeyDown={(event) => {
                                        // Check if Ctrl + Enter is pressed
                                        if (event.metaKey && event.key === 'Enter') {
                                            event.preventDefault(); // Prevent default behavior
                                            submitComment();   // Call custom action function
                                        }
                                    }}  // Handle keydown event for Ctrl+Enter
                                    onChange={(event) => {
                                        setTextAreaContent(event.target.value);
                                    }}>
                                </Textarea>
                                <Button isDisable={textAreaContent?.length < 2} className="bg-info" onClick={submitComment}>
                                    <Icon icon="send" />
                                </Button>
                            </InputGroup>
                        </div>
                    </div>
            }
            {
                filesToUpload.map((file, ind) => {
                    return <div>{file.name} {file.size} B</div>
                })
            }
        </div>
    )
}