import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useRef, useState } from 'react';
import Button from '../../bootstrap/Button';
import Card, {
    CardBody, CardHeader, CardLabel, CardTitle
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Select from '../../bootstrap/forms/Select';
import Icon from '../../icon/Icon';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import CreatableSelect from 'react-select/creatable';
import { CardActions } from '../../bootstrap/Card';
import Checks from '../../bootstrap/forms/Checks';
import Input from '../../bootstrap/forms/Input';

const defaultRender = (props) => (
    <>
        {props.canvasLayer.children}
        {props.annotationLayer.children}
        {props.textLayer.children}
    </>
);

const isNullOrWhiteSpace = (str) => {
    return (!str || str.length === 0 || /^\s*$/.test(str))
}

export const BasicPDFViewer = ({ attachment, setAttachment, deleteAttachment, row, enabled = false, customText = null, height = "75vh" }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const [isMultipleWatermarks, setIsMultipleWatermarks] = useState(false)
    const [documentViewer, setDocumentViewer] = useState(null);
    const viewer = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfLoaded, setPdfLoaded] = useState(false)
    const [tronInstance, setTronInstance] = useState(null)
    const [pdfInstance, setPdfInstance] = useState(false);
    const [org, setOrg] = useState({})
    const [attachemntExpanded, setAttachemntExpanded] = useState(true)
    const [watermarkTexts, setWatermarkTexts] = useState([])
    const [watermarkText, setWatermarkText] = useState(null)

    const getViewer = (attachment) => {

        const markText = customText ??
            ((attachment.watermarkSrc == 'myOrg') ? org?.name :
                (row?._displaylistItem ? row?._displaylistItem["Fund Name"] :
                    row && row["user_metadata"] && row["user_metadata"]["company_name"] ? row["user_metadata"]["company_name"] : "{{Their Org}}"))

        const getTextAlignmentProps = (attachment) => {
            switch (attachment.watermarloc) {
                case 'center':
                    return {
                        alignItems: 'center',
                        transform: 'rotate(-45deg)',
                    }
                case 'end':
                    return {
                        alignItems: 'end',
                    }
                default:
                    return {
                        alignItems: 'start',
                    }
            }
        }

        const textAlignmentProps = getTextAlignmentProps(attachment)

        const ret = <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
            <div style={{ height: height }}>
                <Viewer
                    renderPage={(props) => (
                        <>
                            {props.canvasLayer.children}
                            <div
                                style={{
                                    padding: '5%',
                                    alignItems: textAlignmentProps?.alignItems,
                                    display: 'flex',
                                    height: '100%',
                                    justifyContent: 'center',
                                    // left: 0,
                                    position: 'absolute',
                                    top: 0,
                                    // width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.2)',
                                        fontSize: `${8 * props.scale}rem`,
                                        fontWeight: 'bold',
                                        transform: textAlignmentProps?.transform,
                                        userSelect: 'none',
                                    }}
                                >
                                    {markText}
                                </div>
                            </div>
                            {props.annotationLayer.children}
                            {props.textLayer.children}
                        </>
                    )}
                    fileUrl={attachment?.url}
                    plugins={[
                        defaultLayoutPluginInstance
                        // Register plugins
                    ]}
                />
            </div>
        </Worker>

        return ret
    }

    const waterMarkLocations = [
        {
            title: "Top",
            value: "start"
        },
        {
            title: "Center",
            value: "center"
        },
        {
            title: "Bottom",
            value: "end"
        }
    ]

    return (
        <Card className="pdf-preview">
            {enabled &&
                <CardHeader>
                    <CardLabel>
                        <CardTitle>
                            <span>
                                <Icon onClick={() => setAttachemntExpanded(!attachemntExpanded)} icon={attachemntExpanded ? 'ChevronDown' : "ChevronRight"} />
                                {attachment?.title}
                            </span>
                        </CardTitle>
                    </CardLabel>
                    <CardActions>
                        <Button icon="XLg"
                            onClick={() => deleteAttachment()}
                        />
                    </CardActions>
                </CardHeader>
            }
            <CardBody style={{ display: attachemntExpanded ? "block" : "none" }} >
                <div >
                    {
                        attachment &&
                        <div className='row g-4' >
                            <div className={`${enabled ? 'col-md-9' : 'col-md-12'} order-2 order-md-1`}>
                                <div className="MyComponent">
                                    {/* <div className="webviewer" ref={viewer} style={{ height: "100vh" }}></div> */
                                        getViewer(attachment)
                                    }
                                </div>
                            </div>
                            {
                                enabled &&
                                <div className='col-md-3 order-1 order-md-2'>
                                    <div className='row g-4' >
                                        <div className='col-md-12'>
                                            <FormGroup
                                                id='watermarkloc'
                                                label='Watermark Location'
                                                isFloating>
                                                <Select
                                                    onChange={e => setAttachment({ ...attachment, watermarloc: e.target.value })}
                                                    value={attachment?.watermarloc}
                                                // invalidFeedback={formik?.errors.firstName}
                                                >
                                                    {
                                                        waterMarkLocations.map((type, i) => (
                                                            <option value={type.value}>{type.title}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </CardBody>
        </Card>
    )
}
