import Img1 from '../../../../assets/img/customerconnect.jpg';
import Img2 from '../../../../assets/img/stamp.jpg';
import Img3 from '../../../../assets/img/scene3.png';
import Img4 from '../../../../assets/img/scene4.png';
import Img5 from '../../../../assets/img/scene5.png';
import Img6 from '../../../../assets/img/scene6.png';
import COLORS from '../../../../common/data/enumColors';

const TAGS = {
	NPM: {
		text: 'PDF',
		color: COLORS.SUCCESS.name,
	},
	YARN: {
		text: 'Bulk Edit',
		color: COLORS.DANGER.name,
	},
	BOOTSTRAP: {
		text: 'Sales',
		color: COLORS.PRIMARY.name,
	},
	DEPENDENCIES: {
		text: 'Deals',
		color: COLORS.INFO.name,
	},
};

export const CATEGORIES = {
	DOCUMENTATION: {
		value: 'documentation',
		text: 'Documentation',
	},
	SETTINGS: {
		value: 'settings',
		text: 'Settings',
	},
	COLORS: {
		value: 'colors',
		text: 'Colors',
	},
};

const data = [
	{
		id: 1,
		title: 'Campaigns',
		description: 'Track your deal from start to close down to the last detail and email.',
		image: Img1,
		link: "/campaigns",
		tags: [TAGS.DEPENDENCIES, TAGS.BOOTSTRAP],
		color: COLORS.WARNING.name,
		categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
		content:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer facilisis faucibus interdum. Donec dapibus fringilla elementum. Pellentesque et quam quis mauris suscipit laoreet. Integer a eleifend magna. Quisque iaculis massa sit amet molestie eleifend. Nunc id finibus massa, vel eleifend turpis. Maecenas interdum neque non neque porta venenatis. Duis nec viverra nisi. Aenean enim nulla, egestas at congue et, vehicula eget sem. Donec molestie bibendum fermentum. Sed tempor, augue sit amet scelerisque vehicula, lacus nunc eleifend tellus, at lobortis felis erat eu dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas libero mi, lacinia a purus sagittis, aliquam fringilla magna. Mauris commodo mollis iaculis. Cras metus mauris, tincidunt ac dapibus in, facilisis vel ipsum.',
	},
	{
		id: 2,
		title: 'PDF Watermarking',
		description: 'A simple utility for quickly adding watermarks (such as your company name) to pdf documents in bulk.',
		image: Img2,
		link: "/dashboard",
		tags: [TAGS.NPM, TAGS.YARN],
		color: COLORS.PRIMARY.name,
		categories: [CATEGORIES.DOCUMENTATION],
		content:
			'Aliquam sodales tempor ullamcorper. Quisque non nibh consequat, dapibus magna et, commodo erat. Proin fringilla nibh mollis, vestibulum dui a, laoreet purus. Aliquam vehicula libero est, ut ornare quam eleifend at. Cras accumsan interdum nulla ut accumsan. Duis ornare, est vel rutrum bibendum, magna odio vehicula sem, non hendrerit dui eros ac erat. Sed et justo ac elit pellentesque ornare sit amet quis magna. Curabitur sagittis, leo pulvinar imperdiet consectetur, libero nisi rhoncus magna, non facilisis tortor mi et felis. Ut aliquet diam at eros faucibus, quis gravida nisl volutpat. Quisque eu nibh orci. Praesent posuere orci ligula, a lacinia mauris venenatis non.',
	},
];

export default data;
