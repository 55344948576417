import { Dots } from 'loading-animations-react';
import { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button from '../../bootstrap/Button';
import { TrackerRowsImport } from '../TrackerRowsImport/TrackerRowsImport';
import * as Yup from 'yup';
import Modal, {
    ModalBody, ModalFooter, ModalHeader, ModalTitle
} from '../../bootstrap/Modal';
import {
    OffCanvasTitle
} from '../../bootstrap/OffCanvas';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../bootstrap/Card';
import UserService from '../../../services/UserService';
import { WizardGenerator } from '../../WizardGenerator/WizardGenerator';
import TrackerSlice, { getTrackers } from '../../../redux/slices/TrackerSlice';
import TrackerService from '../../../services/TrackerService';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NewTrackerColumnsEdit } from '../TrackerColumnsEdit/NewTrackerColumnsEdit';

export const NewTrackerModal = ({ isNewTrackerModalOpen, setIsNewTrackerModalOpen, curProject, callback }) => {

    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [wizardConfig, setWizardConfig] = useState({})
    const navigate = useNavigate();

    const dispatch = useDispatch()


    const onSubmit = async (formData) => {
        const data = formData.rowList ?? []

        const trackerRows = data.map((d, i) => {
            return {
                trackerIndex: i,
                _listItem: {
                    data: d,
                    type: "contact",
                }
            }
        })

        const tracker = {
            title: formData.title,
            description: formData.description,
            _trackerRows: trackerRows,
            _workstreams: formData.workstreams,
        }


        const hydratedTracker = await TrackerService.upsertTracker(tracker)


        // TODO: Add project selection/creation options - default to New Project
        // TODO: Based on template, populate workstreams
        // dispatch
        dispatch(getTrackers())
        navigate(`/campaigns/${hydratedTracker._id}`)
        setIsNewTrackerModalOpen(false)
    }

    useEffect(async () => {
        setIsLoading(false)

        setWizardConfig({
            onSubmit: async (formData) => await onSubmit(formData),
            icon: "Bullseye",
            steps: [
                {
                    cards: [
                        {
                            title: "Add Basic Information About Your New Campaign, Deal, or Project",
                            icon: "Info",
                            fields: [
                                {
                                    validate: Yup.string().required(),
                                    id: "title",
                                    title: "Title",
                                    type: "input",
                                    placeHolder: "i.e. Amazon Acquisition"
                                },
                                {
                                    id: "template",
                                    title: "Campaign Template (Optional)",
                                    type: "select",
                                    init:
                                    {
                                        value: "none",
                                        label: "None"
                                    },
                                    options: [
                                        {
                                            value: "none",
                                            label: "None"
                                        },
                                        {
                                            value: "email1",
                                            label: "Multi-buyer Sale Campaign"
                                        }
                                    ]
                                },
                                {
                                    validate: Yup.string(),
                                    id: "description",
                                    title: "Description",
                                    type: "textarea",
                                }
                            ]
                        }]
                },
                {
                    cards: [
                        {
                            icon: "Groups",
                            iconColor: "warning",
                            title: <a>Add Campaign Contacts (Optional)</a>,
                            fields: [
                                {
                                    id: "rowList",
                                    title: "Contact List",
                                    render: (formik) => {
                                        return <TrackerRowsImport
                                            contactsList={formik.values?.rowList}
                                            updateContactsList={(l) => {
                                                formik.setFieldValue("rowList", l)
                                            }}
                                        />
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    cards: [
                        {
                            icon: "Checklist",
                            iconColor: "success",
                            title: "Add Task Columns (Optional)",
                            fields: [
                                {
                                    id: "workstreams",
                                    title: "Tasks",
                                    render: (formik) => {
                                        return <NewTrackerColumnsEdit
                                            trackerWorkstreams={formik.values?.workstreams ?? []}
                                            updateWorkstreams={(l) => {
                                                formik.setFieldValue("workstreams", l)
                                            }}
                                        />
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        })

    }, []);

    return (
        <Modal
            size='xl'
            fullScreen='xxl'
            setIsOpen={setIsNewTrackerModalOpen}
            isOpen={isNewTrackerModalOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable>
            <ModalHeader setIsOpen={setIsNewTrackerModalOpen}>
                <OffCanvasTitle id='upcomingEdit'>Create A New Campaign</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                {
                    isLoading ?
                        <PageWrapper className='justify-content-center text-center' title='Tasks'>
                            <div className='justify-content-center text-center' title='Tasks'>
                                <Dots></Dots>
                            </div>
                        </PageWrapper>
                        : <>
                            <WizardGenerator wizardObject={wizardConfig} />
                        </>
                }
            </ModalBody>
        </Modal>
    )
}