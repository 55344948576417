import React, { useEffect, useState } from 'react'
import FileService from '../../../services/FileService'
import moment from 'moment'
import Spinner from '../../../components/bootstrap/Spinner'
import Icon from '../../../components/icon/Icon'

export const TrackerBoardPanelFilesSection = ({ focusTask, updateFocusTask, fileClickHandler }) => {

    const [files, setFiles] = useState([])

    const getStatusIcon = (file) => {
        switch (file?.status) {
            case "processing":
                return <Spinner size='sm' color="info" />
            case "failed":
                return <Icon icon='XCircleFill' color="danger" />
            case "ready":
                return <Icon icon='CheckCircleFill' color="success" />
            default:
                return <Icon icon='HourglassEmpty' />
        }
    }

    useEffect(async () => {
        const f = focusTask?.files?.length > 0 ? await FileService.getFileInfo(focusTask?.files) : []
        setFiles(f)
    }, [focusTask?._id, focusTask?.files])

    return (
        <>
            <div className="border-bottom h2 mt-4">
                Files ({files.length})
            </div>
            <div className="file-section">
                {files.map(f => (
                    <div className='row' key={f._id}>
                        <div className='col-9'>
                            <div className="file-title hover-underline cursor-pointer" onClick={
                                f.status === "ready" ? () => fileClickHandler(f) : null
                            }>
                                {getStatusIcon(f)} {f.title}
                            </div>
                        </div>
                        <div className='col-3'>
                            {
                                moment(f.createdAt).fromNow()
                            }
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
