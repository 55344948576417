import React, { useState } from 'react'
import { templates } from './sample_tracker_tasks'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Select from '../../bootstrap/forms/Select'
import Button from '../../bootstrap/Button'

export const TrackerWorkstreamTemplateSelect = ({ additionalTemplateOptions = [], updateWorkstreams }) => {

    const allOptions = [...templates, ...additionalTemplateOptions]

    const applyTemplate = (template) => {
        updateWorkstreams(template.workstreams)
    }


    return (
        <div className='row'>
            <div className='col-10'>
                <FormGroup label="Import tasks from template" >
                    <Select
                        value={-1}
                        onChange={e => {
                            if (e.target.value === "-1") return

                            const t = allOptions[e.target.value]
                            applyTemplate(t)
                        }}
                    >
                        <option value={-1}></option>
                        {
                            allOptions?.map((o, i) => (
                                <option value={i}>{o.title} ({o.workstreams.length} tasks)</option>
                            ))
                        }
                    </Select>
                </FormGroup>
            </div>
            <div className='col-2 d-flex flex-column'>
                <Button className="mt-auto" color='danger' icon='XLg' onClick={() => {
                    updateWorkstreams([])
                }
                }>
                    Clear
                </Button>
            </div>
        </div>
    )
}
