import { useAuth0, } from "@auth0/auth0-react";
import { Dots } from 'loading-animations-react';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import EmailService from '../../services/EmailService';
import Card, {
    CardBody, CardHeader, CardLabel, CardTitle,
} from '../bootstrap/Card';
import Select from '../bootstrap/forms/Select';
import { TrackerBoardEmailBlastOtherAttachment } from './TrackerBoardEmailBlastOtherAttachment';
import { TrackerBoardEmailBlastEmailPDFAttachment } from './TrackerBoardEmailBlastEmailPDFAttachment';
import { useSelector } from "react-redux";
import { isNullOrEmpty } from '../../services/Common';

export const TrackerBoardEmailBlastEmailReview = ({ email, emailBody, recipients }) => {

    const me = useSelector((state) => state.user?.me);

    const [isLoading, setIsLoading] = useState(false);
    const [rowIndex, setRowIndex] = useState(0)

    const selectedItem = recipients?.length > 0 ? recipients[rowIndex] : {}

    var convertedEmail = {}
    if (recipients?.length > 0) {
        convertedEmail = EmailService.fillTemplate({ ...email, body: emailBody }, selectedItem, me, { display_name: "Fresler LLC" })
    }


    return (
        isLoading ?
            <PageWrapper className="justify-content-center text-center" title="Tasks">
                <div className=" justify-content-center text-center" title="Tasks">
                    <Dots></Dots>
                </div>
            </PageWrapper>
            : !(recipients?.length > 0) ?
                <PageWrapper className="justify-content-center text-center" title="Tasks">
                    <p class="lead fw-bold">No Recipients Selected</p>
                </PageWrapper> :
                <>
                    <Card>
                        <CardHeader>
                            <CardLabel icon='ExclamationTriangleFill' iconColor="danger">
                                <CardTitle>You are about to send ({recipients?.length}) emails. Validate the content here.</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p className='lead fw-bold'>Email</p>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>Customer:</strong> </div>
                                </div>
                                <div className='col-md-12'>
                                    <div style={{ paddingTop: "5px" }}>
                                        <Select
                                            value={rowIndex}
                                            onChange={e => setRowIndex(e.target.value)}>
                                            {
                                                recipients?.map((row, i) => {
                                                    const fundName = row?.["Fund Name"]
                                                    const name = EmailService.getName(row)
                                                    const title = isNullOrEmpty(name) ? fundName : `${fundName} (${name})`

                                                    return <option value={i}>{title}</option>
                                                }) ?? []
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className='col-md-12'>
                                    {recipients?.length > 0 && <div ><strong>To: </strong>{selectedItem["Contact Name"]} ({selectedItem["Contact Email"]}) </div>}
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>Subject: </strong>{convertedEmail?.subject}</div>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>From: </strong>John Smith ({convertedEmail?.fromEmail}@finspace.app)</div>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>ReplyTo: </strong>John Smith ({me?.email})</div>
                                </div>
                                <div className='col-md-12'>
                                    <div ><strong>CC: </strong>{convertedEmail?.cc}</div>
                                </div>
                                <br />
                                <div className='col-md-12 mb-3'>
                                    <div ><strong>Body:</strong> </div>
                                </div>
                                <br />
                                <div className='col-md-12'>
                                    <div >
                                        <ReactQuill
                                            value={convertedEmail?.body}
                                            onChange={() => { }}
                                            readOnly={true}
                                            modules={{ toolbar: false }}
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                            </div>
                        </CardBody>
                    </Card>
                    {
                        convertedEmail?.attachments?.map((attachment, i) => {
                            switch (attachment?.type) {
                                case "PDF":
                                    return <TrackerBoardEmailBlastEmailPDFAttachment
                                        isEditEnabled={false}
                                        attachment={attachment}
                                        setAttachment={(a) => { }}
                                        deleteAttachment={() => { }}
                                        contact={selectedItem}
                                    />
                                    break;
                                default:
                                    return <TrackerBoardEmailBlastOtherAttachment
                                        isEditEnabled={false}
                                        attachment={attachment}
                                        setAttachment={(a) => { }}
                                        deleteAttachment={() => { }}
                                        contact={selectedItem}
                                    />
                                    break;
                            }
                        })
                    }
                </>
    )
}
