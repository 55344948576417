import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import Avatar, { AvatarGroup } from '../Avatar';

const AvatarTeam = ({ size = 32, children, isAlignmentEnd, members, updateMembers, onEditClick }) => {

    const [isLoading, setisLoading] = useState(true);
    useEffect(async () => {
        setisLoading(true)
        // await UserService.initialize()
        setisLoading(false)
    }, []);

    return (
        isLoading ? <></> :
            <>
                {children && !isAlignmentEnd && <span className='me-3'>
                    {children}
                </span>}
                <AvatarGroup
                    size={size}
                    members={members}
                    updateMembers={updateMembers}
                    onEditClick={onEditClick}>
                    {
                        (members ?? []).map((member, i) => {
                            var resolvedMember = UserService.getUser(member)
                            return <Avatar
                                key={i}
                                src={resolvedMember?.picture}
                                srcSet={resolvedMember?.picture}
                                userName={resolvedMember?.name}
                            />
                        })
                    }
                </AvatarGroup>
                {children && isAlignmentEnd && <span>{children}</span>}
            </>
    );
};

AvatarTeam.propTypes = {
    children: PropTypes.node,
    isAlignmentEnd: PropTypes.bool,
};

AvatarTeam.defaultProps = {
    children: null,
    isAlignmentEnd: false,
};

export default memo(AvatarTeam);
