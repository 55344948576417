import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import a4Logger from '../../services/a4Logger';
import { templateOptions } from './templateOptions';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '../bootstrap/Button';
import Card, {
    CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle
} from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Select from '../bootstrap/forms/Select';
import Label from '../bootstrap/forms/Label';
import InputGroup, { InputGroupText } from '../bootstrap/forms/InputGroup';
import Popovers from '../bootstrap/Popovers';
import UserService from '../../services/UserService';
import FileService from '../../services/FileService';
import Icon from '../icon/Icon';
import { TrackerBoardEmailBlastOtherAttachment } from './TrackerBoardEmailBlastOtherAttachment';
import { TrackerBoardEmailBlastEmailPDFAttachment } from './TrackerBoardEmailBlastEmailPDFAttachment';
import { FileDropPad } from '../Files/FileDropPad';

export const TrackerBoardEmailBlastEmailEdit = ({
    email, updateEmail,
    emailBody, updateEmailBody,
}) => {

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image', 'video', 'formula'],          // add's image support
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    const me = UserService.getMe()
    const [isLoading, setIsLoading] = useState(false);

    const startFileAdd = async (files) => {

        const fileProms = Object.keys(files).map(async (key, index) => {

            const file = files[key]
            var fileExtRe = /(?:\.([^.]+))?$/;
            var matches = fileExtRe.exec(file.name);
            if (matches.length < 1) {
                throw Error('File Name is invalid and cannot determine extension')
            }
            const extension = matches[1]
            var type = "Other"
            switch (extension) {
                case 'pdf':
                    type = "PDF"
                    break;
                case 'ppt':
                case 'pptx':
                    type = "PowerPoint"
                    break;
                default:
                    type = "Other"
                    break;
            }

            const url = await (FileService.toString(file))

            const newF = {
                'title': file.name,
                'description': '',
                'file': file,
                'type': type,
                'url': url
            }

            return newF
        });

        const newFiles = await Promise.all(fileProms)
        updateEmail({
            ...email, attachments: [...(email?.attachments || []), ...newFiles]
        })
        return false
    }

    const deleteAttachment = (i) => {

        if (i > -1) { // only splice array when item is found
            email.attachments.splice(i, 1); // 2nd parameter means remove one item only
        }

        const newAttachments = [...email.attachments]
        updateEmail({ ...email, attachments: newAttachments })
    }

    return (
        email ?
            <>
                <Card >
                    <CardHeader>
                        <CardLabel>
                            <CardTitle>Email Content</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-4'>
                            <div className='col-md-12'>
                                <Label htmlFor='Template'>Template</Label>
                                <Select
                                    placeholder='Select Email Template'
                                    onChange={(e) => {
                                        {
                                            const t = templateOptions.find(x => x?.title == e.target.value)
                                            updateEmailBody(t?.body)
                                            updateEmail({ ...email, body: t.body, subject: t.subject })
                                        }
                                    }}
                                >
                                    {
                                        templateOptions.map((template, option) => (
                                            <option value={template?.title}>{template?.title}</option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className='col-md-12'>
                                <Label htmlFor='Subject'>Subject</Label>
                                <Input
                                    contentEditable
                                    placeholder='Subject'
                                    onChange={e => updateEmail({ ...email, subject: e.target.value })}
                                    value={email.subject}
                                />
                            </div>
                            <div className='col-md-12'>
                                <Label htmlFor='CC'>CC</Label>
                                <Input
                                    contentEditable
                                    placeholder='i.e. jim@a.com; jane@b.com'
                                    onChange={e => updateEmail({ ...email, cc: e.target.value })}
                                    value={email.cc}
                                />
                            </div>
                            <div className='col-md-6'>
                                <FormGroup>
                                    <Label htmlFor='From alias'><span>
                                        <Popovers
                                            className="me-2"
                                            title='Customize the sender alias'
                                            desc={`This alias, '${email?.fromEmail}' and your name '${me?.name}' will be the email sender. However, when recipients reply to the email, they will reply to '${me?.email}'`}
                                            trigger='hover'
                                            delay={1000}>
                                            <Icon icon="InfoCircle">
                                            </Icon>
                                        </Popovers >From Email Address</span></Label>
                                    <InputGroup>
                                        <Input
                                            id='exampleMailAddress'
                                            placeholder="i.e ProjectEfficient"
                                            aria-label="From alias"
                                            autoComplete='email'
                                            ariaDescribedby='addon2'
                                            onChange={e => updateEmail({ ...email, fromEmail: e.target.value })}
                                            value={email.fromEmail}
                                        />
                                        <InputGroupText id='addon2'>
                                            @finspace.app
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className='col-md-12'>
                                <div>
                                    <ReactQuill
                                        modules={
                                            {
                                                toolbar: toolbarOptions
                                            }
                                        }
                                        value={emailBody || ""}
                                        onChange={e => {
                                            updateEmailBody(e)
                                        }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            email?.attachments?.map((attachment, i) => {
                                switch (attachment?.type) {
                                    case "PDF":
                                        return <TrackerBoardEmailBlastEmailPDFAttachment
                                            attachment={attachment}
                                            setAttachment={(a) => {
                                                const newAttachments = [...email?.attachments]
                                                newAttachments[i] = a
                                                updateEmail({ ...email, attachments: newAttachments })
                                            }}
                                            deleteAttachment={() => deleteAttachment(i)}
                                        />
                                        break;
                                    default:
                                        return <TrackerBoardEmailBlastOtherAttachment
                                            attachment={attachment}
                                            setAttachment={(a) => {
                                                const newAttachments = [...email?.attachments]
                                                newAttachments[i] = a
                                                updateEmail({ ...email, attachments: newAttachments })
                                            }}
                                            deleteAttachment={() => deleteAttachment(i)}
                                        />
                                        break;
                                }
                            })
                        }
                    </CardBody >
                    <CardFooter>
                        <CardFooterLeft>
                            <div className='row'>
                                <div className='col-auto'>
                                    <FileDropPad startFileAdd={startFileAdd} />
                                </div>
                                <div className='col-auto'>
                                    Size limit (20MB)
                                </div>
                            </div>
                        </CardFooterLeft>
                        <CardFooterRight>
                            <Button
                                icon='Save'
                                color='info'
                                size='lg'
                            >
                                Save Email Template
                            </Button>
                        </CardFooterRight>
                    </CardFooter>
                </Card>
            </> : <></>
    )
}
