import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import user6 from '../../assets/img/user6.jpeg';
import Auth0ClientService from '../../services/Auth0ClientService';
import UserService from '../../services/UserService';
import a4Logger from '../../services/a4Logger';
import { getAppInsights } from '../../services/AppInsights/TelemetryService';
// import { store } from '../store';
import { getFileJobs } from './FileJobSlice';
import { getTrackers } from './TrackerSlice';
// import { useDispatch } from 'react-redux';

export const getMe = createAsyncThunk('users/getUsers', async (_, { getState, dispatch }) => {

    try {

        a4Logger.appInsights = getAppInsights();
        const tempUserId = sessionStorage.getItem("USERID");
        var user = await Auth0ClientService.getUser()
        a4Logger.logTrace("User retrieved from auth0", { user: user })

        if (user) {
            a4Logger.logTrace("User is not null")
            var userData = await UserService.getMyUserData()
            user = { ...user, userData: userData }
            a4Logger.logTrace("User data retrieved", { user: user })
            UserService.me = user
        }
        else {
            a4Logger.logTrace("User is null")
            await UserService.initialize()
            user = UserService.getMe()
            a4Logger.logTrace("User retrieved from user service", { user: user })
        }

        // Merge users if old user is a temporary user and current user is not
        if (!user?.isFalseUser && tempUserId && tempUserId !== user.user_id) {
            a4Logger.logTrace(`Merging users ${tempUserId} and ${user.user_id}`)
            await UserService.mergeUsers(tempUserId)
            a4Logger.logTrace(`Merged users`)
            sessionStorage.removeItem("USERID");
        }

        a4Logger.logTrace("User retrieved", { user: user })
        a4Logger.user_id = user?.user_id

        const promises = [dispatch(getFileJobs()), dispatch(getTrackers())]
        await Promise.all(promises)

        return user
    }
    catch (err) {
        a4Logger.logTrace("User login failed", err.message)
        a4Logger.logError(err)
        return null
    }
})

export const updateUserData = createAsyncThunk('users/updateData', async (propertyNameValue, thunkAPI) => {

    const state = thunkAPI.getState();
    var user = state.user.me.value;
    var userData = { ...user.userData, ...propertyNameValue }
    await UserService.setMyUserData(userData)

    return userData
})

export const incrementModelCount = createAsyncThunk('users/incrementModelCount', async (_, thunkAPI) => {

    const state = thunkAPI.getState();
    var modelCount = state.user.me.value.userData.numModelsCreated;
    const userData = await thunkAPI.dispatch(updateUserData({ numModelsCreated: modelCount + 1 }));

    return userData
})

const initialState = {
    me: {
        value: {
            email: '',
            name: 'Guest',
            picture: user6,
            isFalseUser: true
        },
        loading: false,
        error: null
    },
    users: {
        value: {},
        loading: false,
        error: null
    },
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            Auth0ClientService.login()
        },
        logoutUser: (state, action) => {
            Auth0ClientService.logout()
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMe.pending, (state) => {
                state.me.loading = true
                state.me.error = null
            })
            .addCase(getMe.fulfilled, (state, action) => {
                state.me.loading = false
                state.me.value = action.payload
            })
            .addCase(getMe.rejected, (state, action) => {
                state.me.loading = false
                state.me.error = action.error.message
            })

            .addCase(updateUserData.fulfilled, (state, action) => {
                state.me.loading = false
                var userData = action.payload
                state.me.value.userData = userData
            })
            .addCase(updateUserData.rejected, (state, action) => {
                state.me.loading = false
                state.me.error = action.error.message
            })
    },
})

// Action creators are generated for each case reducer function
export const { selectFocusUser } = UserSlice.actions
export const selectUsers = (state) => state.user.me.value
export const selectLoading = (state) => state.user.me.loading
export const selectError = (state) => state.user.me.error
export default UserSlice.reducer