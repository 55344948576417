import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import USERS from '../../../common/data/userDummyData';
import Avatar, { AvatarGroup } from '../../../components/Avatar';
import Badge from '../../../components/bootstrap/Badge';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Progress from '../../../components/bootstrap/Progress';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import { demoPages } from '../../../menu';
import { getNumEmails, getNumFiles, getPercentageComplete, getBlockedTrackerRows, getDueDateString, getRemainingTrackerRows } from './TrackerListDashboardListHelper';
import Tooltips from '../../../components/bootstrap/Tooltips';
import Button from '../../../components/bootstrap/Button';

// eslint-disable-next-line react/prop-types
export const TrackerListDashboardTrackerItem = ({ tracker, ...props }) => {

	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	const handleOnClickToProjectPage = useCallback(
		() => navigate(`../campaigns/${tracker?._id}`),
		[navigate],
	);

	const dueDateStr = 'Due 12/31/2021'; // TODO: Calculate this from provided due date or say how long it's been active 

	const percentageComplete = Math.floor(Math.random() * 100) + 1; // TODO: getPercentageComplete(tracker);
	const numEmails = getNumEmails(tracker);
	const numFiles = getNumFiles(tracker);
	const numRemainingRows = getRemainingTrackerRows(tracker);
	const numBlockedRows = getBlockedTrackerRows(tracker);

	const isLate = tracker?.dueDate && new Date(tracker?.dueDate) < new Date();
	const isDueSoon = tracker?.dueDate && new Date(tracker?.dueDate) < new Date(new Date().setDate(new Date().getDate() + 7));
	const dueDateColor = isLate ? 'danger' : (isDueSoon ? 'warning' : 'success');

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className='col-md-4' {...props}>
			<Card stretch onClick={handleOnClickToProjectPage} className='cursor-pointer'>
				<CardHeader>
					<CardLabel icon='Ballot'>
						<CardTitle>{tracker?.title}</CardTitle>
						{/* <CardSubTitle>{teamName}</CardSubTitle> */}
					</CardLabel>
					<CardActions>
						{
							tracker?.status === 'Complete' ? (
								<Badge color='success' isLight>
									<Icon icon='Check' />
								</Badge>
							) : tracker?.dueDate &&
							<small className={`border border-${dueDateColor} border-2 text-success fw-bold px-2 py-1 rounded-1`}>
								{
									tracker?.dueDate ? getDueDateString(tracker?.dueDate) : dueDateStr

								}
							</small>
						}
					</CardActions>
				</CardHeader>
				<CardBody>
					<div className='row g-2 mb-3 d-flex align-items-center'>
						<div className='col-auto'>
							<Tooltips title={`${numRemainingRows} potential customers remaining`}>
								<Button
									icon='Sync'
									color='success'
									isLight>
									{numRemainingRows}
								</Button>
							</Tooltips>
						</div>
						{
							numBlockedRows > 0 &&
							<div className='col-auto'>
								<Tooltips title={`${numBlockedRows} blocked potential customers`}>
									<Button
										icon='Block'
										color='danger'
										isLight>
										{numBlockedRows}
									</Button>
								</Tooltips>
							</div>
						}
						<div className='col-auto'>
							<Tooltips title={`${numFiles} files attached`}>

								<Button
									icon='AttachFile'
									color='info'
									isLight>
									{numFiles}
								</Button>
							</Tooltips>
						</div>
						{
							numBlockedRows > 0 &&
							<div className='col-auto'>

								<Tooltips title={`${numEmails} emails sent`}>
									<Button
										icon='Email'
										color='warning'
										isLight>
										{numEmails}
									</Button>
								</Tooltips>
							</div>
						}
					</div>
					<div className='row'>
						<div className='col-md-6'>
							<div className='fw-bold text-left'>{percentageComplete}%</div>
							<Progress isAutoColor value={percentageComplete} height={10} />
						</div>
						<div className='col-md-6 d-flex justify-content-end'>
							<AvatarGroup>
								<Avatar
									srcSet={USERS.GRACE.srcSet}
									src={USERS.GRACE.src}
									userName={`${USERS.GRACE.name} ${USERS.GRACE.surname}`}
									color={USERS.GRACE.color}
								/>
								<Avatar
									srcSet={USERS.SAM.srcSet}
									src={USERS.SAM.src}
									userName={`${USERS.SAM.name} ${USERS.SAM.surname}`}
									color={USERS.SAM.color}
								/>
								<Avatar
									srcSet={USERS.CHLOE.srcSet}
									src={USERS.CHLOE.src}
									userName={`${USERS.CHLOE.name} ${USERS.CHLOE.surname}`}
									color={USERS.CHLOE.color}
								/>

								<Avatar
									srcSet={USERS.JANE.srcSet}
									src={USERS.JANE.src}
									userName={`${USERS.JANE.name} ${USERS.JANE.surname}`}
									color={USERS.JANE.color}
								/>
								<Avatar
									srcSet={USERS.JOHN.srcSet}
									src={USERS.JOHN.src}
									userName={`${USERS.JOHN.name} ${USERS.JOHN.surname}`}
									color={USERS.JOHN.color}
								/>
								<Avatar
									srcSet={USERS.RYAN.srcSet}
									src={USERS.RYAN.src}
									userName={`${USERS.RYAN.name} ${USERS.RYAN.surname}`}
									color={USERS.RYAN.color}
								/>
							</AvatarGroup>
						</div>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};