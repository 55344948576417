import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import TrackerService from '../../services/TrackerService'
import { getDefaultSliceExtraReducers } from './Common';
import TaskService from '../../services/TaskService';
import { hashStrings } from '../../pages/trackers/TrackerPage/TrackerBoardUtils';

export const getTrackers = createAsyncThunk('trackers/getTrackers', async () => {
    const trackers = await TrackerService.getTrackers();
    return trackers
})

export const upsertTrackers = createAsyncThunk('trackers/upsertTrackers', async (trackers) => {
    const hydratedItems = await TrackerService.upsertTrackers(trackers);
    return [...trackers]
})

export const deleteTracker = createAsyncThunk('trackers/deleteTracker', async (trackerId) => {
    const item = await TrackerService.deleteTracker(trackerId);
    return item?._id
})

export const getTrackerTasks = createAsyncThunk('trackers/getTrackerTasks', async (trackerId) => {
    const tasks = await TaskService.getTrackerTasks(trackerId);
    return { tasks: tasks, _id: trackerId }
})

export const updateTrackerTasks = createAsyncThunk('trackers/updateTrackerTasks', async ({ tasks, saveToDB = true }) => {
    // TODO: update this to only send the change
    console.log("updateTrackerTasks", tasks, saveToDB)
    if (saveToDB) {
        await TaskService.upsertTasks(tasks);
    }
    return tasks
})

export const getTaskById = createAsyncThunk('trackers/getTaskById', async (taskId) => {
    const tasks = await TaskService.getTasksById(taskId);
    return tasks
})

const initialState = {
    state: { // The list of all trackers for the current user
        value: [],
        loading: false,
        error: null
    },
    curTrackerTasks: { // The tasks for the currently selected tracker
        id: null,
        value: {},
        focus: null,
        loading: false,
        error: null
    },
    focusTask: { // The task being drilled into (usually via the task panel)
        isFocusPanelOpen: false,
        trackerRow: null,
        workstream: null,
    }
}

const convertTrackerTasksToMap = (tasks) => {
    const map = {}
    tasks.forEach(task => {
        const aiId = hashStrings(task.trackerRow, task.workstream)
        map[aiId] = { ...task, drilledDown: false }
    })
    return map
}

export const TrackerSlice = createSlice({
    name: 'tracker',
    initialState,
    reducers: {
        setFocusPanelOpen: (state, action) => {
            state.focusTask.isFocusPanelOpen = action.payload
        },
        selectFocusWorkItem: (state, action) => {

            state.focusTask.trackerRow = action.payload.trackerRow
            state.focusTask.workstream = action.payload.workstream
        },
    },
    extraReducers: (builder) => {
        const b = getDefaultSliceExtraReducers(getTrackers, upsertTrackers, deleteTracker)
        b(builder)

        builder
            .addCase(getTrackerTasks.pending, (state) => {
                state.curTrackerTasks.loading = true
                state.curTrackerTasks.error = null
            })
            .addCase(getTrackerTasks.fulfilled, (state, action) => {
                const { tasks, _id } = action.payload
                state.curTrackerTasks.id = _id
                const map = convertTrackerTasksToMap(tasks)

                state.curTrackerTasks.value = map
                state.curTrackerTasks.loading = false
            })
            .addCase(getTrackerTasks.rejected, (state, action) => {
                state.state.loading = false
                state.state.error = action.error.message
            })
        builder
            .addCase(updateTrackerTasks.pending, (state) => {
                state.curTrackerTasks.loading = true
                state.curTrackerTasks.error = null
            })
            .addCase(updateTrackerTasks.fulfilled, (state, action) => {
                const newTasks = action.payload
                newTasks.forEach(newTask => {
                    const aiId = hashStrings(newTask.trackerRow, newTask.workstream)
                    state.curTrackerTasks.value[aiId] = newTask
                })
                state.curTrackerTasks.loading = false
            })
            .addCase(updateTrackerTasks.rejected, (state, action) => {
                state.curTrackerTasks.loading = false
                state.curTrackerTasks.error = action.error.message
            })
        builder
            .addCase(getTaskById.pending, (state) => {
                state.curTrackerTasks.loading = true
                state.curTrackerTasks.error = null
            })
            .addCase(getTaskById.fulfilled, (state, action) => {
                const tasks = action.payload
                tasks.forEach(task => {
                    if (task.tracker == state.curTrackerTasks.id) {
                        const aiId = hashStrings(newTask.trackerRow, newTask.workstream)
                        state.curTrackerTasks.value[aiId] = { ...task, drilledDown: true }
                    }
                })
                state.curTrackerTasks.loading = false
            })
            .addCase(getTaskById.rejected, (state, action) => {
                state.curTrackerTasks.loading = false
                state.curTrackerTasks.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const { setFocusPanelOpen, selectFocusWorkItem } = TrackerSlice.actions
export const selectTrackers = (state) => state.tracker.items.value
export const selectLoading = (state) => state.tracker.items.loading
export const selectError = (state) => state.tracker.items.error
export default TrackerSlice.reducer