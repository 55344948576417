import React from 'react'
import FormGroup from '../bootstrap/forms/FormGroup'
import Label from '../bootstrap/forms/Label'
import CreatableSelect from 'react-select/creatable';
import Textarea from '../bootstrap/forms/Textarea';
import Input from '../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import { Check } from '../icon/bootstrap';
import Select from '../bootstrap/forms/Select';
import AvatarTeam from '../AvatarTeam/AvatarTeam';
import { AvatarMemberInput } from '../AvatarTeam/AvatarGroupMemberModal/AvatarMemberInput';
import './WizardGenerator.css'

export const CreateSelect = ({ fieldObject, formik }) => {

    const id = fieldObject?.id
    const options = fieldObject?.options
    const updateValue = fieldObject?.updateValue

    return (
        <div className='col-md-12'>
            <Label htmlFor={id}>{fieldObject?.title}</Label>
            <FormGroup
                id={id}
                isFloating>
                <CreatableSelect
                    // styles={creatableStyle}
                    noOptionsMessage={() => 'Type to create a new option'}
                    isDisabled={fieldObject?.disabled}
                    options={options}
                    minMenuHeight={10}
                    onChange={(newValue, actionMetaData) => {
                        formik.setFieldValue(id, newValue)
                        if (updateValue) {
                            updateValue(newValue, formik)
                        }
                    }}
                    form="false"
                    value={formik.values[id]}
                    formatCreateLabel={inputValue => `Press enter to create '${inputValue}'`}
                />
            </FormGroup>
        </div>
    )
}

export const CreateSelectMulti = ({ fieldObject, formik }) => {

    const id = fieldObject?.id
    const options = fieldObject?.options
    const addOptions = fieldObject?.addOptions
    const updateValue = fieldObject?.updateValue

    return (
        <div className='col-md-12'>
            <Label htmlFor={id}>{fieldObject?.title}</Label>
            <FormGroup
                id={id}
                isFloating>
                <CreatableSelect
                    // styles={creatableStyle}
                    isDisabled={fieldObject?.disabled}
                    options={options}
                    isMulti
                    minMenuHeight={10}
                    onChange={(newValue, actionMetaData) => {
                        if (actionMetaData.action == 'create-option') {
                            const newVal = newValue[newValue.length - 1]

                            // Only save a tag if it is not already an option
                            if (addOptions && !(options.some(x => x.value == newVal))) {
                                const newTag = { ...newVal, _id: newVal.value }
                                addOptions([newTag])
                            }
                        }

                        formik.setFieldValue(id, newValue)
                        if (updateValue) {
                            updateValue(newValue, formik)
                        }
                    }}
                    form="false"
                    value={formik.values[id]}
                    formatCreateLabel={inputValue => fieldObject?.createLabel ? `${fieldObject?.createLabel} '${inputValue}'` : `Press enter to add '${inputValue}'`}
                />
            </FormGroup>
        </div>
    )
}

export const SliderField = ({ fieldObject, formik }) => {

    const id = fieldObject?.id

    return (
        <div className='col-12'>
            <FormGroup
                id={id}
                label={`${fieldObject?.title} (${formik.values[id]}%)`}>
                <Input
                    disabled={fieldObject?.disabled}
                    type='range'
                    onChange={formik.handleChange}
                    value={formik.values[id]}
                />
            </FormGroup>
        </div>
    )
}

export const TextAreaField = ({ fieldObject, formik }) => {

    const id = fieldObject?.id

    return (
        <div className='col-12'>
            <FormGroup id={id} label={fieldObject?.title} isFloating>
                <Textarea
                    disabled={fieldObject?.disabled}
                    placeholder='Add details here'
                    style={{ height: "80px" }}
                    rows={fieldObject?.rows ?? 4}
                    onChange={formik.handleChange}
                    value={formik.values[id]}
                // validFeedback='Looks good!'
                />
            </FormGroup>
        </div>
    )
}

export const InputField = ({ fieldObject, formik }) => {

    const id = fieldObject?.id
    const updateValue = fieldObject?.updateValue
    const subType = fieldObject?.subType ?? "text"

    return (
        <div className='col-md-12'>
            <FormGroup id={id} label={fieldObject?.title} isFloating>
                <Input
                    type={subType}
                    disabled={fieldObject?.disabled}
                    placeholder={fieldObject?.placeHolder ?? fieldObject?.title}
                    onChange={e => {
                        formik.handleChange(e)
                        if (updateValue) {
                            updateValue(e.target.value)
                        }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values[id]}
                    isValid={formik.isValid}
                    isTouched={formik.touched[id]}
                    invalidFeedback={formik.errors[id]}
                // validFeedback='Looks good!'
                />
            </FormGroup>
        </div>
    )
}


export const SelectField = ({ fieldObject, formik }) => {

    const id = fieldObject?.id
    const options = fieldObject?.options
    const updateValue = fieldObject?.updateValue

    return (
        <div className='col-md-12'>
            <FormGroup id={id} label={fieldObject?.title} isFloating>
                <Select
                    disabled={fieldObject?.disabled}
                    onChange={e => {
                        const v = options?.find(o => o.value === e.target.value)
                        formik.setFieldValue(id, v)
                        if (updateValue) {
                            updateValue(v)
                        }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values[id]?.value}
                    isValid={formik.isValid}
                    isTouched={formik.touched[id]}
                    invalidFeedback={formik.errors[id]}
                >
                    {
                        options?.map((o, i) => (
                            <option value={o.value}>{o.label}</option>
                        ))
                    }
                </Select>
            </FormGroup>
        </div>
    )
}


export const CheckInput = ({ fieldObject, formik }) => {

    const id = fieldObject?.id

    return (
        <div className='col-12'>
            <FormGroup id={id} isFloating>
                <Checks
                    key={id}
                    id={id}
                    name='checks'
                    label={fieldObject?.placeHolder ?? fieldObject?.title}
                    value={id}
                    onChange={formik.handleChange}
                    checked={formik.values?.checks?.includes(id)}
                />
            </FormGroup>
        </div>
    )
}

export const UserSelect = ({ fieldObject, formik }) => {

    const id = fieldObject?.id
    const userOptions = fieldObject?.options ?? []
    const members = formik.values[id]

    return (
        <div className='col-md-12'>
            <Label htmlFor={id}>{fieldObject?.title}</Label>
            <FormGroup
                id={id}
                isFloating
            >
                <div className='user-select-div'>
                    <div className='row'>
                        {
                            members?.length > 0 &&
                            <div className='col-auto'>
                                <AvatarTeam
                                    members={members}
                                />
                            </div>

                        }
                        <div className={members?.length > 0 ? 'col-8' : 'pl-0'} >
                            <AvatarMemberInput
                                options={userOptions}
                                updateMembers={(newValue) => formik.setFieldValue(id, newValue)} />
                        </div>
                    </div>
                </div>
            </FormGroup>
        </div>
    )
}

export const WizardField = ({ fieldObject, formik }) => {
    var field = <></>
    const isVisible = fieldObject?.visible || (() => true)

    if (isVisible(formik.values)) {
        switch (fieldObject?.type) {
            case 'create-select':
                field = <CreateSelect fieldObject={fieldObject} formik={formik} />
                break;
            case 'create-select-multi':
                field = <CreateSelectMulti fieldObject={fieldObject} formik={formik} />
                break;
            case 'select':
                field = <SelectField fieldObject={fieldObject} formik={formik} />
                break;
            case 'input':
                field = <InputField fieldObject={fieldObject} formik={formik} />
                break;
            case 'textarea':
                field = <TextAreaField fieldObject={fieldObject} formik={formik} />
                break;
            case 'check':
                field = <CheckInput fieldObject={fieldObject} formik={formik} />
                break;
            case 'user-select':
                field = <UserSelect fieldObject={fieldObject} formik={formik} />
                break;
            case 'slider':
                field = <SliderField fieldObject={fieldObject} formik={formik} />
                break;
            default:
                if (fieldObject?.render) {
                    field = fieldObject?.render(formik)
                }
                break;
        }
    }

    return (field)
}

export const generateField = (f, formik) => {
    return <WizardField fieldObject={f} formik={formik} />
}
