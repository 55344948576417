import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { GripVertical } from "react-bootstrap-icons";
import { Form } from 'react-bootstrap';
import './FreslerTable.css';
import { FreslerTableDataAddNewRow } from './FreslerTableDataAddNewRow';
import { checkCellWidth, dragHandleCellWidth, getColumnSortFunction, getRowWidth } from './FreslerTableUtilities';

export const FreslerDataViewGrouped = ({
    numRows, cols,
    sortedData, accessRowItem, updateItem, updateData, updateItemSet, renderDataItem, addItem,
    checkedItems, handleItemCheck,
    isSorted, isFiltersActive,
    sortBy, sortOrder,
    groupBy, initGroupOrderMap,
    enableRowSelection,
    broadcast,
    enableNewItemAdd
}) => {

    const [groupedData, setGroupedData] = useState([])
    const [groupOrderMap, setGroupOrderMap] = useState({})
    const [sortedGroups, setSortedGroups] = useState([])

    // Group the data using the groupBy field
    const groupData = () => {
        const groupedData = {}

        sortedData.forEach(row => {
            const groupByValue = row[groupBy?.field]
            if (!groupedData[groupByValue]) {
                groupedData[groupByValue] = []
            }
            groupedData[groupByValue].push(row)
        })

        return groupedData
    }

    useEffect(() => {
        setGroupOrderMap(initGroupOrderMap ?? {})
    }, [initGroupOrderMap])


    useEffect(() => {
        const groupedData = groupData()
        setGroupedData(groupedData)
    }, [sortedData, groupBy])


    useEffect(() => {

        const groupSortComparator = sortBy?.field == groupBy?.field ?
            getColumnSortFunction(sortBy, sortOrder, false) :
            (a, b) => {
                return groupOrderMap[a] - groupOrderMap[b]
            }

        const itemSortFunction = sortBy ? getColumnSortFunction(sortBy, sortOrder) : (a, b) => (a?.groupIndex ?? -Number.MAX_SAFE_INTEGER) - (b?.groupIndex ?? -Number.MAX_SAFE_INTEGER)

        const ss = Object
            .keys(groupedData)
            .sort(groupSortComparator)
            .map(k => ({ group: k, items: groupedData[k].sort(itemSortFunction) }))

        setSortedGroups(ss)
    }, [groupedData, groupOrderMap, sortBy, sortOrder])


    const tableWidth = getRowWidth(cols, enableRowSelection)

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const saveGroupOrder = (groupList) => {
        let orderMap = {}
        groupList.map((g, index) => {
            orderMap[g.group] = index
        })


        setGroupOrderMap(orderMap);
    }

    const swapGroupOrder = (oldGroupVal, newGroupVal) => {
        let orderMap = { ...groupOrderMap }
        const oldGroupOrder = orderMap[oldGroupVal]

        if (oldGroupOrder === undefined) return

        orderMap[newGroupVal] = oldGroupOrder
        delete orderMap[oldGroupVal]
        setGroupOrderMap(orderMap);
    }

    const handleGroupDrop = (droppedItem) => {



        if (!droppedItem.destination) {
            return;
        }

        const sourceIndex = droppedItem.source.index;
        const destIndex = droppedItem.destination.index;

        if (droppedItem.type === "fresler-table-group") {
            // Ignore drop outside droppable container
            if (!droppedItem.destination) return;

            let groupList = reorder(sortedGroups, droppedItem.source.index, droppedItem.destination.index);

            saveGroupOrder(groupList)
        } else if (droppedItem.type === "fresler-table-group-item") {

            const sourceParentId = parseInt(droppedItem.source.droppableId);
            const destParentId = parseInt(droppedItem.destination.droppableId);

            const sourceSubItems = sortedGroups[sourceParentId].items;
            const destSubItems = sortedGroups[destParentId].items;

            saveGroupOrder(sortedGroups)

            let newItems = [...sortedGroups];

            if (sourceParentId === destParentId) {
                const reorderedSubItems = reorder(
                    sourceSubItems,
                    sourceIndex,
                    destIndex
                );
                reorderedSubItems.map((item, index) => {
                    item.groupIndex = index
                })

                updateItemSet(reorderedSubItems)
            } else {
                let newSourceSubItems = [...sourceSubItems];
                let [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);
                draggedItem[groupBy.field] = sortedGroups[destParentId].group

                let newDestSubItems = [...destSubItems];
                newDestSubItems.splice(destIndex, 0, draggedItem);

                newSourceSubItems.map((item, index) => {
                    item.groupIndex = index
                })

                newDestSubItems.map((item, index) => {
                    item.groupIndex = index
                })

                updateItemSet([...newSourceSubItems, ...newDestSubItems])
            }

        }
    };

    useEffect(() => {


    }, [cols])

    const updateGroupValue = (group, val) => {

        const oldGroupValue = group.group
        const newGroupValue = val

        if (oldGroupValue === newGroupValue) return

        const newItems = group.items?.map((it) => {
            it[groupBy.field] = newGroupValue
            return it
        })

        swapGroupOrder(oldGroupValue, newGroupValue)
        updateItemSet(newItems)
    }

    return (

        <DragDropContext onDragEnd={handleGroupDrop}>
            <Droppable droppableId={"fresler-table-group"} type={"fresler-table-group"}>
                {(provided) => (
                    <div
                        // style={{ width: `${tableWidth}px` }}
                        className="fresler-grouped-table fresler-table-row-container"
                        {...{ ...provided.droppableProps, style: { ...provided.droppableProps } }}
                        ref={provided.innerRef}
                    >
                        {
                            sortedGroups?.map((group, index) => {
                                const psuedoItem = { [groupBy.field]: group.group }
                                const render = renderDataItem(psuedoItem, groupBy, updateData, (dataItem, col, val) => { updateGroupValue(group, val) }, broadcast)
                                return (

                                    <Draggable
                                        key={group.group ?? index}
                                        draggableId={group.group}
                                        index={index}>

                                        {(provided) => (

                                            <div key={group.group}
                                                className='fresler-table-group'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <div className="grouped-table-header-text">
                                                    <div className='grouped-table-header-text-label' >

                                                        <div style={{
                                                            display: 'inline-block', // Elements are displayed side by side
                                                            margin: '5px' // Optional: Add some spacing between elements
                                                        }}>
                                                            <span
                                                                {...provided.dragHandleProps}
                                                                className={
                                                                    classNames({
                                                                        "control task-grip": true,
                                                                        'invisible': isSorted || isFiltersActive
                                                                    })
                                                                }
                                                            >
                                                                <GripVertical />{' '}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className='fresler-table-group-display-name-area' style={{
                                                                display: 'inline-block', // Elements are displayed side by side
                                                                margin: '5px' // Optional: Add some spacing between elements
                                                            }}>
                                                            {groupBy.displayName}
                                                        </div>
                                                        <div
                                                            className='grouped-table-header-render '
                                                            style={{
                                                                display: 'inline-block', // Elements are displayed side by side
                                                                margin: '5px' // Optional: Add some spacing between elements
                                                            }}>
                                                            {render}
                                                        </div>
                                                        <div
                                                            className='fresler-table-group-size-area'
                                                            style={{
                                                                display: 'inline-block', // Elements are displayed side by side
                                                                margin: '5px' // Optional: Add some spacing between elements
                                                            }}>
                                                            ({group.items.length})
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grouped-table-header-controls">
                                                    <Droppable droppableId={`${index}`} type={"fresler-table-group-item"}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                className='fresler-table-group-item-area'>
                                                                {
                                                                    group.items
                                                                        .map((row, ind) => {
                                                                            const rowItem = accessRowItem(row);
                                                                            const k = (rowItem.id ?? rowItem._id) ?? `${group.group}-${ind}`
                                                                            const dId = (rowItem.id ?? rowItem._id) ?? `${group.group}-${ind}`

                                                                            return <Draggable key={k} draggableId={dId} index={ind}>
                                                                                {
                                                                                    (provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            key={(rowItem.id ?? rowItem._id)}
                                                                                            className="fresler-grouped-table-row fresler-table-row"
                                                                                        >
                                                                                            <div
                                                                                                style={{ width: `${dragHandleCellWidth}px` }}
                                                                                                className={
                                                                                                    classNames({
                                                                                                        "d-flex align-items-center justify-content-center": true,
                                                                                                        "fresler-grouped-table-cell": true,
                                                                                                        "fresler-grouped-table-row-drag-cell": true,
                                                                                                        "fresler-grip-cell": true
                                                                                                    })} >
                                                                                                <span
                                                                                                    {...provided.dragHandleProps}
                                                                                                    className={
                                                                                                        classNames({
                                                                                                            "control task-grip": true,
                                                                                                            'move-disabled': isSorted || isFiltersActive,
                                                                                                            'invisible': isSorted || isFiltersActive
                                                                                                        })
                                                                                                    }>
                                                                                                    <GripVertical />
                                                                                                </span>
                                                                                            </div>
                                                                                            {
                                                                                                enableRowSelection && <div
                                                                                                    style={{ width: `${checkCellWidth}px` }}
                                                                                                    className="fresler-grouped-table-cell d-flex align-items-center justify-content-center">
                                                                                                    <Form.Check
                                                                                                        style={{ textAlign: 'center' }}
                                                                                                        className='fresler-table-row-select-checkbox '
                                                                                                        color='success'
                                                                                                        onChange={(e) => handleItemCheck(e, row)}
                                                                                                        checked={checkedItems.has(row?.id ?? row?._id)}
                                                                                                    />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                cols.map((col, index) => {
                                                                                                    const rendered = renderDataItem(rowItem, col, updateData, updateItem, null, broadcast)

                                                                                                    return <>
                                                                                                        <div className="fresler-grouped-table-cell" style={{ width: col?.width }}>
                                                                                                            <div className='fresler-table-data-cell'>
                                                                                                                {rendered}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </Draggable>
                                                                        })
                                                                }
                                                                {
                                                                    enableNewItemAdd &&
                                                                    <div
                                                                        className='fresler-table-row fresler-table-row-add-new fresler-grouped-table-row'
                                                                    >
                                                                        <FreslerTableDataAddNewRow
                                                                            cols={cols}
                                                                            enableRowSelection={enableRowSelection}
                                                                            addItem={(newItem) => addItem(
                                                                                {
                                                                                    ...newItem,
                                                                                    [groupBy?.field]: group.group,
                                                                                    groupIndex: -Number.MAX_SAFE_INTEGER
                                                                                }
                                                                            )}
                                                                            renderDataItem={renderDataItem}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </div>

                                        )}
                                    </Draggable>
                                )
                            })
                        }
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}
