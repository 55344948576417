import React, { Component, Fragment, useState, useEffect } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';
import { useNavigate } from "react-router";
import { createBrowserHistory } from 'history'

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const history = createBrowserHistory();
        return <Component history={history} {...props} />;
    };
    return Wrapper;
};

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
const TelemetryProvider = (props) => {
    const { history, instrumentationKey, after, children } = props;
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const initializeTelemetry = async () => {
            if (!initialized && Boolean(instrumentationKey) && Boolean(history)) {
                ai.initialize(instrumentationKey, history);
                setInitialized(true);
            }

            after();
        };

        initializeTelemetry();
    }, [initialized, instrumentationKey, history, after]);

    return <>{children}</>;
};

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider, "app-wrapper-ai", "app-wrapper-ai"));
