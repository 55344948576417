import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import Wizard, { WizardItem } from '../Wizard';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../bootstrap/Card';
import { generateField, WizardField } from './WizardFields';
import Popovers from '../bootstrap/Popovers';
import Icon from '../icon/Icon';
import { WizardGeneratorErrorDisplayPanel } from './WizardGeneratorErrorDisplayPanel';

const generateYup = (wizardObject) => {
    var yup = {}

    wizardObject?.steps?.map(s => {
        s?.cards?.map(c => {
            c?.fields?.map(f => {
                var key = f?.id
                var validate = f?.validate

                if (validate) {
                    yup[key] = validate
                }
            })
        })
    })

    return Yup.object().shape(yup)
}

const generateInitValues = (wizardObject) => {
    var init = {}
    const checks = []

    wizardObject?.steps?.map(s => {
        s?.cards?.map(c => {
            c?.fields?.map(f => {
                var key = f?.id
                var value = f?.init

                if (f?.type == "check") {
                    if (value) {
                        checks.push(key)
                    }
                }
                else {
                    init[key] = value
                }
            })
        })
    })

    init.checks = checks
    return init
}

const getErrorMessages = (formik, wizardStep) => {
    const stepValidator = wizardStep.validator
    const stepErrorMessages = stepValidator && stepValidator(formik) || []
    var formikMessages = []

    Object.keys(formik.errors).map((key) => {
        const errorValue = formik.errors[key]
        const additionalMessages = typeof errorValue === 'string' ? [errorValue] : Object.values(errorValue)
        formikMessages.push(...additionalMessages)
    })

    return [...formikMessages, ...stepErrorMessages]
}

export const WizardGenerator = ({ wizardObject, extIndex, setExtIndex }) => {

    const validate = generateYup(wizardObject)
    const initValues = generateInitValues(wizardObject)
    const [cardsCollapsed, setCardsCollapsed] = useState(null)

    const formik = useFormik({
        initialValues: initValues,
        validationSchema: validate,
        onSubmit: (formData) => {
            wizardObject?.onSubmit(formData)
        },
    });

    useEffect(() => {
        const yup = generateYup(wizardObject)
        const values = generateInitValues(wizardObject)

        var cc = []
        wizardObject?.steps?.map(s => s?.cards?.map(card => {
            if (card?.collapsed) {
                cc.push(card?.title)
            }
        }))

        formik.setValues(values)
        setCardsCollapsed(cc)

    }, [wizardObject])

    const toggleCollapse = (cardTitle) => {

        var cc = cardsCollapsed

        if (cc?.includes(cardTitle)) {
            cc = cc.filter(e => e !== cardTitle);
        }
        else {
            cc.push(cardTitle)
        }

        setCardsCollapsed(cc)
    }


    return (
        <Wizard
            isHeader
            color='info'
            noValidate
            onSubmit={formik.handleSubmit}
            className='pt-3 shadow-3d-info'

            extIndex={extIndex}
            setExtIndex={setExtIndex}
        >
            {
                wizardObject?.steps?.map(s => {
                    const stepErrorMessages = getErrorMessages(formik, s)

                    return <WizardItem title={s?.title ? s?.title : ""} icon={s?.icon} iconColor={s?.iconColor} nextIndexTransitionDisabled={(stepErrorMessages || []).length > 0}>
                        {
                            s?.cards?.map(card => {
                                const isCollapsed = cardsCollapsed?.includes(card?.title)
                                return <Card
                                    className='wizard-item-section no-shadow'
                                    isScrollable={false}>

                                    {card?.title &&
                                        <CardHeader>
                                            <CardLabel icon=
                                                {
                                                    card?.isCollapsable ?
                                                        (isCollapsed ? "ChevronDown" : "ChevronUp") :
                                                        card?.icon
                                                } iconColor={
                                                    card?.isCollapsable ? null : card?.iconColor
                                                }>
                                                <CardTitle>
                                                    {card?.title}
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                    }
                                    <CardBody>
                                        {
                                            !isCollapsed &&
                                            <div className='row g-4'>
                                                {
                                                    card?.fields?.map(f => {
                                                        return <WizardField fieldObject={f} formik={formik} />
                                                    })
                                                }
                                            </div>
                                        }
                                    </CardBody >
                                </Card>
                            })
                        }
                        <WizardGeneratorErrorDisplayPanel errorMessages={stepErrorMessages} />
                    </WizardItem>
                })
            }
        </Wizard>
    )
}
