import React, { createRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/bootstrap/Button';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle
} from '../../components/bootstrap/Card';
import Progress from '../../components/bootstrap/Progress';
import Icon from '../../components/icon/Icon';
import useDarkMode from '../../hooks/useDarkMode';
import { deleteFileJob, getFileJobs } from '../../redux/slices/FileJobSlice';
import FileJobService from '../../services/FileJobService';

// eslint-disable-next-line react/prop-types
const FileJobItem = ({ fileJob, ...props }) => {
    const { darkModeStatus } = useDarkMode();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const link = createRef()

    const handleOnClickToProjectPage = useCallback(
        () => navigate(`/filejob/${fileJob?._id}`),
        [navigate],
    );

    function capitalize(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }

    const getFileJobState = (fileStatuses) => {
        if (fileStatuses?.numFailed > 0) {
            return "failed"
        }
        if (fileStatuses?.numQueued > 0 || fileStatuses?.numProcessing > 0) {
            return "processing"
        }

        return "success";
    }

    const getFileJobStateStyle = (status) => {
        switch (status) {
            case "failed":
                return { displayText: "Failed", icon: "ExclamationCircle", color: "danger" }
            case "processing":
                return { displayText: "Processing", icon: "ArrowClockwise", color: "info" }
            default:
                return { displayText: "Ready", icon: "Check", color: "success" }
        }
    }

    const status = getFileJobState(fileJob?.fileStatuses);
    const statusStyle = getFileJobStateStyle(status);


    const handleFileJobDelete = useCallback((e) => {
        e.stopPropagation();
        const isConfirmed = window.confirm(`Are you sure that you want to delete model ${fileJob.title}`);
        if (isConfirmed) {
            dispatch(deleteFileJob(fileJob._id))
            dispatch(getFileJobs())
        }
    });

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className='col-md-4' {...props}>
            <Card stretch onClick={handleOnClickToProjectPage} className='cursor-pointer'>
                <CardHeader>
                    <CardLabel icon='Ballot'>
                        <CardTitle>{fileJob?.title}</CardTitle>
                    </CardLabel>
                    <CardActions>
                        <small className={`border border-${statusStyle.color} border-2 text-${statusStyle.color} fw-bold px-2 py-1 rounded-1`}>
                            <Icon icon={statusStyle?.icon}></Icon>{
                                capitalize(statusStyle.displayText)
                            }
                        </small>
                        <Button
                            color='danger'
                            icon='Trash'
                            onClick={handleFileJobDelete}
                        />
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <div className='row'>
                        <div className='col-md-8'>
                            {`${fileJob?.fileStatuses?.numReady ?? 0}/${fileJob?.fileStatuses?.numPDFs} documents ready`}
                            <Progress isAutoColor value={fileJob?.fileStatuses?.percReady} height={10} />
                        </div>
                        <div className='col-md-4 d-flex justify-content-end'>
                            <Button
                                color='success'
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    await FileJobService.downloadAllFiles(fileJob, link)
                                }} >
                                <a role='button' ref={link}  >
                                    <Icon icon='Download' />
                                </a>
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export const FileJobDashboardFileJobItemList = ({ filejobs }) => {

    const [isNewFileJobModalOpen, setIsNewFileJobModalOpen] = useState(false)

    const flattenedFileJobs = filejobs?.map((filejob) => {

        const numPDFs = Object.values(filejob?.fileStatuses).reduce((accumulator, value) => accumulator + value, 0);

        const numReady = filejob?.fileStatuses?.ready ?? 0;
        const numProcessing = filejob?.fileStatuses?.processing ?? 0;
        const numQueued = filejob?.fileStatuses?.queued ?? 0;
        const numFailed = filejob?.fileStatuses?.failed ?? 0;

        const percReady = Math.round(numReady * 100 / numPDFs);
        const percProcessing = Math.round(numProcessing * 100 / numPDFs);
        const percQueued = Math.round(numQueued * 100 / numPDFs);
        const percFailed = Math.round(numFailed * 100 / numPDFs);

        return {
            ...filejob,
            fileStatuses: {
                numPDFs,
                numReady,
                numProcessing,
                numQueued,
                numFailed,
                percReady,
                percProcessing,
                percQueued,
                percFailed,
            },
        }
    })

    return (
        <>
            <div className='row mt-3'>
                <div className='col-12'>
                    <div className='display-4 fw-bold py-3'>Previous Jobs</div>
                </div>
                {
                    flattenedFileJobs?.map((fileJob) => {
                        return (
                            <FileJobItem
                                key={fileJob._id}
                                fileJob={fileJob}
                            />
                        )
                    })
                }
            </div>
        </>
    )
}
