import { GripVertical } from "react-bootstrap-icons";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Button } from './subs/Button';
import { Form } from 'react-bootstrap';
import { Icon } from './subs/Icon';
import React from 'react';

export const FreslerTableColumnEditTableOptionalRow = ({ col, addColumn, dndProvided }) => {
    return (
        <div className='fresler-table-row'>
            <div className='fresler-grouped-table-cell'>
            </div>
            <div className='fresler-grouped-table-cell'>
                <Form.Control
                    disabled={true}
                    value={col.displayName}
                />
            </div>
            <div className='fresler-grouped-table-cell'>
                <CreatableSelect
                    isDisabled={true}
                    isSearchable
                    options={[]}
                    value={{ value: col.field, label: col.field }} />
            </div>
            <div className='fresler-grouped-table-cell'>
                <Select
                    isDisabled={true}
                    className="basic-single"
                    classNamePrefix="select"
                    name="color"
                    value={{ value: col.type, label: col.type }}
                    options={[
                        { value: 'string', label: 'string' },
                        { value: 'number', label: 'number' },
                    ]}
                />
            </div>
            <div className='fresler-grouped-table-cell'>
                <Button
                    color='success'
                    icon='PlusLg'
                    onClick={() => { addColumn(col) }}>
                </Button>
            </div>
        </div>
    )
}
