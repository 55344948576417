import memoize from "memoizee";
import React, { useEffect, useState } from 'react'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb from '../../../components/bootstrap/Breadcrumb';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import TrackerService from '../../../services/TrackerService';
import { Dots } from 'loading-animations-react';
import { useNavigate, useParams } from 'react-router-dom';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../components/bootstrap/Card';
import { FreslerTable } from '../../../components/FreslerTable/src/src/components/FreslerTable/FreslerTable';
import Badge from '../../../components/bootstrap/Badge';
import { TrackerColumnModal } from './TrackerColumnModal';
import TaskService from '../../../services/TaskService';
import "./TrackerBoard.css"
import { getTaskIcons } from './TrackerBoardGetTaskStatusIcons';
import { TrackerBoardTaskCell } from './TrackerBoardTaskCell';
import { useDispatch, useSelector } from 'react-redux';
import { getTrackerTasks, setFocusPanelOpen, updateTrackerTasks, upsertTrackers } from '../../../redux/slices/TrackerSlice';
import { ObjectId, getOrDefaultActionItem } from './TrackerBoardUtils';
import SplitPane, {
    Divider,
    SplitPaneBottom,
    SplitPaneLeft,
    SplitPaneRight,
    SplitPaneTop,
} from '../../../components/SplitPane/SplitPane';
import { TaskPanel } from './TaskPanel';
import { taskStatusSelectOptions, trackerRowStatusOptions } from '../../../services/Common';
import { TrackerBoardEmailBlastModal } from '../../../components/TrackerBoardEmailBlastModal/TrackerBoardEmailBlastModal';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import { TrackerBoardContactEditPanel } from './TrackerBoardContactEditPanel';
import { NewTrackerModal } from '../../../components/trackers/NewTrackerModal/NewTrackerModal';
import EmailService from '../../../services/EmailService';
import { TrackerBoardTrackerTitle } from "./TrackerBoardTrackerTitle";
import ListService from "../../../services/ListService";
import a4Logger from "../../../services/a4Logger";
import Spinner from "../../../components/bootstrap/Spinner";

export const TrackerBoard = ({ trackerId }) => {

    const [tracker, setTracker] = useState(null)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [isColumnModalOpen, setIsColumnModalOpen] = useState(false)
    const [trackerWorkstreams, setTrackerWorkstreams] = useState([])
    const [tableCols, setTableCols] = useState([])

    const { trackerRow, workstream } = useSelector(state => state?.tracker?.focusTask)
    const curTrackerTasks = useSelector(state => state?.tracker?.curTrackerTasks)
    const [isTrackerBoardContactEditPanelOpen, setIsTrackerBoardContactEditPanelOpen] = useState(false)
    const [isNewTrackerModalOpen, setIsNewTrackerModalOpen] = useState(false)
    const focusTask = trackerRow && workstream && curTrackerTasks?.value ? getOrDefaultActionItem(tracker, trackerRow, workstream, curTrackerTasks?.value) || {} : {}
    const [isProcessing, setIsProcessing] = useState(false) // Like isLoading but for simpler updates

    const process = async (proc) => {
        setIsProcessing(true)
        try {
            await proc()
        }
        catch (e) {
            a4Logger.logError(e)
        }
        setIsProcessing(false)
    }

    const dispatch = useDispatch()

    const { isFocusPanelOpen } = useSelector(state => state?.tracker?.focusTask)

    const trackersState = useSelector(state => state?.tracker?.state)
    const { _id: stateTrackerId, loading: isTrackerTaskMapLoading } = useSelector(state => state?.tracker?.curTrackerTasks)

    const workstreamIndexComparator = (a, b) => ((a.trackerIndex ?? -Number.MAX_SAFE_INTEGER) - (b.trackerIndex ?? -Number.MAX_SAFE_INTEGER))

    const updateFocusTask = (task, change) => {

        dispatch(updateTrackerTasks({ tasks: [{ ...task, ...change }] }))
    }

    const [statusMessageUpdated, setStatusMessageUpdated] = useState(false)
    const numActiveRows = tracker?._trackerrows?.filter(x => x.status == "Active")?.length ?? 0
    const totalNumRows = tracker?._trackerrows?.length ?? 0
    const numBlockedRows = tracker?._trackerrows?.filter(x => x.status == "Blocked")?.length ?? 0
    const contactsBlockedWarning = numBlockedRows > 0 ? <Badge color="danger">{numBlockedRows} Contacts Blocked</Badge> : null
    const subHeaderMessage = <>{numActiveRows}/{totalNumRows} Remaining Active Contacts {contactsBlockedWarning}</>

    const trackerTasks = useSelector(state => state?.tracker?.curTrackerTasks?.value)

    const getWorkstreamActions = (workstream) => {
        var actions = [
            {
                title: "Mark All Complete",
                icon: "Check",
                runAction: (data, checkedItems, completeAction) => {

                    const selectedItems = (checkedItems.size > 0
                        ? data?.filter(x => checkedItems.has(x?._id))
                        : data) ?? []

                    const numItems = selectedItems.length

                    const msg = `Mark ${numItems} items as complete?`

                    if (window.confirm(msg)) {
                        var updatedTasks = selectedItems.map((item) => {
                            const task = getOrDefaultActionItem(tracker, item, workstream, trackerTasks)
                            return { ...task, status: "Complete" }
                        })

                        dispatch(updateTrackerTasks({ tasks: updatedTasks }))
                    }
                }
            },
            {
                title: "Mark All Active",
                icon: "ArrowRepeat",
                runAction: (data, checkedItems, completeAction) => {

                    const selectedItems = (checkedItems.size > 0 ? data?.filter(x => checkedItems.has(x?._id)) : data) ?? []
                    const numItems = selectedItems.length

                    const msg = `Mark ${numItems} items as 'In Progress'?`
                    if (window.confirm(msg)) {
                        var updatedTasks = selectedItems.map((item) => {
                            const task = getOrDefaultActionItem(tracker, item, workstream, trackerTasks)
                            return { ...task, status: "In Progress" }
                        })

                        dispatch(updateTrackerTasks({ tasks: updatedTasks }))
                    }
                }
            }
        ]


        // TODO: Change from renderInit to completeAction or something like that? What a terrible way to do this
        if (workstream.type == "Email") {
            actions.unshift({
                title: "Send Email",
                icon: "EnvelopeAtFill",
                renderInit: (setIsComplete, setIsOpen, isOpen, data, checkedItems) => (
                    <TrackerBoardEmailBlastModal
                        tracker={tracker}
                        workstream={workstream}
                        data={data}
                        callback={setIsComplete}
                        isTrackerBoardEmailBlastModalOpen={isOpen}
                        setIsTrackerBoardEmailBlastModalOpen={setIsOpen}
                        selectedItems={checkedItems}
                    />
                ),
            })
        }

        return actions

    }

    // const _getSerializedFund = 
    // const getSerializedFund = memoize(_getSerializedFund, { maxAge: 1000 * 60 * 60 * 24 })
    const mainCols = [
        {
            displayName: "Status",
            field: "status",
            type: "select",
            width: 150,
            options: trackerRowStatusOptions,
            editable: true,
            broadcastChange: (item, col, value) => {
                // Probably should be doing this through the slice so that we can use in a side panel
                TrackerService.upsertTrackerRows([{ ...item, status: value }])
            }
        },
        {
            displayName: "Fund",
            field: "Fund Name",
            type: "string",
            enableGrouping: false,
            editable: true,
            serialize: (dataItem, col) => {
                if (col?.field == "Fund Name") {
                    const nc = EmailService.getCustomerAndCompany(dataItem?._listItem?.data)
                    return nc
                }
                return ""
            },
            viewRender: (dataItem, col, updateData, updateItem, isNewItem) => {

                const fundName = dataItem?._listItem?.data?.["Fund Name"] ?? ""
                const contactName = dataItem?._listItem?.data?.["Contact Name"] ?? ""

                return <div
                    className="tracker-board-contact-cell"
                    onClick={() => {
                        setIsTrackerBoardContactEditPanelOpen(true)
                    }}
                >
                    <div>{fundName}</div>
                    <div className='small text-muted'>
                        {contactName}
                    </div>
                </div>
            }
        },
    ]

    const refreshColumnHeaders = () => {
        const newCols = [
            ...mainCols,
            ...trackerWorkstreams.sort(workstreamIndexComparator)
                .filter(ws => !ws.deleted)
                .map((ws, i) => {
                    return {
                        displayName: ws.title,
                        generateFreslerTableCellClasses: (dataItem, col) => {
                            return "fresler-table-cell-task-wrapper"
                        },
                        enableSearch: false,
                        enableFilter: false,
                        field: ws._id,
                        workStream: ws,
                        type: "string",
                        editable: true,
                        viewRender: (dataItem, col, updateData, updateItem, isNewItem) => {
                            // TODO: If changes don't update div, maybe separating this into a component with local state will work
                            return <TrackerBoardTaskCell
                                tracker={tracker}
                                dataItem={dataItem}
                                col={col}
                                updateData={updateData}
                                updateItem={updateItem}
                                isNewItem={isNewItem} />
                        },
                        actions: getWorkstreamActions(ws)
                    }
                })
        ]

        setTableCols(newCols)
    }

    const customTableActions = [
        {
            displayName: 'Delete',
            icon: 'Trash',
            color: 'danger',
            apply: async (data, selected, updateCheckedItems, updateData, updateDataItem) => {
                if (window.confirm(`Delete ${selected.size} items? This action cannot be undone.`)) {

                    await process(async () => {
                        var updatedRows = tracker?._trackerrows
                            ?.filter((item) => selected.has(item.id ?? item._id))
                            ?.map((item, i) => ({ ...item, deleted: true }))

                        await TrackerService.upsertTrackerRows(updatedRows)
                        await refreshBoard()

                        updateCheckedItems(new Set())
                    })
                }
            }
        },
        {
            displayName: 'Block',
            icon: 'Ban',
            color: 'danger',
            apply: async (data, selected, updateCheckedItems, updateData, updateDataItem) => {
                if (window.confirm(`Mark (${selected.size}) tracker rows as blocked?`)) {

                    await process(async () => {
                        var updatedRows = tracker?._trackerrows
                            ?.filter((item) => selected.has(item.id ?? item._id))
                            ?.map((item, i) => ({ ...item, status: "Blocked" }))

                        await TrackerService.upsertTrackerRows(updatedRows)
                        await refreshBoard()

                        updateCheckedItems(new Set())
                    })
                }
            }
        }
    ]

    const updateWorkstream = (ws) => {

        if (!ws._id) {
            ws._id = ObjectId()
            ws.tracker = tracker?._id
            ws.trackerIndex = trackerWorkstreams.length
            ws.project = tracker?.project
            ws.status = "New"

            setTrackerWorkstreams([...trackerWorkstreams, ws])
        }
        else {
            var newWSList = [...trackerWorkstreams]
            const ind = newWSList.findIndex(x => x._id == ws._id)
            newWSList[ind] = ws
            setTrackerWorkstreams(newWSList)
        }


        TaskService.upsertWorkstream(ws)
        refreshColumnHeaders()
    }

    const updateWorkstreams = (newWs) => {
        const indexed = newWs.map((x, i) => ({ ...x, trackerIndex: i }))
        setTrackerWorkstreams(indexed)
        TaskService.upsertWorkstreams(indexed)

        refreshColumnHeaders()
    }

    const refreshBoard = async () => {
        setIsLoading(true)
        const tracker = await TrackerService.getTracker(trackerId)

        // Use global state for the actual tracker tasks

        if (stateTrackerId != trackerId) {
            dispatch(getTrackerTasks(trackerId))
        }

        const ws = tracker?._workstreams ?? []

        setTracker(tracker)
        setTrackerWorkstreams(ws)
        setFocusPanelOpen(false)
        setIsLoading(false)
    }

    useEffect(async () => {
        setIsLoading(true)
        const tracker = await TrackerService.getTracker(trackerId)

        // Use global state for the actual tracker tasks

        if (stateTrackerId != trackerId) {
            dispatch(getTrackerTasks(trackerId))
        }

        const ws = tracker?._workstreams ?? []

        setTracker(tracker)
        setTrackerWorkstreams(ws)
        setFocusPanelOpen(false)
        setIsLoading(false)
    }, [trackerId])

    useEffect(() => {
        refreshColumnHeaders()
    }, [trackerWorkstreams])


    const commonProps = {
        disableTableActions: ["Delete"],
        customTableActions: customTableActions,
        enableNewItemAdd: false,
        // Common props
        trackerId: trackerId,
        tracker: tracker,
        setTracker: setTracker,
        isLoading: isLoading,
        setIsLoading: setIsLoading,

        // Page control props
        isColumnModalOpen: isColumnModalOpen,
        setIsColumnModalOpen: setIsColumnModalOpen,

        // Table data
        initCols: tableCols,
        initData: tracker?._trackerrows ?? [],
        enableColumnDrag: false,
        isTableOptionsAccessible: false,

        trackerWorkstreams: trackerWorkstreams ?? [],
        updateWorkstream: updateWorkstream,
        updateWorkstreams: updateWorkstreams,

        focusTask: focusTask,
        updateFocusTask: updateFocusTask,
    }

    const buttons = [
        {
            title: "Edit Tasks (Columns)",
            icon: "Task",
            iconColor: "success",
            onClick: () => { setIsColumnModalOpen(true) }
        },
        {
            title: "Contact List",
            icon: "Person",
            iconColor: "warning",
            onClick: () => { setIsTrackerBoardContactEditPanelOpen(true) }
        },
        {
            title: "New Campaigns",
            icon: "Plus",
            iconColor: "primary",
            onClick: () => { navigate(`/newCampaign?campaignType=buyer`) }
        },
        {
            title: "Save Template",
            icon: "Save",
            iconColor: "warning",
            onClick: () => { setIsTrackerSaveTemplateModalOpen(true) }
        },
        {
            title: "Export",
            icon: "CloudDownload",
            iconColor: "info",
            onClick: () => { navigate(`/newCampaign?campaignType=buyer`) }
        },
    ]

    const updateTracker = (newTracker) => {

        setTracker(newTracker)
        TrackerService.upsertTracker(newTracker)

        // TODO: Disptach is causing an odd issue where after a dispatch the 
        // tasks refresh and the row status cannot be updated
        // dispatch(upsertTrackers([newTracker]))
        // dispatch(upsertTrackers([newTracker]))
    }

    return (
        <>
            <SplitPane>
                <SplitPaneLeft numPanels={isFocusPanelOpen ? 2 : 1}>
                    <Card stretch style={{ height: "100%" }} >
                        <CardHeader borderSize={1}>
                            <CardLabel icon='Bullseye' iconColor='info'>
                                <TrackerBoardTrackerTitle title={tracker?.title} updateTitle={
                                    (title) => {
                                        let newTracker = { ...tracker, title: title }
                                        updateTracker(newTracker)
                                    }
                                } />
                                <CardSubTitle>
                                    {
                                        statusMessageUpdated ?
                                            "" : subHeaderMessage
                                    }
                                </CardSubTitle>
                            </CardLabel>
                            <CardActions
                                className="d-flex justify-content-between d-inline-flex align-items-center"
                            >
                                {
                                    isProcessing && <Spinner
                                        color='info'
                                        size='2rem'
                                    />
                                }
                                <Dropdown>
                                    <DropdownToggle>
                                        <Button icon='MoreVert' isLight size="lg" />
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        {
                                            buttons.map((button, i) => {
                                                return <DropdownItem key={i}>
                                                    <Button icon={button.icon} onClick={button.onClick}>{button.title}</Button>
                                                </DropdownItem>
                                            })
                                        }
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>
                        <CardBody isScrollable>
                            {
                                <FreslerTable {...commonProps} />
                            }
                        </CardBody>
                    </Card>
                </SplitPaneLeft>
                {
                    isFocusPanelOpen &&
                    <>
                        <Divider />
                        <SplitPaneRight className="">
                            <TaskPanel
                                {...commonProps}
                            />
                        </SplitPaneRight>
                    </>
                }
            </SplitPane>
            <TrackerBoardContactEditPanel
                isTrackerBoardContactEditPanelOpen={isTrackerBoardContactEditPanelOpen}
                setIsTrackerBoardContactEditPanelOpen={setIsTrackerBoardContactEditPanelOpen}
                tracker={tracker}
                trackerRows={tracker?._trackerrows ?? []}
                onSave={(l) => {
                    refreshBoard()
                    setIsTrackerBoardContactEditPanelOpen(false)
                }}
            />

            <TrackerColumnModal {...commonProps} />
            <NewTrackerModal
                isNewTrackerModalOpen={isNewTrackerModalOpen}
                setIsNewTrackerModalOpen={setIsNewTrackerModalOpen} />
        </>
    )
}
