import React from 'react'
import { TrackerColumnsEdit } from './TrackerColumnsEdit'
import "./NewTrackerColumnsEdit.css"
import { TrackerWorkstreamTemplateSelect } from './TrackerWorkstreamTemplateSelect'

export const NewTrackerColumnsEdit = ({ trackerWorkstreams, updateWorkstreams }) => {
    const curWS = trackerWorkstreams || []

    const updateWorkstream = (workstream, index = null) => {

        if (index != null) {
            const updatedWorkstreams = [...curWS]
            updatedWorkstreams[index] = workstream
            updateWorkstreams(updatedWorkstreams)
        }
        else {
            updateWorkstreams([...curWS, workstream])
        }
    }

    return (
        <>
            <div className='new-tracker-column-edit-wrapper'>
                <div className='mb-4'>
                    <TrackerWorkstreamTemplateSelect updateWorkstreams={updateWorkstreams} />
                </div>
                <div >
                    <TrackerColumnsEdit trackerWorkstreams={curWS} updateWorkstreams={updateWorkstreams} updateWorkstream={updateWorkstream} />
                </div>
            </div>
            {
                !(curWS?.length > 0) &&
                <div className='row'>
                    <div className='col-12 text-center'>
                        <div className='display-6'>You can also add contacts after setting up your campaign.</div>
                    </div>
                </div>
            }
        </>
    )
}
