import { deleteJson, getJson, postJson, putJson } from "./ServiceHelperMethods";

class TrackerService {

    static async getTrackers() {
        return await getJson(`trackers`);
    }

    static async getTracker(id) {
        const trackers = await getJson(`trackers/${id}`)

        return trackers[0];
    }

    static async upsertTracker(tracker) {
        const hydratedTrackers = await putJson(`trackers`, [tracker]);
        return hydratedTrackers[0];
    }

    static async upsertTrackers(trackers) {
        return await putJson(`trackers`, trackers);
    }

    static async deleteTracker(trackerId) {
        return await deleteJson(`trackers/${trackerId}`);
    }

    static async upsertTrackerRows(trackerRows) {
        return await putJson(`trackerrows`, trackerRows);
    }
}



export default TrackerService;