import { useEffect } from 'react';
import Modal, {
    ModalBody,
    ModalHeader
} from '../../bootstrap/Modal';
import {
    OffCanvasTitle
} from '../../bootstrap/OffCanvas';
import { NewFileJobWizard } from './NewFileJobWizard';

export const NewFileJobModal = ({ isNewFileJobModalOpen, setIsNewFileJobModalOpen, files, callback }) => {

    useEffect(() => {
        if (files?.length > 0) {
            setIsNewFileJobModalOpen(true)
        }
    }, [files])


    return (
        <Modal
            setIsOpen={setIsNewFileJobModalOpen}
            isOpen={isNewFileJobModalOpen}
            titleId='upcomingEdit'
            isCentered
            isScrollable
            size='xl'
            fullScreen='xxl'
        >
            <ModalHeader setIsOpen={setIsNewFileJobModalOpen}>
                <OffCanvasTitle id='upcomingEdit'>New PDF Water Mark</OffCanvasTitle>
            </ModalHeader>
            <ModalBody>
                <NewFileJobWizard files={files} callback={() => setIsNewFileJobModalOpen(false)} />
            </ModalBody>
        </Modal>
    )
}